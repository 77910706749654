import { Action, ActionTypes, teamData } from "../actions";

const initialState: teamData = {
  team: []
};

export function teamReducer(state = initialState, action: Action): teamData {
  switch (action.type) {
    default:
      return state;
  }
}
