import "./AppFooter.scss";

import Col from "react-bootstrap/Col";
// import { Button, Form } from "react-bootstrap";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import Image from "react-bootstrap/Image";
import React from "react";
import Row from "react-bootstrap/Row";

const AppFooter = () => (
  <footer className="app-footer">
    <div className="container">
      <Row className="align-items-center">
        <Col sm>
          <Image
            src="https://dealengine-client-assets.s3-us-west-2.amazonaws.com/dealengine-logo-red.svg"
            fluid
            alt="DealEngine logo, a rocket taking off over an upwards trending bar chart"
          />
          <h2 className="logo-label">DealEngine</h2>
        </Col>
        <Col>
          <ul aria-labelledby="footer-social-links">
            <li>
              <a
                href="https://twitter.com/DealengineAI"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/dealengine"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/dealengineAI/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF />
              </a>
            </li>
          </ul>
        </Col>
        <Col sm>
          <ul>
            <li>
              <a href="mailto:contact@dealengine.ai">Founder Support</a>
            </li>
            <li>
              <a
                className="issue-button"
                href="javascript:void(0);"
                onClick={() => window.showBugCollectorDialog()}
              >
                Report An Issue
              </a>
            </li>
            <li>
              <a
                className="issue-button"
                href="javascript:void(0);"
                onClick={() => window.showFeedbackCollectorDialog()}
              >
                Provide Feedback
              </a>
            </li>
          </ul>
        </Col>
        {/* <Col sm>
          <h2>product</h2>
          <ul>
            <li>
              <a href="#" rel="noopener noreferrer">
                About Us
              </a>
            </li>
            <li>
              <a href="#" rel="noopener noreferrer">
                Pricing
              </a>
            </li>
            <li>
              <a href="#" rel="noopener noreferrer">
                Blog
              </a>
            </li>
          </ul>
        </Col> */}
        {/* <Col sm>
          <h2>sign up for our newsletter</h2>
          <Form>
            <Form.Group controlId="emailNewsletter">
              <Form.Label>Receive updates, offers, and user tips.</Form.Label>
              <Form.Control type="email"></Form.Control>
            </Form.Group>
            <Button variant="outline-primary" type="submit">
              subscribe
            </Button>
          </Form>
        </Col> */}
      </Row>
      {/* <Row className="justify-content-center">
        <Col>
          <small>
            Terms of Use &nbsp; &nbsp;| &nbsp;&nbsp; Privacy Policy &nbsp;
            &nbsp;| &nbsp;&nbsp; Sitemap &nbsp;&nbsp;| &nbsp;&nbsp;
            DealEngine.ai
          </small>
        </Col>
      </Row> */}
    </div>
  </footer>
);

export default AppFooter;
