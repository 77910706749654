import { Routes, Action, ActionTypes } from "../actions";

const initialState: Routes = {
  routesArray: [],
  path: "",
  url: ""
};

export function routesReducer(state = initialState, action: Action): Routes {
  switch (action.type) {
    case ActionTypes.appRoutes:
      return {
        ...state,
        routesArray: action.payload
      };
    case ActionTypes.pathURL:
      return {
        ...state,
        path: action.payload.path,
        url: action.payload.url
      };
    default:
      return state;
  }
}
