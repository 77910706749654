/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, { Component } from "react";

// Since Google Analytics 4, the GA React package no longer works correctly
// this is a hack/wrapper to send analytics through
// taken from https://stackoverflow.com/a/64582687
export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
    window.gtag("send", "page_view", {
      page_location: window.location.href,
      page_path: window.location.pathname
    });
    // TODO: come up with custom options to send through
    // window.gtag("event", "test_login", {
    //   method: "Google"
    // });
  };

  const HOC = class extends Component {
    componentDidMount() {
      if (options.title) {
        document.title = `DealEngine | ${options.title}`;
      }
      const {
        location: { pathname: page }
      } = this.props;
      trackPage(page);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
      const {
        location: { pathname: currentPage }
      } = this.props;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}
