import { Action, ActionTypes, mtmData } from "../actions";

const initialState: mtmData = {
  data: []
};

export function mtmReducer(state = initialState, action: Action): mtmData {
  switch (action.type) {
    case ActionTypes.getMtm:
      return {
        ...state,
        data: action.payload.data
      };
    case ActionTypes.loadMtmUpdateData:
      return {
        ...state,
        data: action.payload.data
      };
    case ActionTypes.postMtm:
      return {
        ...state
      };
    default:
      return state;
  }
}
