import React from "react";
import { useHistory } from "react-router-dom";
import "./ErrorPage.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setError } from "../../../redux/actions/errors";

const ErrorPage = ({ clearState, setError }) => {
  const history = useHistory();

  const navigateToHomePage = () => {
    setError({ message: "", statusCode: null });
    clearState();
    history.push("/");
  };

  const randomGen = () => {
    return Math.floor(Math.random() * (7 - 1) + 1);
  };

  return (
    <div className="error-page">
      <h1 className="error-header">404</h1>
      <img
        alt="DealEngine pet image indicating a 404 page"
        className="img-fluid"
        src={`https://dealengine-client-assets.s3.us-west-2.amazonaws.com/images-404/${randomGen()}.png`}
      />
      <p className="error-msg">This page does not exist</p>
      <div className="error-link" onClick={() => navigateToHomePage()}>
        <i className="icon-home"></i> Click here to go back to home page.
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  clearState: PropTypes.func,
  setError: PropTypes.func
};

export default connect(null, { setError })(ErrorPage);
