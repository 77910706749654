import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./ProgressBar.css";

const MarketSizing = ({ color, progress }) => {
  const [offset, setOffset] = useState(0);
  const circleRef = useRef(null);

  // have some of these come from props
  const size = 120;
  const center = size / 2;
  const radius = size / 2 - 15 / 2;
  const circumference = 2 * Math.PI * radius;
  const circleOneStroke = "#edebe9";
  const circleTwoStroke = color;
  const strokeWidth = 7;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);

    circleRef.current.style = "transition: stroke-dashoffset 850ms ease-in-out";
  }, [setOffset, progress, circumference, offset]);

  return (
    <svg className="svg" width={size} height={size}>
      <circle
        className="svg-circle-bg"
        stroke={circleOneStroke}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
      />
      {/* <circle
        className="svg-circle"
        ref={circleRef}
        stroke={circleTwoStroke}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      /> */}
      <circle
        className="svg-fill-circle"
        ref={circleRef}
        stroke={circleTwoStroke}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        fill={color}
        fillOpacity="45%"
      />
      <g
        id="icon-market-sizing"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        width={100}
        height={100}
      >
        <g id="Layer_1-2market-sizing" data-name="Layer 1">
          <path
            d="M227.75,224.59c-5.47,0-10.94,0-17.26,0v-6.46q0-53.27,0-106.55c0-6.82-.84-7.62-7.8-7.64q-7.71,0-15.43,0c-7.37,0-8,.59-8,7.91q0,53,0,106.05v6.29h-7.77v-6.28q0-37.59,0-75.19c0-7-.58-7.55-7.74-7.57-5.48,0-11,0-16.43,0-6.24,0-7.09.9-7.09,7.3q0,38.08,0,76.18v5.61h-7.57V218q0-43.82,0-87.63c0-6.07-.85-6.85-6.94-6.88-5.48,0-11,0-16.43,0-7.4,0-7.89.49-7.89,7.93q0,43.57,0,87.14v5.71H93.64V218.2q0-35.85,0-71.7c0-7-.59-7.57-7.73-7.58q-8,0-15.94,0c-6.63,0-7.53.89-7.53,7.41q0,36.35,0,72.69v5.24H54.79v-6.12c0-18.92,0-37.84,0-56.76,0-6-.88-6.81-7-6.83-5,0-10,0-14.94,0-9.18,0-9.29.1-9.29,9.06v61c-6.12,0-11.57,0-17,0-2.93,0-6.12.14-6.06,4.11.06,3.73,3.13,3.62,5.87,3.62H227.93c2.81,0,5.69-.07,5.6-3.88S230.5,224.6,227.75,224.59Zm-181.11-.25H31.7V162.82H46.64Zm38.93-.14h-15V147h15Zm39,.14H109.46V131.67h15.11Zm38.87-.09H148.35v-81h15.09Zm38.76,0h-15v-112h15Z"
            fill="#000"
            fillRule="nonzero"
            transform={`translate(${center / 2.1},${
              center / 2.5
            }) scale(.27 .27)`}
          />
          <path
            d="M13.79,144A85.71,85.71,0,0,0,25.72,139c2.7-1.27,4.88-1.54,7.77.19,6,3.6,14.23,1,16-5.59,1.27-4.73,4.07-6.28,7.88-7.63,2.34-.83,4.65-1.76,6.91-2.79,2.85-1.31,5.2-1.51,8.22.41,5.72,3.64,14,1.15,15.68-5.13,1.41-5.28,4.73-6.94,9-8.42,2.5-.86,4.84-2.32,7.39-2.87a9.1,9.1,0,0,1,5.75.6c4.36,2.33,8.78,3,12.62-.09,2.92-2.36,5.37-2.42,8.53-1.18a60.25,60.25,0,0,0,7.64,2.21c3,.75,5.11,1.93,6.07,5.48,1.29,4.79,7.05,7.8,11.85,7.15,5.43-.73,10.92-5.33,10-10.53-1.15-6.59,3-9.14,6.5-12.56,3.71-3.56,7.58-7,11.21-10.6,2-2,3.71-2.75,6.87-1.93,9.09,2.35,16.17-4.92,14.32-14-.26-1.29-.38-3.22.35-4,3.35-3.53,7-6.76,9.7-9.27,2.7,1.81,4.68,4.24,6.56,4.17,3.51-.14,3.13-6.24,3.13-6.24l-1.39-10.91s-15.86.13-17.76,1.07L210,53.2c-2,1.92-5,4.59-7.76,7.4-1.95,1.95-3.71,3-6.88,2.59-7-.87-12.4,5-12.5,12.35a7.87,7.87,0,0,1-2,5C175.66,85.87,170.07,90.85,164.7,96c-2.16,2.09-4.17,3.1-7.46,2.27a8.79,8.79,0,0,0-7,1.41c-3.64,3.19-7.08,2.45-10.91,1.08-2.49-.9-5.18-1.32-7.57-2.41A7.62,7.62,0,0,1,128.12,95c-2.68-6-6.43-8.86-12-8.54-5.32.3-8.88,3.43-10.76,9.45-.94,3-16.57,10.44-19.42,9.2-.29-.13-.5-.44-.78-.61-8.78-5.13-15.59-2.46-18.73,7.34-1,3-16.89,10-19.65,8.63-.29-.15-.49-.46-.77-.63-8-4.92-15.9-1.7-18.63,7.36a7.54,7.54,0,0,1-3.43,4.1c-4.27,2.18-8.93,3.62-13.11,5.94-1.35.76-1.69,3.35-2.49,5.1C10.17,142.94,12.26,144.39,13.79,144ZM194.48,68.63a6.08,6.08,0,1,1-6.08,6.08A6.08,6.08,0,0,1,194.48,68.63ZM155.7,104.41a6.08,6.08,0,1,1-6.07,6.08A6.08,6.08,0,0,1,155.7,104.41Zm-39-12.5A6.08,6.08,0,1,1,110.66,98,6.08,6.08,0,0,1,116.74,91.91Zm-38.8,16.25h.24a5.94,5.94,0,1,1-.24,0ZM39,123.58a6.08,6.08,0,1,1-6.07,6.07A6.08,6.08,0,0,1,39,123.58Z"
            fill="#000"
            fillRule="nonzero"
            transform={`translate(${center / 2.1},${
              center / 2.5
            }) scale(.27 .27)`}
          />
          <path
            d="M79.08,79.15a44.5,44.5,0,0,1-4.32-8.92H90.85c.41,3.08,1,6.06,1.6,8.92h10.28a80.85,80.85,0,0,1-1.79-8.92h32.15a85.77,85.77,0,0,1-1.79,8.92h10.28c.66-2.86,1.19-5.84,1.6-8.92h16.1A44.5,44.5,0,0,1,155,79.15h11.42a54.82,54.82,0,1,0-98.73,0Zm82.75-23.83a44.47,44.47,0,0,1-.28,4.91h-17.5c.07-1.62.1-3.26.1-4.91a118.25,118.25,0,0,0-.6-12H160.2A45,45,0,0,1,161.83,55.32Zm-5.77-22H142a68.39,68.39,0,0,0-6.53-18.84A45.17,45.17,0,0,1,156.06,33.35ZM117,10.5c5,0,11.39,8.35,14.78,22.85H102.24C105.63,18.85,112,10.5,117,10.5ZM100.53,43.35h33a104.59,104.59,0,0,1,.66,12c0,1.65,0,3.29-.11,4.91H100c-.07-1.62-.11-3.26-.11-4.91A107,107,0,0,1,100.53,43.35Zm-2-28.84A68.39,68.39,0,0,0,92,33.35H78A45.17,45.17,0,0,1,98.52,14.51Zm-8,28.84a118.25,118.25,0,0,0-.6,12c0,1.64,0,3.28.1,4.91H72.48a44.47,44.47,0,0,1-.28-4.91,45,45,0,0,1,1.63-12Z"
            fill="#000"
            fillRule="nonzero"
            transform={`translate(${center / 2.1},${
              center / 2.5
            }) scale(.27 .27)`}
          />
        </g>
      </g>
    </svg>
  );
};

export default MarketSizing;

MarketSizing.propTypes = {
  progress: PropTypes.number,
  color: PropTypes.string
};

MarketSizing.defaultProps = {
  progress: 0,
  color: "#edebe9"
};
