import React, { useEffect, useState } from "react";
import {
  getAuthStatus,
  getLoaded,
  getOnboarding,
  getOrgTier
} from "../../../redux/selectors/authSelectors";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getGlossary } from "../../../redux/actions/glossary";
import { getGlossaryData } from "../../../redux/selectors/glossarySelectors";
import { getMtm } from "../../../redux/actions/mtm";
import { getMtmData } from "../../../redux/selectors/mtmSelectors";
import { getPlaybook } from "../../../redux/actions/playbook";
import { getUserSprints } from "../../../redux/actions/premium";
import { getScores } from "../../../redux/actions/scores";
import {
  getVideos,
  getSprints,
  getTemplates
} from "../../../redux/actions/premium";
import { loadOrgData } from "../../../redux/actions/auth";
import { pathURL } from "../../../redux/actions/routes";
import { setError } from "../../../redux/actions/errors";
import { useHistory } from "react-router-dom";
import { getTierToInt } from "../../helpers/helpers";

// This Header component will become a smart component that will trigger
// the app's initial data fetches and Redux setup for the user/org
const Header = (props) => {
  const {
    routesArray,
    path,
    url,
    pathURL,
    setError,
    errors,
    isLoggedIn,
    getGlossary,
    glossaryData,
    isLoaded,
    getMtm,
    getPlaybook,
    isOnboarded,
    loadOrgData,
    mtmData,
    getScores,
    getVideos,
    getSprints,
    getTemplates,
    tier,
    getUserSprints
  } = props;

  const history = useHistory();
  const [loading, setLoad] = useState(false);

  // This hook checks if the url is a supported route, if it's not supported it throws an error and triggers the error boundary component catch. Note: In the development environment, the browser may show the error in the view, this will not happen in the Production environment... as a dev just hit esc and the error will disappear and show the error component as expected
  useEffect(() => {
    // once the routesArray is loaded, check if the current path exists
    if (routesArray.length) {
      // If a user is not yet logged in, send them to the login page
      if (!path && !url && !isLoggedIn) {
        history.push({
          pathname: "/login"
        });
      }
      // If path is "premium" check tier
      if (
        path &&
        url &&
        isLoggedIn &&
        tier &&
        path === "/premium" &&
        getTierToInt(tier) < 2
      ) {
        history.push({
          pathname: "/"
        });
      }
      // If user is logged in, check if it's a valid route
      if (!path && !url && isLoggedIn) {
        pathURL("/", "/");
        const error = new Error(
          `Page with pathname ${location.pathname} not found with status code 404.`
        );
        setError({
          message: `Page with pathname ${location.pathname} not found.`,
          statusCode: 404
        });
        throw error;
      }
    }
  }, [path, url, routesArray, pathURL]);

  useEffect(() => {
    if (errors.message || errors.statusCode) {
      pathURL("/", "/");
      const error = new Error(
        `${errors.message} With status code ${errors.statusCode} `
      );
      setError({
        message: `Page with pathname ${location.pathname} not found.`,
        statusCode: 404
      });
      throw error;
    }
    // eslint-disable-next-line
  }, [errors]);

  // make initial data calls here
  // only make these calls if the user is logged in and data hasn't loaded
  // TODO: make another check to wait if it's in the process of loading and add loading spinner to app
  if (isLoggedIn && !isLoaded) {
    // need to delay a bit for the backend to store token upon login
    if (!loading) {
      setLoad(true);
      setTimeout(() => {
        // Check if the data is empty and then call...prevents multiple calls
        loadOrgData();
        (async () => {
          const response = await getScores();
          if (!response || response.status !== 200) {
            // put in another call?
          } else {
            // if (process.env.NODE_ENV !== "development") {
            pendo.initialize({
              visitor: {
                id: localStorage.getItem("user_id") || "" // Required if user is logged in (user id)
                // email:        // Recommended if using Pendo Feedback, or NPS Email
                // full_name:    // Recommended if using Pendo Feedback
                // role:         // Optional

                // You can add any additional visitor level key-values here,
                // as long as it's not one of the above reserved names.
              },

              account: {
                id: localStorage.getItem("org_id") || "" // Required if using Pendo Feedback (org id)
                // name:         // Optional
                // is_paying:    // Recommended if using Pendo Feedback
                // monthly_value:// Recommended if using Pendo Feedback
                // planLevel:    // Optional
                // planPrice:    // Optional
                // creationDate: // Optional

                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
              }
            });
            // }
            getPlaybook();
            getMtm();
            getVideos();
            getSprints();
            getTemplates();
            getUserSprints();
          }
          setLoad(false);
        })();
      }, 1000);
    }
  }

  return <div></div>;
};

Header.propTypes = {
  routesArray: PropTypes.array,
  path: PropTypes.string,
  url: PropTypes.string,
  pathURL: PropTypes.func,
  errors: PropTypes.any
};

const mapStateToProps = (state) => ({
  routesArray: state.routes.routesArray,
  path: state.routes.path,
  url: state.routes.url,
  errors: state.errors,
  isLoggedIn: getAuthStatus(state),
  glossaryData: getGlossaryData(state),
  isLoaded: getLoaded(state),
  isOnboarded: getOnboarding(state),
  mtmData: getMtmData(state),
  tier: getOrgTier(state)
});

export default connect(mapStateToProps, {
  pathURL,
  setError,
  getGlossary,
  getMtm,
  getPlaybook,
  loadOrgData,
  getScores,
  getVideos,
  getSprints,
  getTemplates,
  getUserSprints
})(Header);
