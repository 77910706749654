import { Dispatch } from "redux";
import { ActionTypes } from "../types";

export interface Routes {
  routesArray: [];
  path: string;
  url: string;
}

export interface PathSet {
  path: string;
  url: string;
}

export interface SetAppRoutesAction {
  type: ActionTypes.appRoutes;
  payload: [];
}

export interface SetPathURLAction {
  type: ActionTypes.pathURL;
  payload: PathSet;
}

export const appRoutes = (routes: []) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    dispatch<SetAppRoutesAction>({
      type: ActionTypes.appRoutes,
      payload: routes
    });
  };
};

export const pathURL = (path: string, url: string) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    dispatch<SetPathURLAction>({
      type: ActionTypes.pathURL,
      payload: { path, url }
    });
  };
};
