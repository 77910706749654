/*global console*/
// import "./Public.scss";
import "./Referral.scss";

import React, { Component } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Spinner from "react-bootstrap/Spinner";
import PublicWrapper from "./PublicWrapper";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAuthStatus } from "../redux/selectors/authSelectors";
import { pathURL } from "../redux/actions/routes";
import { registerUser, getReferral } from "../redux/actions/auth";
import { toLowerCase } from "../secure/helpers/helpers";

// Multi-step flow from this guide: https://css-tricks.com/the-magic-of-react-based-multi-step-forms/

class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner_referral: "",
      error: false,
      errorMessage: "",
      loading: true,
      validated: false,
      currentStep: 1,
      first_name: "",
      last_name: "",
      email: "",
      company_name: "",
      company_industry: "",
      stage: "",
      engagement: "",
      description: "",
      referral: "",
      pitch: "",
      ideal_investor: "",
      partner: {
        active: false,
        description: "",
        logo_url: "",
        shortname: "",
        url: "",
        name: ""
      }
    };
  }

  handleValidation() {
    const {
      first_name,
      last_name,
      email,
      company_name,
      company_industry,
      referral,
      stage,
      engagement,
      password,
      password_confirm,
      description
    } = this.state;
    let formIsValid = true;

    //Name
    if (!first_name || !last_name) {
      // eslint-disable-line no-console
      console.log("name issue");
      formIsValid = false;
    }

    if (typeof first_name !== "undefined") {
      if (!first_name.match(/^[A-Za-z\s\-]+$/)) {
        // eslint-disable-line no-console
        console.log("first name issue");

        formIsValid = false;
      }
    }

    if (typeof last_name !== "undefined") {
      if (!last_name.match(/^[A-Za-z\s\-]+$/)) {
        // eslint-disable-line no-console
        console.log("last name issue");

        formIsValid = false;
      }
    }

    //Email
    if (!email) {
      // eslint-disable-line no-console
      console.log("no email");

      formIsValid = false;
    }

    if (typeof email !== "undefined") {
      let lastAtPos = email.lastIndexOf("@");
      let lastDotPos = email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        // eslint-disable-line no-console
        console.log("email validation issue");

        formIsValid = false;
      }
    }

    if (!company_name) {
      // eslint-disable-line no-console
      console.log("company name issue");

      formIsValid = false;
    }

    if (!company_industry) {
      // eslint-disable-line no-console
      console.log("industry issue");

      formIsValid = false;
    }

    // if (!referral) {
    //   // eslint-disable-line no-console
    //   console.log("referral");

    //   formIsValid = false;
    // }

    if (!description) {
      // eslint-disable-line no-console
      console.log("description");

      formIsValid = false;
    }

    if (!stage) {
      // eslint-disable-line no-console
      console.log("stage");

      formIsValid = false;
    }

    if (!password) {
      // eslint-disable-line no-console
      console.log("password");

      formIsValid = false;
    }

    //Email
    if (!engagement) {
      // eslint-disable-line no-console
      console.log("no engagement");

      formIsValid = false;
    }

    return formIsValid;
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    });

    const form = e.currentTarget;
    if (!this.handleValidation()) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        validated: true,
        error: true,
        loading: false,
        errorMessage: "Please fill out all form fields correctly"
      });
      e.preventDefault();
    } else {
      const {
        first_name,
        last_name,
        email,
        company_name,
        company_industry,
        referral,
        stage,
        pitch,
        ideal_investor,
        description,
        engagement,
        partner_referral,
        password,
        password_confirm
      } = this.state;
      // Post to db, if post is successful, update redux to persist changes
      (async () => {
        const response = await this.props.registerUser({
          first_name: first_name,
          last_name: last_name,
          email: email,
          role: 1,
          is_active: true,
          password: password,
          password_confirm: password_confirm,
          organization: {
            name: company_name,
            industry: company_industry,
            referral: partner_referral
          },
          org_info: {
            referral: referral,
            stage: stage,
            pitch: pitch,
            ideal_investor: ideal_investor,
            description: description,
            engagement: engagement
          }
        });
        if (response && response.status === 200) {
          this.setState({
            loading: false,
            redirect: true
          });
          return;
        } else {
          let errorMessage = "";
          if (response && response.data && response.data.Error) {
            errorMessage = response.data.Error;
          }
          this.setState({
            error: true,
            errorMessage: `An error occured: ${errorMessage}`,
            loading: false
          });
        }
      })();
    }
  };
  componentDidMount() {
    const { match, location } = this.props;

    const referrerMatch = location.search.substring(
      location.search.lastIndexOf("referrer=") + 9
    );

    this.setState({
      partner_referral: referrerMatch
    });

    if (referrerMatch) {
      // create partner lookup api call
      // if response comes back with partner, call for logo
      // if no partner continue as normal
      // set loading to false

      (async () => {
        const response = await this.props.getReferral({
          referral_id: referrerMatch
        });
        if (response && response.status === 200) {
          this.setState({
            loading: false,
            partner: response.data.data
          });
          return;
        } else {
          this.setState({
            error: true,
            errorMessage: "An error occured.",
            loading: false
          });
        }
      })();
    }
    this.props.pathURL(match.path, match.url);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = (event) => {
    this.setState({
      error: false,
      errorMessage: ""
    });

    const { name, value } = event.target;
    this.setState({
      [name]: name === "email" ? toLowerCase(value) : value
    });
  };

  handleStageRadioChange = (event) => {
    const { id } = event.target;

    this.setState({
      stage: id
    });
  };

  _next = () => {
    if (this.state.first_name && this.state.last_name && this.state.email) {
      let currentStep = this.state.currentStep;
      currentStep = currentStep >= 1 ? 2 : currentStep + 1;
      this.setState({
        currentStep: currentStep
      });
      window.scrollTo(0, 0);
    }
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep
    });
    window.scrollTo(0, 0);
  };

  /*
   * the functions for our button
   */
  previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1) {
      return (
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={this._prev}
        >
          previous
        </button>
      );
    }
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep < 2) {
      return (
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={this._next}
        >
          continue
        </button>
      );
    }
    return null;
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/login"} />;
    }

    if (!this.state.loading && !this.state.partner.logo_url) {
      return <Redirect to={"/register"} />;
    }

    return (
      <React.Fragment>
        <PublicWrapper>
          <Card body className="referral-register">
            {this.state.partner.logo_url ? (
              <div>
                <Image
                  src={this.state.partner.logo_url}
                  fluid
                  alt="DealEngine logo, a rocket taking off over an upwards trending bar chart"
                  className="logo"
                />
                <h2>
                  Welcome, you&apos;ve been referred by{" "}
                  {this.state.partner.name}!
                </h2>
                <br></br>
                <p>
                  <a
                    href={this.state.partner.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {this.state.partner.name}
                  </a>{" "}
                  has partnered with DealEngine to provide your company with
                  free access to the DealEngine app, which features:
                </p>
                <br></br>
                <ul>
                  <li>
                    Playbook, your guided roadmap to the startup journey with
                    tools and resources
                  </li>
                  <li>
                    Metrics That Matter - detailed analysis of your company KPIs
                  </li>
                  <li>DealScore - your company viability score</li>
                  <li>Explainer Videos</li>
                  <li>Optional Webinars and Workshops</li>
                </ul>
              </div>
            ) : (
              <div></div>
            )}
            {this.state.loading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <Form
                className="form-signin"
                onSubmit={this.handleSubmit}
                noValidate
                validated={this.state.validated}
              >
                <h1 className="create">create your account</h1>

                {/*
          render the form steps and pass required props in
        */}
                <Step1
                  currentStep={this.state.currentStep}
                  handleChange={this.handleChange}
                  email={this.state.email}
                  first_name={this.state.first_name}
                  last_name={this.state.last_name}
                  company_name={this.state.company_name}
                  partner_info={this.state.partner}
                  password={this.state.password}
                  password_confirm={this.state.password_confirm}
                />
                <Step2
                  currentStep={this.state.currentStep}
                  handleChange={this.handleChange}
                  company_industry={this.state.company_industry}
                  stage={this.state.stage}
                  engagement={this.state.engagement}
                  description={this.state.description}
                  referral={this.state.referral}
                  pitch={this.state.pitch}
                  ideal_investor={this.state.ideal_investor}
                  loading={this.state.loading}
                  errorMessage={this.state.errorMessage}
                  error={this.state.error}
                />
                {this.previousButton()}
                {this.nextButton()}
              </Form>
            )}
          </Card>
        </PublicWrapper>
      </React.Fragment>
    );
  }
}

function Step1(props) {
  if (props.currentStep !== 1) {
    return null;
  }
  return (
    <div>
      <Form.Group>
        <Form.Label htmlFor="first_name">
          <br />
          What is your name?
        </Form.Label>
        <Form.Control
          id="first_name"
          name="first_name"
          type="text"
          placeholder="First Name"
          value={props.first_name}
          onChange={props.handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Control
          id="last_name"
          name="last_name"
          type="text"
          placeholder="Last Name"
          value={props.last_name}
          onChange={props.handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="first_name">What is your company name?</Form.Label>
        <Form.Control
          id="company_name"
          name="company_name"
          type="text"
          placeholder="Company Name"
          value={props.company_name}
          onChange={props.handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="email">Email</Form.Label>
        <Form.Control
          id="email"
          name="email"
          type="text"
          placeholder="Enter Email"
          value={props.email}
          onChange={props.handleChange}
          isInvalid={
            props.email &&
            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              props.email
            )
          }
          required
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid email.
        </Form.Control.Feedback>
        <Form.Group className="form-group">
          <Form.Label htmlFor="password">Password</Form.Label>
          <Form.Control
            className="form-control"
            id="password"
            name="password"
            type="password"
            placeholder="Enter password"
            value={props.password}
            onChange={props.handleChange}
            isInvalid={
              props.password &&
              !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
                props.password
              )
            }
            required
          />
          <Form.Control.Feedback type="invalid">
            Please choose a stronger password (Minimum eight characters, at
            least one uppercase letter, one lowercase letter, one number and one
            special character)
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label htmlFor="password_confirm">Password Confirm</Form.Label>
          <Form.Control
            className="form-control"
            id="password_confirm"
            name="password_confirm"
            type="password"
            placeholder="Enter password again"
            value={props.password_confirm}
            onChange={props.handleChange}
            isInvalid={
              props.password_confirm &&
              props.password_confirm !== props.password
            }
            required
          />
          <Form.Control.Feedback type="invalid">
            Passwords do not match
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Group>
    </div>
  );
}

const industries = [
  { code: "ADT", name: "Adtech" },
  { code: "ADM", name: "Advanced Manufacturing" },
  { code: "AID", name: "AI & Big Data" },
  { code: "BFA", name: "Beauty & Fashion" },
  { code: "BIO", name: "Biopharma" },
  { code: "CRY", name: "Blockchain and Crypto" },
  { code: "CLT", name: "CleanTech" },
  { code: "CON", name: "Consumer Electronics" },
  { code: "CYB", name: "Cybersecurity" },
  { code: "DIH", name: "Digital Health" },
  { code: "DIM", name: "Digital Media" },
  { code: "EDT", name: "Edtech" },
  { code: "ENE", name: "Energy & Environmental" },
  { code: "ENT", name: "Enterprise" },
  { code: "FIN", name: "Fintech" },
  { code: "GAM", name: "Gaming" },
  { code: "GOV", name: "GovTech" },
  { code: "MED", name: "MedTech" },
  { code: "MOB", name: "Mobile" },
  { code: "PRT", name: "PropertyTech" },
  { code: "RET", name: "Retail & Ecommerce" },
  { code: "ROB", name: "Robotics" },
  { code: "SMC", name: "Smart City" },
  { code: "SOC", name: "Social Media" },
  { code: "SPA", name: "SpaceTech" },
  { code: "SPT", name: "SportsTech" },
  { code: "TEL", name: "Telecom/5G" },
  { code: "TRA", name: "Transportation" },
  { code: "TRV", name: "Travel" },
  { code: "OTH", name: "Other" }
];

const referralChoices = [
  { value: "referral", text: "Referred by a friend/colleague" },
  { value: "linkedin", text: "LinkedIn" },
  { value: "twitter", text: "Twitter" },
  { value: "facebook", text: "Facebook" },
  { value: "internet", text: "Internet Search" },
  { value: "other", text: "Other" }
];

const companyStage = [
  { value: "Idea", text: "Idea" },
  { value: "Prototype", text: "Prototype" },
  { value: "MVP", text: "MVP" },
  { value: "Market Launch", text: "Market Launch" },
  { value: "Post-Revenue", text: "Post-Revenue" },
  { value: "Scaling", text: "Scaling" }
];

function Step2(props) {
  if (props.currentStep !== 2) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="form-group step-2 second-step">
        {/* Where did you hear about us? */}
        {/* <Form.Group>
          <Form.Label htmlFor="referral">
            Where did you hear about us?
          </Form.Label>
          <Form.Control
            as="select"
            custom
            id="referral"
            name="referral"
            defaultValue={props.referral}
            onChange={props.handleChange}
            required
          >
            <option value={""} key="options" hidden>
              {"Select One"}
            </option>
            {referralChoices.map((data) => (
              <option value={data.value} key={data.text}>
                {data.text}
              </option>
            ))}
          </Form.Control>
          <>
            {props.referral !== "referral" &&
            props.referral !== "linkedin" &&
            props.referral !== "twitter" &&
            props.referral !== "facebook" &&
            props.referral !== "internet" &&
            props.referral !== "" ? (
              <Form.Control
                id="referral"
                name="referral"
                type="text"
                placeholder="How did you hear about us?"
                onChange={props.handleChange}
              />
            ) : (
              <></>
            )}
          </>
        </Form.Group> */}
        <Form.Group>
          <Form.Label htmlFor="company_industry">
            What is your industry?
          </Form.Label>
          <Form.Control
            as="select"
            custom
            id="company_industry"
            name="company_industry"
            defaultValue={props.company_industry}
            onChange={props.handleChange}
            required
          >
            <option value={""} key="options" hidden>
              {"Select One"}
            </option>
            {industries.map((data) => (
              <option value={data.name} key={data.code}>
                {data.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="stage">
            What stage is your company in?
          </Form.Label>
          <Form.Control
            as="select"
            custom
            id="stage"
            name="stage"
            defaultValue={props.stage}
            onChange={props.handleChange}
            required
          >
            <option value={""} key="options" hidden>
              {"Select One"}
            </option>
            {companyStage.map((data) => (
              <option value={data.value} key={data.text}>
                {data.text}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="engagement">
            What is your level of engagement with the customer right now?
          </Form.Label>
          <Form.Control
            as="select"
            custom
            id="engagement"
            name="engagement"
            defaultValue={props.engagement}
            onChange={props.handleChange}
            required
          >
            <option value={""} key="options" hidden>
              {"Customer engagement levels"}
            </option>
            {[
              "I need to validate market demand",
              "Customers are awaiting my product",
              "I have some sales",
              "Customers love me!",
              "I am ready to tap bigger markets"
            ].map((data) => (
              <option value={data} key={data}>
                {data}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {/* Pitch video link */}
        <Form.Group>
          <Form.Label htmlFor="pitch">Pitch video link (optional)</Form.Label>
          <Form.Control
            id="pitch"
            name="pitch"
            value={props.pitch}
            onChange={props.handleChange}
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="description">Company description</Form.Label>
          <Form.Control
            as="textarea"
            id="description"
            name="description"
            maxLength="2048"
            placeholder={
              props.company_name ? `${props.company_name} is...` : ""
            }
            value={props.description}
            style={{ height: "100px" }}
            onChange={props.handleChange}
            required
          ></Form.Control>
        </Form.Group>
        {/* Who is your ideal investor? */}
        <Form.Group>
          <Form.Label htmlFor="ideal_investor">
            Who is your ideal investor? (optional)
          </Form.Label>
          <Form.Control
            id="ideal_investor"
            name="ideal_investor"
            value={props.ideal_investor}
            placeholder={" Angel Investor, VC, etc."}
            onChange={props.handleChange}
          ></Form.Control>
        </Form.Group>
      </div>
      <Button variant="outline-primary" type="submit" disabled={props.loading}>
        {props.loading ? "Loading…" : "get started"}
      </Button>
      {props.error && <div className="error">{props.errorMessage}</div>}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  success: getAuthStatus(state)
});

Referral.propTypes = {};

export default connect(mapStateToProps, {
  pathURL,
  registerUser,
  getReferral
})(Referral);
