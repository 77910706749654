import React from "react";
import { Donut } from "britecharts-react";
//import "britecharts-react/dist/britecharts-react.min.css";

// trying out britecharts
//https://github.com/britecharts/britecharts-react

export default function BriteChart({ data: data }) {
  const { total, answered } = data;
  const remainingQuestions = total - answered;
  const remainingPercentage = (remainingQuestions / total) * 100;
  const answeredPercentage = (answered / total) * 100;
  // if (data.total) {
  return (
    <>
      <Donut
        data={[
          {
            quantity: answered,
            percentage: answeredPercentage.toFixed(2),
            name: "Answered",
            id: 1
          },
          {
            quantity: remainingQuestions,
            percentage: remainingPercentage.toFixed(2),
            name: "To do",
            id: 2
          }
        ]}
        colorSchema={["#eb5757", "#edebe9"]}
        internalRadius={90}
        shouldShowLoadingState={!data.total}
        highlightSliceById={1}
        isAnimated={false}
      />
      {/* <Legend
        data={[
          {
            id: 1,
            quantity: 60,
            name: "Team"
          },
          {
            id: 2,
            quantity: 20,
            name: "Product"
          },
          {
            id: 3,
            quantity: 10,
            name: "Market"
          },
          {
            id: 4,
            quantity: 10,
            name: "Money"
          }
        ]}
        colorSchema={["#b21991", "#ffd600", "#badbef", "#ff9c12"]}
        height={250}
        width={600}
      /> */}
    </>
  );
  // } else return null;
}
