import "./Public.scss";
import React, { Component } from "react";
import { Container } from "react-bootstrap";
import BrandNav from "./BrandNav";
import "../index.scss"; //../  src/index.scss
import MarketFooter from "./MarketFooter";

class PublicWrapper extends Component {
  render() {
    return (
      <>
        <div className="public-wrap">
          <BrandNav />
          <Container>
            <main role="main">{this.props.children}</main>
          </Container>
          <MarketFooter />
        </div>
      </>
    );
  }
}

export default PublicWrapper;
