import "./Nav.scss";

import DropdownButton from "react-bootstrap/DropdownButton";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import React from "react";
import { determinePath } from "../../helpers/helpers";
import { useLocation } from "react-router-dom";

// Need to use LinkContainer as wrapper to react-bootstrap
const DENav = () => {
  // Logic to determine Menu title by url
  const location = useLocation();

  const org_name = localStorage.getItem("org_name") || "Welcome!";

  const tier = localStorage.getItem("tier") || "1";

  return (
    <Navbar
      id="de-navbar"
      collapseOnSelect
      expand="sm"
      bg="light"
      variant="light"
      sticky="top"
    >
      <div className="container">
        <Nav className="mr-auto" aria-labelledby="primary-navigation">
          <DropdownButton
            title={determinePath(location.pathname)}
            variant="outline-info"
            id="nav-dropdown-item-button"
          >
            <NavDropdown.Item as={Link} to={"/"}>
              Dashboard
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to={"/playbook"}>
              Playbook
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to={"/mtm"}>
              Metrics
            </NavDropdown.Item>
            {/* <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to={"/team"}>
              Team
            </NavDropdown.Item> */}
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to={"/glossary"}>
              Glossary
            </NavDropdown.Item>

            {tier === "2" || tier === "3" ? (
              <>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to={"/premium"}>
                  Premium
                </NavDropdown.Item>
              </>
            ) : null}
            {/* <NavDropdown.Divider /> */}
            {/* <NavDropdown.Item as={Link} to={"/profile"}>
              Profile &amp; Settings
            </NavDropdown.Item> */}
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to={"/logout"}>
              Sign out
            </NavDropdown.Item>
          </DropdownButton>
        </Nav>
        <Navbar.Text className="d-none d-sm-block">
          {org_name}
          {/* import Image */}
          <Image></Image>
        </Navbar.Text>
      </div>
    </Navbar>
  );
};

export default DENav;
