import "./Dashboard.scss";

import {
  getDealScore,
  getLastUpdatedScore,
  getMarketScore,
  getMoneyScore,
  getProductScore,
  getTeamScore
} from "../../../redux/selectors/scoresSelectors";
import {
  getMTMAnswered,
  getMTMTotal,
  getMtmNextSteps,
  showModal
} from "../../../redux/selectors/mtmSelectors";
import {
  getPlaybookAllAnswered,
  getPlaybookAllTotal,
  getPlaybookCompanyAnswered,
  getPlaybookCompanyTotal,
  getPlaybookMarketAnswered,
  getPlaybookMarketTotal,
  getPlaybookMoneyAnswered,
  getPlaybookMoneyTotal,
  getPlaybookProductAnswered,
  getPlaybookProductTotal,
  getPlaybookTeamAnswered,
  getPlaybookTeamTotal
} from "../../../redux/selectors/playbookSelectors";

import { getOrgStage } from "../../../redux/selectors/authSelectors";

import { roundTwoPlaces } from "../../helpers/helpers";

import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ScoreRadial } from "../../components/Charts/ScoreRadial";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import QsCompleted from "../../components/Charts/QsCompleted";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import { StoreState } from "../../../redux";
import Wrapper from "../../components/Wrapper/Wrapper";
import { WelcomeModal } from "../../components/WelcomeModal/WelcomeModal";
import { connect } from "react-redux";
import { pathURL } from "../../../redux/actions/routes";
import { SizeMe } from "react-sizeme";

// Using match with typescript is weird, used this logic to set up the type:
// https://stackoverflow.com/questions/48138111/what-typescript-type-should-i-use-to-reference-the-match-object-in-my-props
interface MatchParams {
  name: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
  path: string;
  url: string;
}

interface AppProps {
  // Because typescript can't understand a redux thunk action
  // Need to make fetchTodos a generic function instead of
  // strict type

  pathURL: (path: string, url: string) => void;
  match: MatchProps;
  playbookTotal: any;
  playbookAnswered: any;
  mtmTotal: any;
  mtmAnswered: any;
  mtmNextSteps: any;
  showModal: any;
  playbookTeamTotal: any;
  playbookTeamAnswered: any;
  playbookProductTotal: any;
  playbookProductAnswered: any;
  playbookMarketTotal: any;
  playbookMarketAnswered: any;
  playbookMoneyTotal: any;
  playbookMoneyAnswered: any;
  playbookCompanyTotal: any;
  playbookCompanyAnswered: any;
  dealScore: any;
  productScore: any;
  marketScore: any;
  moneyScore: any;
  teamScore: any;
  scoreLastUpdated: any;
  orgStage: any;
}

interface AppState {
  fetching: boolean;
}

class _Dashboard extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = { fetching: false };
  }

  componentDidMount() {
    this.props.pathURL(this.props.match.path, this.props.match.url);
  }

  componentDidUpdate(prevProps: AppProps): void {
    // replace this with another data load if needed
    // if (!prevProps.todos.length && this.props.todos.length) {
    //   this.setState({ fetching: false });
    // }
  }

  render() {
    return (
      <div className="App dash-wrap">
        <Wrapper>
          {this.props.showModal ? (
            <>
              <WelcomeModal />
            </>
          ) : null}
          <CardDeck>
            <Card>
              <Card.Body>
                <Card.Title>
                  <h2>dealscore</h2>
                </Card.Title>
                {this.props.scoreLastUpdated ? (
                  <>
                    <Card.Text>
                      <strong>
                        Your overall Dealscore is {this.props.dealScore}.&nbsp;
                      </strong>
                      This is the result of our proprietary analysis of the data
                      provided, focusing on four key business areas. As you
                      progress and respond to feedback, your overall and area
                      scores will improve.
                    </Card.Text>
                    <div className="ds-graph">
                      <SizeMe>
                        {({ size }) => (
                          <ScoreRadial
                            data={[
                              {
                                value: this.props.teamScore,
                                color: "#5AA8D8"
                              },
                              {
                                value: this.props.productScore,
                                color: "#FFD600"
                              },
                              {
                                value: this.props.marketScore,
                                color: "#B21991"
                              },
                              {
                                value: this.props.moneyScore,
                                color: "#FF9C12"
                              }
                            ]}
                            size={size}
                          />
                        )}
                      </SizeMe>
                      <div id="legend" aria-label="Legend">
                        <h3>Area Scores</h3>
                        <ul>
                          <li>
                            <div>&nbsp;</div>Team:{" "}
                            {roundTwoPlaces(this.props.teamScore)}
                          </li>
                          <li>
                            <div>&nbsp;</div>Product:{" "}
                            {roundTwoPlaces(this.props.productScore)}
                          </li>
                          <li>
                            <div>&nbsp;</div>Market:{" "}
                            {roundTwoPlaces(this.props.marketScore)}
                          </li>
                          <li>
                            <div>&nbsp;</div>Money:{" "}
                            {roundTwoPlaces(this.props.moneyScore)}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <p className="no-scores-text">
                      Your Dealscore will be available soon. Until then, check
                      out the <Link to={"/playbook"}>Playbook</Link> or answer
                      additional questions in <Link to={"/mtm"}>Metrics</Link>{" "}
                      to get to get a more accurate score.
                    </p>
                  </div>
                )}
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>
                  <h2>next steps</h2>
                </Card.Title>
                <Card.Text>
                  Take these next steps to improve your business. Dont forget to
                  come back and check off what you completed.
                </Card.Text>
              </Card.Body>
              <ul className="next-steps">
                {/* This shows the top four unanswered MTM questions */}
                {this.props.mtmNextSteps.slice(0, 3).map((step: any) => (
                  <li key={step.key}>
                    <Link
                      key={step.key}
                      to={{
                        pathname: "/mtm",
                        state: { filterValue: step.category }
                      }}
                    >
                      <div>Update MTM Profile</div>
                      <span>{step.title}</span>
                    </Link>
                  </li>
                ))}
                {/* This will always show at the bottom of the list */}
                {/* Jeanette...if there is only one item, the card header pushes the record down to the bottom of the widget */}
                <li>
                  <HashLink
                    to="/playbook"
                    scroll={(el) => {
                      el.scrollIntoView({
                        behavior: "smooth",
                        block: "center"
                      });
                    }}
                  >
                    <div>Update your Playbook </div>
                    <span>Update Playbook</span>
                  </HashLink>
                </li>
              </ul>
            </Card>
          </CardDeck>
          <CardDeck>
            <Card>
              <Card.Body>
                <Card.Title>
                  <h2>metrics that matter</h2>
                </Card.Title>
                <Card.Text>
                  Answers to these important <Link to={"/mtm"}>questions</Link>{" "}
                  will give investors a better picture of your company.
                </Card.Text>
                <QsCompleted
                  data={{
                    total: this.props.mtmTotal,
                    answered: this.props.mtmAnswered
                  }}
                />
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>
                  <h2>playbook progress</h2>
                </Card.Title>
                <Card.Text>
                  Find your progress in your {this.props.orgStage} stage of the{" "}
                  <Link to={"/playbook"}>Playbook</Link>.
                </Card.Text>
              </Card.Body>
              {/* TODO what vars look like: now={now} label={`${now}%`} srOnly, also this is technically a nav element aria-labelledby="playbook-navigation */}
              <Container aria-labelledby="playbook-navigation">
                {/* TODO anchor tags need to autopopulate to id of section/tab */}
                {/* Make this a conditional since Team and Company switch at times */}
                {/* Make this a conditional since Team and Company switch at times */}
                <Row>
                  <Col className="progress-cap">Team</Col>
                  <Col className="Team">
                    <ProgressBar
                      now={
                        (this.props.playbookTeamAnswered /
                          this.props.playbookTeamTotal) *
                        100
                      }
                    ></ProgressBar>
                  </Col>
                </Row>
                <Row>
                  <Col className="progress-cap">Product</Col>
                  <Col className="Product">
                    <ProgressBar
                      now={
                        (this.props.playbookProductAnswered /
                          this.props.playbookProductTotal) *
                        100
                      }
                    ></ProgressBar>
                  </Col>
                </Row>
                <Row>
                  <Col className="progress-cap">Market</Col>
                  <Col className="Market">
                    <ProgressBar
                      now={
                        (this.props.playbookMarketAnswered /
                          this.props.playbookMarketTotal) *
                        100
                      }
                    ></ProgressBar>
                  </Col>
                </Row>
                <Row>
                  <Col className="progress-cap">Money</Col>
                  <Col className="Money">
                    <ProgressBar
                      now={
                        (this.props.playbookMoneyAnswered /
                          this.props.playbookMoneyTotal) *
                        100
                      }
                    ></ProgressBar>
                  </Col>
                </Row>
              </Container>
            </Card>
          </CardDeck>
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = (
  state: StoreState
): {
  playbookTotal: any;
  playbookAnswered: any;
  mtmTotal: any;
  mtmAnswered: any;
  mtmNextSteps: any;
  showModal: any;
  playbookTeamTotal: any;
  playbookTeamAnswered: any;
  playbookProductTotal: any;
  playbookProductAnswered: any;
  playbookMarketTotal: any;
  playbookMarketAnswered: any;
  playbookMoneyTotal: any;
  playbookMoneyAnswered: any;
  playbookCompanyTotal: any;
  playbookCompanyAnswered: any;
  dealScore: any;
  productScore: any;
  marketScore: any;
  moneyScore: any;
  teamScore: any;
  scoreLastUpdated: any;
  orgStage: any;
} => {
  return {
    playbookTotal: getPlaybookAllTotal(state),
    playbookAnswered: getPlaybookAllAnswered(state),
    mtmTotal: getMTMTotal(state),
    mtmAnswered: getMTMAnswered(state),
    mtmNextSteps: getMtmNextSteps(state),
    showModal: showModal(state),
    playbookTeamTotal: getPlaybookTeamTotal(state),
    playbookTeamAnswered: getPlaybookTeamAnswered(state),
    playbookProductTotal: getPlaybookProductTotal(state),
    playbookProductAnswered: getPlaybookProductAnswered(state),
    playbookMarketTotal: getPlaybookMarketTotal(state),
    playbookMarketAnswered: getPlaybookMarketAnswered(state),
    playbookMoneyTotal: getPlaybookMoneyTotal(state),
    playbookMoneyAnswered: getPlaybookMoneyAnswered(state),
    playbookCompanyTotal: getPlaybookCompanyTotal(state),
    playbookCompanyAnswered: getPlaybookCompanyAnswered(state),
    dealScore: getDealScore(state),
    productScore: getProductScore(state),
    marketScore: getMarketScore(state),
    moneyScore: getMoneyScore(state),
    teamScore: getTeamScore(state),
    scoreLastUpdated: getLastUpdatedScore(state),
    orgStage: getOrgStage(state)
  };
};

export const Dashboard = connect(mapStateToProps, {
  pathURL
})(_Dashboard);
