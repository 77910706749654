import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./Public.scss";
import "./BetaRegister.scss";
import { connect } from "react-redux";
import { pathURL } from "../redux/actions/routes";
import { logoutUser } from "../redux/actions/auth";
import PublicWrapper from "./PublicWrapper";

class BetaConfirmation extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    const { match } = this.props;
    this.props.pathURL(match.path, match.url);
    this.props.logoutUser();
  }

  render() {
    // If user hasn't been redirected to this page ("POP"), then send them to root
    if (this.props.history.action !== "REPLACE") {
      return <Redirect to={"/"} />;
    }
    return (
      <>
        <PublicWrapper>
          <div className="beta-confirmation mx-auto text-center">
            <h1>Thank you for your interest in DealEngine!</h1>
            <p>
              Our beta cohort is currently full, but we have added you to our
              wait list. If space becomes available, you will receive an email
              from us once your account has been activated. If you have any any
              questions or concerns, please contact us at&nbsp;
              <a href="mailto:support@dealengine.ai?subject=Beta Cohort Inquiry">
                support@dealengine.ai
              </a>
            </p>
          </div>
        </PublicWrapper>
      </>
    );
  }
}

BetaConfirmation.propTypes = {};

export default connect(null, { pathURL, logoutUser })(BetaConfirmation);
