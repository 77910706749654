import "../../../index.scss";
import React, { Component } from "react";
import { Container } from "react-bootstrap";
import BrandNav from "../../../public/BrandNav";
import AppFooter from "../AppFooter/AppFooter";

class OnboardWrapper extends Component {
  render() {
    return (
      <>
        <BrandNav />
        <Container className="onboard-wrap">
          <main role="main">{this.props.children}</main>
        </Container>
        <AppFooter />
      </>
    );
  }
}

export default OnboardWrapper;
