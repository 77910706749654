import "./Public.scss";

import { Link, Redirect } from "react-router-dom";
import React, { Component } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import PublicWrapper from "./PublicWrapper";
import axios from "axios";
import { connect } from "react-redux";
import { pathURL } from "../redux/actions/routes";

class PasswordReset extends Component {
  constructor() {
    super();
    this.state = {
      error: false,
      errorMessage: "",
      loading: false,
      redirect: false,
      email: "",
      password: ""
    };
  }

  submit = async (e) => {
    e.preventDefault();

    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.state.email
      )
    ) {
      this.setState({
        error: true,
        errorMessage: "Please provide a valid email address"
      });
      return;
    }
    this.setState({
      loading: true
    });
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_API_URL}api/reset-password`,
      {
        email: this.state.email
      }
    );

    if (response && response.status === 200) {
      this.setState({
        loading: false,
        redirect: true
      });
    }
  };
  componentDidMount() {
    const { match } = this.props;
    this.props.pathURL(match.path, match.url);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  resetError = (event) => {
    this.setState({
      error: false,
      errorMessage: ""
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/login"} />;
    }

    return (
      <>
        <PublicWrapper>
          <Card body className="de-public" border="info">
            <h1>reset password</h1>
            <Form onSubmit={this.submit}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  id="inputEmail"
                  className="form-control"
                  placeholder="Email address"
                  name="email"
                  onFocus={this.resetError}
                  value={this.state.email}
                  onChange={this.handleChange}
                  isInvalid={
                    this.state.email &&
                    !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                      this.state.email
                    )
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email.
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                variant="outline-primary"
                type="submit"
                disabled={this.state.loading}
              >
                {this.state.loading ? "Loading…" : "request reset link"}
              </Button>
              {this.state.error && <div>{this.state.errorMessage}</div>}
              <Button variant="outline-secondary" as={Link} to="/register">
                apply for beta
              </Button>
              <Button variant="outline-secondary" as={Link} to="/login">
                sign in
              </Button>
            </Form>
          </Card>
        </PublicWrapper>
      </>
    );
  }
}

PasswordReset.propTypes = {};

export default connect(null, { pathURL })(PasswordReset);
