/*global console*/
import "./Public.scss";
import "./BetaRegister.scss";

import React, { Component } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import PublicWrapper from "./PublicWrapper";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getAuthStatus } from "../redux/selectors/authSelectors";
import { pathURL } from "../redux/actions/routes";
import { registerBetaUser } from "../redux/actions/auth";
import { toLowerCase } from "../secure/helpers/helpers";

// Multi-step flow from this guide: https://css-tricks.com/the-magic-of-react-based-multi-step-forms/

class BetaRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      loading: false,
      validated: false,
      currentStep: 1,
      first_name: "",
      last_name: "",
      email: "",
      company_name: "",
      company_industry: "",
      stage: "",
      engagement: "",
      description: "",
      referral: "",
      pitch: "",
      beta_choices: "",
      ideal_investor: ""
    };
  }

  handleValidation() {
    const {
      first_name,
      last_name,
      email,
      company_name,
      company_industry,
      referral,
      stage,
      beta_choices,
      engagement,
      description
    } = this.state;
    let formIsValid = true;

    //Name
    if (!first_name || !last_name) {
      // eslint-disable-line no-console
      console.log("name issue");
      formIsValid = false;
    }

    if (typeof first_name !== "undefined") {
      if (!first_name.match(/^[A-Za-z\s\-]+$/)) {
        // eslint-disable-line no-console
        console.log("first name issue");

        formIsValid = false;
      }
    }

    if (typeof last_name !== "undefined") {
      if (!last_name.match(/^[A-Za-z\s\-]+$/)) {
        // eslint-disable-line no-console
        console.log("last name issue");

        formIsValid = false;
      }
    }

    //Email
    if (!email) {
      // eslint-disable-line no-console
      console.log("no email");

      formIsValid = false;
    }

    if (typeof email !== "undefined") {
      let lastAtPos = email.lastIndexOf("@");
      let lastDotPos = email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        // eslint-disable-line no-console
        console.log("email validation issue");

        formIsValid = false;
      }
    }

    if (!company_name) {
      // eslint-disable-line no-console
      console.log("company name issue");

      formIsValid = false;
    }

    if (!company_industry) {
      // eslint-disable-line no-console
      console.log("industry issue");

      formIsValid = false;
    }

    if (!referral) {
      // eslint-disable-line no-console
      console.log("referral");

      formIsValid = false;
    }

    if (!stage) {
      // eslint-disable-line no-console
      console.log("stage");

      formIsValid = false;
    }

    if (!beta_choices) {
      // eslint-disable-line no-console
      console.log("beta");

      formIsValid = false;
    }

    //Description
    if (!description) {
      // eslint-disable-line no-console
      console.log("no description");

      formIsValid = false;
    }

    //Email
    if (!engagement) {
      // eslint-disable-line no-console
      console.log("no engagement");

      formIsValid = false;
    }

    return formIsValid;
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      loading: true
    });

    const form = e.currentTarget;
    if (!this.handleValidation()) {
      console.log("validation fail");
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        validated: true,
        error: true,
        loading: false,
        errorMessage: "Please fill out all form fields correctly"
      });
      e.preventDefault();
    } else {
      console.log("still registering");
      const {
        first_name,
        last_name,
        email,
        company_name,
        company_industry,
        referral,
        stage,
        pitch,
        beta_choices,
        ideal_investor,
        description,
        engagement
      } = this.state;
      // Post to db, if post is successful, update redux to persist changes
      (async () => {
        const response = await this.props.registerBetaUser({
          first_name: first_name,
          last_name: last_name,
          email: email,
          role: 1,
          is_active: false,
          organization: {
            name: company_name,
            industry: company_industry,
            referral: null
          },
          beta_info: {
            referral: referral,
            stage: stage,
            pitch: pitch,
            beta_choices: beta_choices,
            ideal_investor: ideal_investor,
            description: description,
            engagement: engagement,
            partner: null
          }
        });
        if (response && response.status === 200) {
          this.setState({
            loading: false,
            redirect: true
          });
          return;
        } else {
          let errorMessage = "";
          if (response && response.data && response.data.Error) {
            errorMessage = response.data.Error;
          }
          this.setState({
            error: true,
            errorMessage: `An error occured: ${errorMessage}`,
            loading: false
          });
        }
      })();
    }
  };
  componentDidMount() {
    const { match } = this.props;
    this.props.pathURL(match.path, match.url);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = (event) => {
    this.setState({
      error: false,
      errorMessage: ""
    });

    const { name, value } = event.target;
    this.setState({
      [name]: name === "email" ? toLowerCase(value) : value
    });
  };

  handleStageRadioChange = (event) => {
    const { id } = event.target;

    this.setState({
      stage: id
    });
  };

  _next = () => {
    if (this.state.first_name && this.state.last_name && this.state.email) {
      let currentStep = this.state.currentStep;
      currentStep = currentStep >= 1 ? 2 : currentStep + 1;
      this.setState({
        currentStep: currentStep
      });
      window.scrollTo(0, 0);
    }
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep
    });
    window.scrollTo(0, 0);
  };

  /*
   * the functions for our button
   */
  previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1) {
      return (
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={this._prev}
        >
          previous
        </button>
      );
    }
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep < 2) {
      return (
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={this._next}
        >
          continue
        </button>
      );
    }
    return null;
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/beta-confirmation"} />;
    }

    return (
      <React.Fragment>
        <PublicWrapper>
          <Card body className="beta-register">
            <h1 className="create">beta registration</h1>
            <Form
              className="form-signin"
              onSubmit={this.handleSubmit}
              noValidate
              validated={this.state.validated}
            >
              {/*
          render the form steps and pass required props in
        */}
              <Step1
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                email={this.state.email}
                first_name={this.state.first_name}
                last_name={this.state.last_name}
                company_name={this.state.company_name}
              />
              <Step2
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                company_industry={this.state.company_industry}
                stage={this.state.stage}
                engagement={this.state.engagement}
                description={this.state.description}
                referral={this.state.referral}
                pitch={this.state.pitch}
                beta_choices={this.state.beta_choices}
                ideal_investor={this.state.ideal_investor}
                loading={this.state.loading}
                errorMessage={this.state.errorMessage}
                error={this.state.error}
              />
              {this.previousButton()}
              {this.nextButton()}
            </Form>
          </Card>
        </PublicWrapper>
      </React.Fragment>
    );
  }
}

function Step1(props) {
  if (props.currentStep !== 1) {
    return null;
  }
  return (
    <div>
      <Form.Group>
        <Form.Label htmlFor="first_name">
          <h2>Be a Part of DealEngine’s Beta Launch!</h2> <br />
          <h3>
            At DealEngine, we are passionate about helping startups become
            better through data analysis. We are inviting a select group of
            startup founders to join us. We’ll analyze your company and provide
            valuable scoring and feedback.
          </h3>
          <br />
          What is your name?
        </Form.Label>
        <Form.Control
          id="first_name"
          name="first_name"
          type="text"
          placeholder="First Name"
          value={props.first_name}
          onChange={props.handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Control
          id="last_name"
          name="last_name"
          type="text"
          placeholder="Last Name"
          value={props.last_name}
          onChange={props.handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="email">Email</Form.Label>
        <Form.Control
          id="email"
          name="email"
          type="text"
          placeholder="Enter Email"
          value={props.email}
          onChange={props.handleChange}
          isInvalid={
            props.email &&
            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              props.email
            )
          }
          required
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid email.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="first_name">What is your company name?</Form.Label>
        <Form.Control
          id="company_name"
          name="company_name"
          type="text"
          placeholder="Company Name"
          value={props.company_name}
          onChange={props.handleChange}
          required
        />
      </Form.Group>
    </div>
  );
}

const industries = [
  { code: "ADT", name: "Adtech" },
  { code: "ADM", name: "Advanced Manufacturing" },
  { code: "AID", name: "AI & Big Data" },
  { code: "BFA", name: "Beauty & Fashion" },
  { code: "BIO", name: "Biopharma" },
  { code: "CRY", name: "Blockchain and Crypto" },
  { code: "CLT", name: "CleanTech" },
  { code: "CON", name: "Consumer Electronics" },
  { code: "CYB", name: "Cybersecurity" },
  { code: "DIH", name: "Digital Health" },
  { code: "DIM", name: "Digital Media" },
  { code: "EDT", name: "Edtech" },
  { code: "ENE", name: "Energy & Environmental" },
  { code: "ENT", name: "Enterprise" },
  { code: "FIN", name: "Fintech" },
  { code: "GAM", name: "Gaming" },
  { code: "GOV", name: "GovTech" },
  { code: "MED", name: "MedTech" },
  { code: "MOB", name: "Mobile" },
  { code: "PRT", name: "PropertyTech" },
  { code: "RET", name: "Retail & Ecommerce" },
  { code: "ROB", name: "Robotics" },
  { code: "SMC", name: "Smart City" },
  { code: "SOC", name: "Social Media" },
  { code: "SPA", name: "SpaceTech" },
  { code: "SPT", name: "SportsTech" },
  { code: "TEL", name: "Telecom/5G" },
  { code: "TRA", name: "Transportation" },
  { code: "TRV", name: "Travel" },
  { code: "OTH", name: "Other" }
];

const referralChoices = [
  { value: "referral", text: "Referred by a friend/colleague" },
  { value: "linkedin", text: "LinkedIn" },
  { value: "twitter", text: "Twitter" },
  { value: "facebook", text: "Facebook" },
  { value: "internet", text: "Internet Search" },
  { value: "other", text: "Other" }
];

const companyStage = [
  { value: "Idea", text: "Idea" },
  { value: "Prototype", text: "Prototype" },
  { value: "MVP", text: "MVP" },
  { value: "Market Launch", text: "Market Launch" },
  { value: "Post-Revenue", text: "Post-Revenue" },
  { value: "Scaling", text: "Scaling" }
];

const betaChoices = [
  { value: "now", text: "I’d like to join the next beta cohort" },
  {
    value: "later",
    text: "I’d rather wait for DealEngine to be available publicly "
  }
];

function Step2(props) {
  if (props.currentStep !== 2) {
    return null;
  }
  return (
    <React.Fragment>
      <h1>beta application</h1>
      <div className="form-group step-2">
        {/* Where did you hear about us? */}
        <Form.Group>
          <Form.Label htmlFor="referral">
            Where did you hear about us?
          </Form.Label>
          <Form.Control
            as="select"
            custom
            id="referral"
            name="referral"
            defaultValue={props.referral}
            onChange={props.handleChange}
            required
          >
            <option value={""} key="options" hidden>
              {"Select One"}
            </option>
            {referralChoices.map((data) => (
              <option value={data.value} key={data.text}>
                {data.text}
              </option>
            ))}
          </Form.Control>
          <>
            {props.referral !== "referral" &&
            props.referral !== "linkedin" &&
            props.referral !== "twitter" &&
            props.referral !== "facebook" &&
            props.referral !== "internet" &&
            props.referral !== "" ? (
              <Form.Control
                id="referral"
                name="referral"
                type="text"
                placeholder="How did you hear about us?"
                onChange={props.handleChange}
              />
            ) : (
              <></>
            )}
          </>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="company_industry">
            What is your industry?
          </Form.Label>
          <Form.Control
            as="select"
            custom
            id="company_industry"
            name="company_industry"
            defaultValue={props.company_industry}
            onChange={props.handleChange}
            required
          >
            <option value={""} key="options" hidden>
              {"Select One"}
            </option>
            {industries.map((data) => (
              <option value={data.name} key={data.code}>
                {data.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="stage">
            What stage is your company in?
          </Form.Label>
          <Form.Control
            as="select"
            custom
            id="stage"
            name="stage"
            defaultValue={props.stage}
            onChange={props.handleChange}
            required
          >
            <option value={""} key="options" hidden>
              {"Select One"}
            </option>
            {companyStage.map((data) => (
              <option value={data.value} key={data.text}>
                {data.text}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="engagement">
            What is your level of engagement with the customer right now?
          </Form.Label>
          <Form.Control
            as="select"
            custom
            id="engagement"
            name="engagement"
            defaultValue={props.engagement}
            onChange={props.handleChange}
            required
          >
            <option value={""} key="options" hidden>
              {"Customer engagement levels"}
            </option>
            {[
              "I need to validate market demand",
              "Customers are awaiting my product",
              "I have some sales",
              "Customers love me!",
              "I am ready to tap bigger markets"
            ].map((data) => (
              <option value={data} key={data}>
                {data}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {/* Pitch video link */}
        <Form.Group>
          <Form.Label htmlFor="pitch">Pitch video link (optional)</Form.Label>
          <Form.Control
            id="pitch"
            name="pitch"
            value={props.pitch}
            onChange={props.handleChange}
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="description">Company description</Form.Label>
          <Form.Control
            as="textarea"
            id="description"
            name="description"
            placeholder={
              props.company_name ? `${props.company_name} is...` : ""
            }
            value={props.description}
            style={{ height: "100px" }}
            onChange={props.handleChange}
            required
          ></Form.Control>
        </Form.Group>
        {/* Interest in joining dealengine beta */}
        <Form.Group>
          <Form.Label htmlFor="beta_choices">
            Interest in joining dealengine beta
          </Form.Label>
          <Form.Control
            as="select"
            custom
            id="beta_choices"
            name="beta_choices"
            defaultValue={props.beta_choices}
            onChange={props.handleChange}
            required
          >
            <option value={""} key="options" hidden>
              {"Select One"}
            </option>
            {betaChoices.map((data) => (
              <option value={data.value} key={data.text}>
                {data.text}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {/* Who is your ideal investor? */}
        <Form.Group>
          <Form.Label htmlFor="ideal_investor">
            Who is your ideal investor? (optional)
          </Form.Label>
          <Form.Control
            id="ideal_investor"
            name="ideal_investor"
            value={props.ideal_investor}
            placeholder={" Angel Investor, VC, etc."}
            onChange={props.handleChange}
          ></Form.Control>
        </Form.Group>
      </div>
      <Button variant="outline-primary" type="submit" disabled={props.loading}>
        {props.loading ? "Loading…" : "get started"}
      </Button>
      {props.error && <div className="error">{props.errorMessage}</div>}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  success: getAuthStatus(state)
});

BetaRegister.propTypes = {};

export default connect(mapStateToProps, { pathURL, registerBetaUser })(
  BetaRegister
);
