/* eslint-disable indent */
import React from "react";
import PropTypes from "prop-types";
import PersonCircle from "./PersonCircle";
import CaretLeft from "./CaretLeft";
import CaretRight from "./CaretRight";
import MedalGold from "./MedalGold";
import BusinessModel from "./businessModel";
import Competitors from "./competitors";
import Financials from "./financials";
import Fundraise from "./fundraise";
import GoToMarket from "./goToMarket";
import MarketSizing from "./marketSizing";
import Team from "./team";
import ValueProposition from "./valueProposition";

const Icon = (props) => {
  const { name } = props;
  switch (name) {
    case "caretLeft":
      return <CaretLeft {...props} />;
    case "caretRight":
      return <CaretRight {...props} />;
    case "personCircle":
      return <PersonCircle {...props} />;
    case "medalGold":
      return <MedalGold {...props} />;
    case "businessModel":
      return <BusinessModel {...props} />;
    case "competitors":
      return <Competitors {...props} />;
    case "financials":
      return <Financials {...props} />;
    case "fundraise":
      return <Fundraise {...props} />;
    case "goToMarket":
      return <GoToMarket {...props} />;
    case "marketSizing":
      return <MarketSizing {...props} />;
    case "team":
      return <Team {...props} />;
    case "valueProposition":
      return <ValueProposition {...props} />;
    default:
      return <div />;
  }
};

export default Icon;

Icon.propTypes = {
  name: PropTypes.string.isRequired
};
