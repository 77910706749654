export const generatePlaybook = (data) => {
  const determineValue = (value) => {
    if (data && data[value]) {
      return data[value];
    }
    return false;
  };
  return {
    data: [
      // Evidence
      {
        refKey: "lc_evidence",
        name: "Evidence",
        description:
          "The Evidence lifecycle brings together the most rudimentary of ideas with some efforts to gain customer insights and validations. It is best to iterate and pivot at this stage, as the risks and costs are lowest. If you nail your Evidence lifecycle and define a true Value Proposition that the market needs and wants, your chances for success increase dramatically.",
        lc_areas: [
          // Evidence: Team
          {
            refKey: "evidence_team_lc",
            name: "Team",
            description:
              "It’s never too soon to start thinking about the team you'll need. Assess your current needs and keep in mind what you’ll need in the future as you grow. Making connections and assessing qualifications are an important part of that journey.",
            fa_tasks: [
              {
                refKey: "identify_cofounders",
                name: "Identify the right co-founding team",
                new_action_items: [
                  {
                    refKey: "find_cofounders",
                    name: "Find Co-founders",
                    description:
                      "The right co-founding team is a critical foundation for any company. You need to assess the strengths and skill sets needed to accomplish your goals, and work backwards to figure out what people might be the best fit.",
                    user_answer: determineValue("find_cofounders"),
                    resources: [
                      {
                        description:
                          "LinkedIn is a great place to find people in all walks of life and with various areas of expertise.",
                        url: "https://www.linkedin.com/"
                      },
                      {
                        description:
                          "At CoFounder Lab, you can get access to over 400,000 potential cofounders, team members and advisors on the largest startup platform on the planet.",
                        url: "https://cofounderslab.com/"
                      },
                      {
                        description:
                          "Reddit has several entrepreneur and startup focused groups. The Entrepreneur community alone has almost a million members.",
                        url: "https://www.reddit.com/r/Entrepreneur/"
                      }
                    ]
                  },
                  {
                    refKey: "find_interns",
                    name: "Find Interns or Apprentices",
                    description:
                      "Working at a startup can be a great learning experience for students looking to get their feet wet.",
                    user_answer: determineValue("find_interns"),
                    resources: [
                      {
                        description:
                          "Check out the internship program by book company Chegg.",
                        url: "https://www.internships.com/"
                      },
                      {
                        description:
                          "Barefoot Students allows you to hire college students and recent grads in 195 countries. Post jobs & internships. Search student profiles.",
                        url: "https://www.barefootstudent.com/"
                      },
                      {
                        description:
                          "Pangea offers college talent on-demand. Their portal Pangea.app gives you an immediate, direct access to students ready to undertake any project.",
                        url: "https://pangea.app/"
                      },
                      {
                        description:
                          "Help apprentices kickstart their digital careers, by offering experience for a rapidly changing job market by working with your business.",
                        url: "https://acadium.com/get-an-apprentice/"
                      }
                    ]
                  },
                  {
                    refKey: "find_contractors",
                    name: "Find Contractors",
                    description:
                      "If you need more cost-effective doers, check out some of the marketplaces for on-demand hires.",
                    user_answer: determineValue("find_contractors"),
                    resources: [
                      {
                        description:
                          "Amazon Mechanical Turk (MTurk) is a crowdsourcing marketplace that makes it easier for individuals and businesses to outsource their processes and jobs to a distributed workforce who can perform these tasks virtually.",
                        url: "https://www.mturk.com/"
                      },
                      {
                        description:
                          "Fiverr offers a whole world of freelance talent at your fingertips.",
                        url: "https://www.fiverr.com/"
                      },
                      {
                        description:
                          "Upwork is the world’s work marketplace, where you can engage the largest network of trusted independent professionals to unlock the full potential of your business.",
                        url: "https://www.upwork.com/"
                      }
                    ]
                  },
                  {
                    refKey: "identify_gaps",
                    name: "Identify Gaps",
                    description:
                      "Knowing what you're missing is often as important as knowing what you have. Take stock of your team and their capabilities, and make note of any missing skill sets.",
                    user_answer: determineValue("identify_gaps"),
                    resources: [
                      {
                        description:
                          "CoBloom presents: The Ultimate Startup Hiring Guide: How to Build a Winning Team",
                        url: "https://drive.google.com/file/d/1cgRJIEN4FzhVOHvvpRMrAluJ8e0q_6Lt/view?usp=sharing"
                      },
                      {
                        description:
                          "Entrepreneur magazine offers 9 tips guaranteed to build a winning team.",
                        url: "https://www.entrepreneur.com/article/292425"
                      },
                      {
                        description:
                          "PDF guide to the steps in successful team building.",
                        url: "https://drive.google.com/file/d/1vjv-huFFWTCk2-PizF7FulIK-RnyOqx_/view?usp=sharing"
                      }
                    ]
                  },
                  {
                    refKey: "assign_resp",
                    name: "Assign responsibilities to co-founders",
                    description:
                      "If you have one or more founders, it’s imperative that you decide who will do what up front. Put it in writing.  Co-founder disagreements can destroy your business.",
                    user_answer: determineValue("assign_resp"),
                    resources: [
                      {
                        description:
                          "Make sure you are using a good set of project management tools. We've listed a few that are the best here, starting with a free account from Trello.",
                        url: "https://trello.com/signup"
                      },
                      {
                        description:
                          "Asana is another great tool that starts at the low price of free for a Basic account.",
                        url: "https://asana.com/"
                      },
                      {
                        description:
                          "Atlassian's Jira is free for up to a team of 10.",
                        url: "https://www.atlassian.com/software/jira/pricing"
                      }
                    ]
                  },
                  {
                    refKey: "find_mentors",
                    name: "Find relevant mentors",
                    description:
                      "Your company will greatly benefit from a mentor who has succeeded in your industry, and  can provide you with critical advice and guidance. Moreover, your Board of Advisors will lend a lot of credibility to any external-facing fundraising efforts.",
                    user_answer: determineValue("find_mentors"),
                    resources: [
                      {
                        description:
                          "LinkedIn is a great place to find people in all walks of life and with various areas of expertise.",
                        url: "https://www.linkedin.com/"
                      },
                      {
                        description:
                          "LunchClub offers smart introductions to relevant people, with curated 1:1 professional connections.",
                        url: "https://lunchclub.com/"
                      },
                      {
                        description:
                          "The Founder Institute offers a free legal template called the FAST Agreement that you can use to attract and legally retain advisors.",
                        url: "https://fi.co/fast"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "ensure_diversity",
                name: "Ensure diverse representation among the team",
                new_action_items: [
                  {
                    refKey: "diversify_team",
                    name: "Diversifying your team increases your success rate",
                    description:
                      "Numerous research now indicates that having a multifaceted team (skills, identity, demographics, etc) enhances the resilience and ultimate success of said team.",
                    user_answer: determineValue("diversify_team"),
                    resources: [
                      {
                        description:
                          "Diversify Tech is a talent directory and an excellent resource to help change the ratio in tech by hiring more underrepresented people.",
                        url: "https://www.diversifytech.co/hire/"
                      },
                      {
                        description:
                          "Diversity and inclusion isn’t just an issue for large tech companies. Startups need to make diverse hiring a priority, too. Read Builtin's on how to make diversity a hiring priority at the startup stage.",
                        url: "https://builtin.com/diversity-inclusion/small-business-diversity-and-inclusion-hiring-strategy"
                      },
                      {
                        description:
                          "HackerEarth's Go-Getter’s guide to diversity hiring in tech.",
                        url: "https://www.hackerearth.com/blog/talent-assessment/diversity-hiring-in-tech"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "list_supporters",
                name: "Develop a network of partners and supporters",
                new_action_items: [
                  {
                    refKey: "find_partners",
                    name: "Find Partners",
                    description:
                      "Not everyone likes networking, or cold-calling in particular. Remember that the success of your company is contingent on making connections with people you don't know yet.",
                    user_answer: determineValue("find_partners"),
                    resources: [
                      {
                        description:
                          "LinkedIn is a great place to find people in all walks of life and with various areas of expertise.",
                        url: "https://www.linkedin.com/"
                      },
                      {
                        description:
                          "HubSpot offers The Ultimate Guide to Business Development and How It Can Help Your Company Grow.",
                        url: "https://blog.hubspot.com/sales/business-development"
                      },
                      {
                        description:
                          "LunchClub offers smart introductions to relevant people, with curated 1:1 professional connections.",
                        url: "https://lunchclub.com/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "prepare_journey",
                name: "Prepare for the journey ahead of you",
                new_action_items: [
                  {
                    refKey: "understand_journey",
                    name: "Understand the Startup Journey",
                    description:
                      "It’s important to have an understanding of what you’re getting yourself into as a founder. These free resources can help you learn about the startup journey.",
                    user_answer: determineValue("understand_journey"),
                    resources: [
                      {
                        description: "How to Start a Startup by Steve Altman",
                        url: "http://startupclass.samaltman.com/"
                      },
                      {
                        description: "GoodReads' Hello, Startup Reading List",
                        url: "https://www.goodreads.com/list/show/88189.Hello_Startup_Reading_List"
                      },
                      {
                        description:
                          "Entrepreneur Magazine on the 8 Ways to Prepare for the Many Startup Obstacles Ahead",
                        url: "https://www.entrepreneur.com/article/248886"
                      }
                    ]
                  },
                  {
                    refKey: "get_buyin",
                    name: "Get buy-in and feedback from family and friends",
                    description:
                      "Spend time to make sure close family and friends ‘buy into’ your startup.  You'll get great honest feedback and you may be establishing your pipeline for a friends and family raise.",
                    user_answer: determineValue("get_buyin"),
                    resources: [
                      {
                        description:
                          "LivePlan has tips on how to pitch your business to your family, friends and community.",
                        url: "https://www.liveplan.com/blog/heres-how-and-why-to-pitch-your-business-to-friends-family-and-your-community/"
                      },
                      {
                        description:
                          "Entrepreneur Magazine on 5 Steps to Get Your Parents to Respect Your Entrepreneurial Dreams",
                        url: "https://www.entrepreneur.com/article/296619"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Evidence: Product
          {
            refKey: "evidence_product_lc",
            name: "Product",
            description:
              "Any solution to a real-world market problem begins and ends with the product. You'll spend a lot of time iterating on the right solution for your customers. You'll want to develop a system that can concurrently take that market feedback while you are defining your product in order to bring a more solid solution to the market.",
            fa_tasks: [
              {
                refKey: "define_solution",
                name: "Define your solution",
                new_action_items: [
                  {
                    refKey: "define_product",
                    name: "Define product features",
                    description:
                      "There are lots of great online resources that can help you begin your product definition and test it.",
                    user_answer: determineValue("define_product"),
                    resources: [
                      {
                        description:
                          "Use the Value Proposition template to better align your product features to the customer's key needs and pains.",
                        url: "https://www.smartsheet.com/value-proposition-positioning-templates"
                      },
                      {
                        description:
                          "Wide collection of customer interviewing templates to go out in the field and validate your product/ market fit.",
                        url: "https://drive.google.com/drive/folders/0B0-WhGDCaJbDMEFDRHFyWlRuTGc?usp=sharing"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "define_differentiation",
                name: "Define your differentiation",
                new_action_items: [
                  {
                    refKey: "define_differentiation",
                    name: "Write product descriptions",
                    description:
                      "A good product description will come in handy when you test your concept in real-life interviews with customers.",
                    user_answer: determineValue("define_differentiation"),
                    resources: [
                      {
                        description:
                          "Use the Value Proposition template to better align your product features to the customer's key needs and pains.",
                        url: "https://www.smartsheet.com/value-proposition-positioning-templates"
                      },
                      {
                        description:
                          "Wide collection of customer interviewing templates to go out in the field and validate your product/ market fit.",
                        url: "https://drive.google.com/drive/folders/0B0-WhGDCaJbDMEFDRHFyWlRuTGc?usp=sharing"
                      },
                      {
                        description:
                          "Think about how your customer will get excited about your product. You can even use retail product descriptions for inspiration",
                        url: "https://www.shopify.com/blog/8211159-9-simple-ways-to-write-product-descriptions-that-sell"
                      },
                      {
                        description:
                          '"How to Differentiate Your Startup from The Competition" – Guest Post by Martin Luenendonk.',
                        url: "https://www.finsmes.com/2017/07/how-to-differentiate-your-startup-from-the-competition-guest-post-by-martin-luenendonk.html"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "check_competitors",
                name: "See if anyone else is doing what you are planning to do",
                new_action_items: [
                  {
                    refKey: "research_competitors",
                    name: "Understand who's doing similar things in your space",
                    description:
                      "Start researching the competitive landscape. Who is offering a similar product, how are they selling it, and to whom?",
                    user_answer: determineValue("research_competitors"),
                    resources: [
                      {
                        description:
                          "SEMRush has the 5-Step Competitor Analysis Strategy That Never Fails!",
                        url: "https://www.semrush.com/blog/5-step-competitor-analysis-strategy-never-fails/"
                      },
                      {
                        description:
                          "Use the Competitive Analysis tool from SimilarWeb.",
                        url: "https://www.similarweb.com/corp/marketing/competitive-analysis/"
                      },
                      {
                        description:
                          "Find out from HubSpot what's a competitive analysis & how do you conduct one?",
                        url: "https://blog.hubspot.com/marketing/competitive-analysis-kit"
                      },
                      {
                        description:
                          'Jamie Johnson has a nice piece on "Sizing Up the Competition: How to Conduct Competitive Research"',
                        url: "https://www.uschamber.com/co/start/strategy/how-to-conduct-competitive-research"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Evidence: Market
          {
            refKey: "evidence_market_lc",
            name: "Market",
            description:
              "Within Market, you'll consider all types of relationships with customers and partners, such as sales and marketing, channel and partner strategies, market research, customer service and more.",
            fa_tasks: [
              {
                refKey: "identify_segments",
                name: "Begin to identify target segment(s)",
                new_action_items: [
                  {
                    refKey: "identify_segments",
                    name: "Identify most promising segment(s)",
                    description:
                      "You’ll likely come across several possible target segments that look promising. The reality is that you don’t have the resources to enter all markets. Do some research and analysis to determine the best possible segment to pursue.",
                    user_answer: determineValue("identify_segments"),
                    resources: [
                      {
                        description:
                          "Defining your beachhead is key. The beachhead is your initial market entry point where you will have the most success and are poised to enter another market segment.",
                        url: "https://corporatefinanceinstitute.com/resources/knowledge/strategy/beachhead-strategy/"
                      },
                      {
                        description:
                          "The MaRS Startup Toolkit offers some great guidance in identifying target customer segments to focus your marketing resources",
                        url: "https://learn.marsdd.com/article/identifying-target-customer-segments-to-focus-your-marketing-resources/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "interview_customers",
                name: "Interview prospective customers",
                new_action_items: [
                  {
                    refKey: "customer_discovery",
                    name: "Do Customer Discovery",
                    description:
                      "You will only validate your hypotheses by going out in the field and interviewing prospective customers. This is a lot easier than selling; you are only there to listen. Many prospective customers would be thrilled to vent about the problems related to their current solution.",
                    user_answer: determineValue("customer_discovery"),
                    resources: [
                      {
                        description:
                          "Define your perfect customer. You may have already created a User Persona. If not, you can start creating that persona.",
                        url: "https://slite.com/templates/persona"
                      },
                      {
                        description:
                          "Find prospective customers. If your company is B2B, then LinkedIn is an excellent place to start.",
                        url: "https://www.linkedin.com/feed/"
                      },
                      {
                        description:
                          "If your company is a B2C company, then you need to figure out where your target customers might congregate. Is it a group on Facebook or Nextdoor? Are there other forums where their common interests might bring them together?",
                        url: "https://www.facebook.com/"
                      },
                      {
                        description:
                          "When you are ready to interview them, you'll want to present your product concept, some mockups or possibly even a usable prototype for the users to evaluate. You can capture feedback in a Google Form or similar data collection tool.",
                        url: "https://www.google.com/forms/about/"
                      },
                      {
                        description:
                          "Make sure to be armed with your documented questions and hypotheses about what the customers actually want. You can again check out the Strategyzer templates to see sample trigger questions and a framework to test and validate your hypotheses you developed before interviewing.",
                        url: "https://drive.google.com/drive/u/2/folders/1ObO4PaHny0iZwjD1gNpOQL9m9PvL_biG"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "identify_pain",
                name: "Identify real customer pain points",
                new_action_items: [
                  {
                    refKey: "define_problem",
                    name: "Define Problem",
                    description:
                      "Every new market offering needs to solve a customer problem. The best way to find these problems is to go out and interview potential customers, also known as customer discovery.",
                    user_answer: determineValue("define_problem"),
                    resources: [
                      {
                        description:
                          "The Five Whys - Getting to the Root of a Problem",
                        url: "https://www.mindtools.com/pages/article/newTMC_5W.htm"
                      },
                      {
                        description:
                          "IDEO's DesignKit's Methodology on 'How Might We'",
                        url: "https://www.designkit.org/methods/3"
                      }
                    ]
                  },
                  {
                    refKey: "research_problem",
                    name: "Research big problems in the world",
                    description:
                      "A great way to brainstorm business ideas is by researching the persistent problems humanity is facing. The fact that big problems are widely discussed might mean that, in addition to saving the world, you could discover impressive market opportunities.",
                    user_answer: determineValue("research_problem"),
                    resources: [
                      {
                        description: "Y Combinator Requests for Startups",
                        url: "https://www.ycombinator.com/rfs/"
                      },
                      {
                        description: "What has Homebrew interested",
                        url: "https://quip.com/rcLXASq4IbIj"
                      },
                      {
                        description:
                          "Get to know 6000+ entrepreneurs and developers from more than 160 countries via sharing and discussing startup ideas.",
                        url: "https://www.ideaswatch.com/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "validate_solution",
                name: "Validate your solution",
                new_action_items: [
                  {
                    refKey: "define_persona",
                    name: "Define User Persona",
                    description:
                      "Close your eyes and dream of your perfect customer. What are they like? What do they do? What are they motivated by? These are just some of the questions you answer when you create a complete User Persona, which describes one target customer in detail. Once you have that defined, you can go out and think about how to get 1,000 more just like that one.",
                    user_answer: determineValue("define_persona"),
                    resources: [
                      {
                        description:
                          "Slite offers free customizable user persona templates. A user persona, also known as a customer persona, is a comprehensive description of a company's ideal customer. The free plan offers up to 100 documents.",
                        url: "https://slite.com/templates/persona"
                      },
                      {
                        description:
                          "Miro's User Personas Template allows you to create target user profiles to better understand how to sell to them.",
                        url: "https://miro.com/templates/personas/"
                      },
                      {
                        description:
                          "Smaply has a persona generator to empathize with your customers and put them at the center. Smaply makes it easy for you to take your customers' perspective and better understand their expectations. You get one persona mapping with the free account.",
                        url: "https://www.smaply.com/tools/personas"
                      }
                    ]
                  },
                  {
                    refKey: "value_prop",
                    name: "Measure Value Proposition",
                    description:
                      "Value proposition is the measure of an innovation, service, or feature intended to make a company or product attractive to customers. How attractive is your offering to customers?",
                    user_answer: determineValue("value_prop"),
                    resources: [
                      {
                        description:
                          "The Value Proposition Canvas breaks the problem of identifying the value proposition down into discrete parts. This enables a person or team to address each part, step by step. On the right, the canvas profiles the customer by asking: what jobs are they trying to accomplish, what’s hard or unpleasant (pains) about their current way of doing it, and what's desirable or delightful (gains) in an ideal solution? The value proposition is mapped out by asking: what is the product or service, and how does it create the gains the customer wants and soothe the pains they don't?",
                        url: "https://docs.google.com/document/d/1x8t-wFM5Fu2y8X6UAa_pqCRY23zXwQcAZTDvUX3VJaw/edit"
                      }
                    ]
                  },
                  {
                    refKey: "market_via",
                    name: "Determine market viability",
                    description:
                      "You need to determine if your offering will be viable in the marketplace. Are you offering a product or a service that you can profitably deliver?  Run the numbers: is there enough customer demand so that you can cover costs and make a sufficient profit?",
                    user_answer: determineValue("market_via"),
                    resources: [
                      {
                        description:
                          "Pitching Sequoia? Here's the big question you'll need to answer",
                        url: "https://pando.com/2013/02/20/sequoias-why-now/"
                      },
                      {
                        description:
                          "The Four Steps to the Epiphany: Successful Strategies for Products that Win",
                        url: "https://www.amazon.com/Four-Steps-Epiphany-Successful-Strategies-dp-1119690358/dp/1119690358/ref=dp_ob_title_bk"
                      }
                    ]
                  },
                  {
                    refKey: "pivot",
                    name: "Pivot (if needed)",
                    description:
                      "Pivoting means you are changing your business model, based on the things you learned during the evidence stage. The Evidence stage is absolutely the very best time to pivot, assuming you have not yet found a viable model. Pivoting will become much more expensive the longer you wait.",
                    user_answer: determineValue("pivot"),
                    resources: [
                      {
                        description:
                          "The important thing is to listen to your prospective customers and ask yourself your key question - did my solution sufficiently meet their needs? If not, why is that? Are you targeting the wrong segment, not solving the right problem, or is the value proposition with an entirely different segment? Perhaps even B2B instead of B2C? The Customer Discovery framework allows you to pivot and start over. Trust us, it is a lot cheaper to pivot now than a year from now.",
                        url: "https://drive.google.com/drive/u/2/folders/11AN-Rx3D52qePeLY13MIe9mni_CJ7bkq"
                      },
                      {
                        description:
                          'The Harvard Business Review offers "The Strategic Pivot: Rules for Entrepreneurs and Other Innovators"',
                        url: "https://hbr.org/2011/02/how-and-when-to-pivot-rules-fo"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "establish_presence",
                name: "Establish a market presence",
                new_action_items: [
                  {
                    refKey: "register_domain",
                    name: "Register a domain name",
                    description:
                      "Get a matching domain to your business name.  An AOL email address or a website with free hosting and a name like mysite.wordpress.com makes it seem like either (a) you are not running a real business or (b) you don't plan to be around long.",
                    user_answer: determineValue("register_domain"),
                    resources: [
                      {
                        description:
                          "Google can help you find your place online. Choose from more than 300 domain endings. Get 24/7 Google support from real humans.",
                        url: "https://domains.google/"
                      },
                      {
                        description:
                          "GoDaddy is the world's largest services platform for entrepreneurs around the globe. They're on a mission to empower our worldwide community of 20+ million customers — and entrepreneurs everywhere — by giving them all the help and tools they need to grow online.",
                        url: "https://www.godaddy.com/"
                      },
                      {
                        description:
                          "Ionos offers a free domain with a web hosting package, including domain and email account, privacy protection and Wildcard SSL (https:).",
                        url: "https://www.ionos.com/domains/free-domain-name"
                      }
                    ]
                  },
                  {
                    refKey: "social_media",
                    name: "Register social media profiles",
                    description:
                      "Getting set up on the major social media channels (Facebook, LinkedIn, and Twitter, to start) will make marketing on them later easier. Also, it’s important to reserve your brand as a profile name. Try Knowem.com to reserve the names.",
                    user_answer: determineValue("social_media"),
                    resources: [
                      {
                        description:
                          "Easily manage all your social media and get results with Hootsuite. With the free starter account, you can manage three different social media accounts, including your choice among LinkedIn, Facebook, Twitter, Instagram and others.",
                        url: "https://www.hootsuite.com/"
                      },
                      {
                        description:
                          "Create a LinkedIn business page. Drive brand awareness & quality engagement with professional audiences by creating a LinkedIn Company Page. Explore how you can join conversations, grow & engage your audience. Learn more.",
                        url: "https://business.linkedin.com/marketing-solutions/linkedin-pages"
                      },
                      {
                        description:
                          "Use the Twitter microblogging and social networking platform to reach your audience.",
                        url: "https://twitter.com/"
                      },
                      {
                        description:
                          "Facebook for Business gives you the latest news, advertising tips, best practices and case studies for using Facebook to meet your business goals.",
                        url: "https://www.facebook.com/business"
                      }
                    ]
                  },
                  {
                    refKey: "mailing",
                    name: "Have a mailing address",
                    description:
                      "You will likely need a mailing address for your business and a PO Box will likely not cut it, as incorporation services typically prohibit that option. If you stay virtual, a good option might be a virtual office or colocation service.",
                    user_answer: determineValue("mailing"),
                    resources: [
                      {
                        description:
                          "Get an impressive and professional address for your business.",
                        url: "https://www.intelligentoffice.com/virtual-address"
                      },
                      {
                        description:
                          "If you are ready for the real deal and get local office space, your best starting point might be something a bit more cost-effective from a co-working facility. WeWork is the global leader. You can check to see if they have something close to you.",
                        url: "https://www.wework.com/"
                      },
                      {
                        description:
                          "If you prefer to go more off-the-beaten-path with co-location, you can check out the League of Extraordinary Coworking Spaces to see if they offer something close to you.",
                        url: "https://lexc.co/"
                      }
                    ]
                  },
                  {
                    refKey: "business_cards",
                    name: "Get business cards",
                    description:
                      "As a startup founder, you’ll be doing a lot of networking, so order plenty of business cards. They are inexpensive enough that you can reorder them later if things change. If you are so beyond the business card, make sure that you have a fast and easy way of sharing your contact information with your phone.",
                    user_answer: determineValue("business_cards"),
                    resources: [
                      {
                        description:
                          "VistaPrint allows you to create business cards as unique as you.",
                        url: "https://www.vistaprint.com/business-cards"
                      },
                      {
                        description:
                          "Moo allows you to order a sample pack so you can touch and feel the premium range of papers and finishes for yourself. It’s free!",
                        url: "https://www.moo.com/"
                      },
                      {
                        description:
                          "If you are beyond paper cards, consider creating a QR code that sends users to either your contact page on your website or your LinkedIn profile. You can either have that image easily available on your phone or stick the QR code to your phone case.",
                        url: "https://www.linkedin.com/feed/"
                      }
                    ]
                  },
                  {
                    refKey: "setup_website",
                    name: "Set up a website",
                    description:
                      "Get your website up and running as soon as possible. Today, it’s necessary for credibility.  Even if your product is not yet built, you can start with company information.",
                    user_answer: determineValue("setup_website"),
                    resources: [
                      {
                        description:
                          "Wix helps you create a website you’re proud of. Wix offers a platform that gives you the freedom to create, design, manage and develop your web presence exactly the way you want.",
                        url: "https://www.wix.com/"
                      },
                      {
                        description:
                          "Squarespace is the all-in-one solution for anyone looking to create a beautiful website. Domains, eCommerce, hosting, galleries, analytics, and 24/7 support.",
                        url: "https://www.squarespace.com/"
                      },
                      {
                        description:
                          "Google Cloud web hosting allows you to host static and dynamic websites in the cloud with Click to Deploy or customized solutions.",
                        url: "https://cloud.google.com/solutions/web-hosting"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "competitor_eval",
                name: "Detailed Competitor Evaluation",
                new_action_items: [
                  {
                    refKey: "detailed_comp_research",
                    name: "Detailed competitor research",
                    description:
                      "By now you know what other companies might be competing for your customers, but your research will have to go to the next level to understand how much of a challenge you might face during the sales process.",
                    user_answer: determineValue("detailed_comp_research"),
                    resources: [
                      {
                        description:
                          "Research competitors' patent and trademark filings",
                        url: "https://patents.google.com/"
                      },
                      {
                        description:
                          "Research any public filings if the company is public",
                        url: "https://www.sec.gov/edgar/searchedgar/companysearch.html"
                      },
                      {
                        description:
                          "Subscribe to the Google News feed for all of your competitors",
                        url: "https://news.google.com/"
                      },
                      {
                        description:
                          "Interview their customers. It may be tricky to find them but if you can have a conversation with current customers, then you can quickly learn about their strengths and weaknesses, thus allowing you to craft better marketing materials to highlight your advantages",
                        url: "https://drive.google.com/drive/u/2/folders/1ObO4PaHny0iZwjD1gNpOQL9m9PvL_biG"
                      },
                      {
                        description:
                          "This template from 280 Group allows you to compare features between your product and those of your competitors.",
                        url: "https://docs.google.com/spreadsheets/d/132QiLbpwQXKasVQXIs7TDKAimS94Fs38BZdPzS6RkBk/edit#gid=1495147798"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Evidence: Money
          {
            refKey: "evidence_money_lc",
            name: "Money",
            description:
              "Having proper financial discipline is one of the keys to survival during these early startup periods. We'll be reviewing everything from fundraising to financial and accounting systems and more.",
            fa_tasks: [
              {
                refKey: "identify_resources",
                name: "Identify resources needed to bring your solution to market",
                new_action_items: [
                  {
                    refKey: "identify_resources",
                    name: "Identify resources needed to bring your solution to market",
                    description:
                      "You need to have a decent understanding of what resources are needed to bring your product or service to market, including money, materials, people, partnerships, regulatory and anything else that may impact costs or impede progress.",
                    user_answer: determineValue("identify_resources"),
                    resources: [
                      {
                        description:
                          "The Business Model Canvas is a tremendous resource to organize your planning around resources needed.",
                        url: "https://drive.google.com/drive/u/2/folders/1ZG6cWzdkseB8RJ68rJsZJvfURagRBCgB"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "develop_model",
                name: "Develop a basic business and revenue model",
                new_action_items: [
                  {
                    refKey: "develop_model",
                    name: "Develop a basic business and revenue model",
                    description:
                      "At this point you need to translate your research into resources needed, then into your first financial model. This may sound intimidating, but it doesn’t need to be. Start by entering numbers into a Google Spreadsheet as you learn them. The sophistication and complexity can be added over time.",
                    user_answer: determineValue("develop_model"),
                    resources: [
                      {
                        description:
                          "You can start with a super simple financial model, just to put some numbers down and organize your thoughts. Make sure to think about a full year's worth of planning. What costs repeat themselves and how often.",
                        url: "https://docs.google.com/spreadsheets/d/1pF_4KqiZ4GQ1hKtJmf9MxGkP_Uont8_pxYv07ToRXL8/edit#gid=1695479590"
                      },
                      {
                        description:
                          "Freshbooks has a guide on how to 'Create a Small Business Budget in 5 Simple Steps'",
                        url: "https://www.freshbooks.com/blog/the-5-step-plan-to-creating-a-balanced-business-budget"
                      }
                    ]
                  },
                  {
                    refKey: "define_model",
                    name: "Define a business plan and/or Business Model Canvas",
                    description:
                      "Creating a business plan with financial projections forces you to think through details. Keep your plan a living breathing thing that you revisit and adapt regularly. Steve Blank's Business Model Canvas is a very precise way of documenting those key considerations.",
                    user_answer: determineValue("define_model"),
                    resources: [
                      {
                        description:
                          "The business model canvas is a great tool to help you understand a business model in a straightforward, structured way. Using this canvas will lead to insights about the customers you serve, what value propositions are offered through what channels, and how your company makes money.",
                        url: "https://canvanizer.com/new/business-model-canvas"
                      },
                      {
                        description:
                          "Free download of the Business Model Canvas in PDF form. Great for creating poster sized documents to be used with Post-It notes",
                        url: "https://www.businessmodelsinc.com/about-bmi/tools/business-model-canvas/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "defining_metrics",
                name: "Define your key Metrics that Matter, along with goal KPIs",
                new_action_items: [
                  {
                    refKey: "define_metrics",
                    name: "Define your key Metrics that Matter, along with goal KPIs",
                    description:
                      "You cannot know if you succeed or fail if you have not defined your own company's metrics that matter, as well as what those numbers should be.",
                    user_answer: determineValue("define_metrics"),
                    resources: [
                      {
                        description:
                          "Klipfolio has a nice set of definitions for many of the most common metrics.",
                        url: "https://www.klipfolio.com/resources/articles/what-are-business-metrics"
                      },
                      {
                        description:
                          "Twilio's Segment has a guide to choosing the metrics that matter.",
                        url: "https://segment.com/academy/intro/choosing-metrics-that-matter/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "develop_financial_model",
                name: "Develop a basic financial model",
                new_action_items: [
                  {
                    refKey: "financial_model",
                    name: "Figure out the money",
                    description:
                      "Startups typically take a lot longer to get off the ground than one would expect or hope for. Know where your living expenses for the first year will come from (savings, a job, spouse’s income, etc.).  If you need financing for the business start investigating as soon as possible.",
                    user_answer: determineValue("financial_model"),
                    resources: []
                  }
                ]
              }
            ]
          }
        ]
      },
      // Acceptance
      {
        refKey: "lc_acceptance",
        name: "Acceptance",
        description:
          "The Acceptance lifecycle begins once you have achieved enough market validation to reach a conclusion that segments within your target markets welcome your solution, thus defining a possible path forward. Once armed with that evidence and validation, you can refine your Formula, the recipe for success in the marketplace.",
        lc_areas: [
          // Acceptance: Team
          {
            refKey: "acceptance_team_lc",
            name: "Team",
            description:
              "It’s never too soon to start thinking about the team you'll need. Assess your current needs and keep in mind what you’ll need in the future as you grow. Making connections and assessing qualifications are an important part of that journey.",
            fa_tasks: [
              {
                refKey: "create_company",
                name: "Create your company",
                new_action_items: [
                  {
                    refKey: "choose_name",
                    name: "Choose a business name",
                    description:
                      "You want a name that will stick in your target audience’s heads. And it shouldn’t already be taken by another company. Do Google searches and use a corporate name search tool to see if the name you have in mind is unique. Check at the state and Federal level.",
                    user_answer: determineValue("choose_name"),
                    resources: [
                      {
                        description:
                          "Make sure to do a trademark search, so you can protect your brand over time. You can do a free trademark search at the government website.",
                        url: "https://www.uspto.gov/"
                      },
                      {
                        description:
                          "Lots of resources from Google's Brand Resource Center.",
                        url: "https://about.google/brand-resource-center/"
                      },
                      {
                        description:
                          "Legal-Sherpa helps you protect your name, logo or slogan.",
                        url: "https://www.legal-sherpa.com/"
                      },
                      {
                        description:
                          "Entrepreneur Magazine features 5 must-use tools for brainstorming company names.",
                        url: "https://www.entrepreneur.com/article/238601"
                      },
                      {
                        description:
                          "Fuze Branding offers a name brainstorming worksheet.",
                        url: "https://www.fuzebranding.com/brainstorm-worksheet-tips-for-naming-your-business/"
                      }
                    ]
                  },
                  {
                    refKey: "incorporate",
                    name: "Incorporate / figure out legal structure",
                    description:
                      "Incorporating your startup can protect your personal assets. Talk over structure (corporation, LLC, sole proprietorship) with your attorney and accountant.",
                    user_answer: determineValue("incorporate"),
                    resources: [
                      {
                        description:
                          "8 Situations When You Need to Incorporate Your Startup",
                        url: "https://mashable.com/2014/02/17/incorporate-your-startup/"
                      },
                      {
                        description:
                          "Stripe-Atlas helps you turn your idea into a startup. Stripe Atlas is a powerful, safe, and easy-to-use platform for forming a company. By removing lengthy paperwork, legal complexity, and numerous fees, Stripe Atlas helps you launch your startup from anywhere in the world.",
                        url: "https://stripe.com/atlas"
                      },
                      {
                        description:
                          "StartPack helps you launch your US LLC business from anywhere in the world. They handle the complex paperwork. You get a US Bank Account and access to US Payments.",
                        url: "https://www.startpack.io/"
                      },
                      {
                        description:
                          "Gust Launch makes incorporation easy and sets up founders for investment and growth. With powerful tools. Honest advice. And true compassion.",
                        url: "https://gust.com/launch"
                      }
                    ]
                  },
                  {
                    refKey: "apply_ein",
                    name: "Apply for an EIN",
                    description:
                      "An Employer Identification Number (EIN) helps you separate yourself from your business. You'll need it if you plan to incorporate your business or open a business bank account.  Plus, with it you can avoid giving out your social security number (an opening  to identity theft). EIN numbers are free; apply online.",
                    user_answer: determineValue("apply_ein"),
                    resources: [
                      {
                        description:
                          "An Employer Identification Number (EIN) is also known as a Federal Tax Identification Number, and is used to identify a business entity. Generally, businesses need an EIN.",
                        url: "https://www.irs.gov/businesses/small-businesses-self-employed/employer-id-numbers"
                      }
                    ]
                  },
                  {
                    refKey: "apply_license",
                    name: "Investigate and apply for business licenses",
                    description:
                      "You may need one or more business licenses for your startup depending on your industry and location. Most licenses are at the state or local level. Here in the United States, the SBA has a helpful business license and permits tool.",
                    user_answer: determineValue("apply_license"),
                    resources: [
                      {
                        description:
                          "Resources to apply for licenses and permits. Most small businesses need a combination of licenses and permits from both federal and state agencies. The requirements — and fees — vary based on your business activities, location, and government rules.",
                        url: "https://www.sba.gov/business-guide/launch-your-business/apply-licenses-permits"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "recruit_advisors",
                name: "Recruit outside advisors & service providers",
                new_action_items: [
                  {
                    refKey: "find_legal",
                    name: "Get outside legal counsel",
                    description:
                      "This is a tricky one because it could be very expensive if you are not careful. However, the cost of not doing it right far outweighs that cost. We suggest you look for counsel options that minimize or delay that cost. We've laid out some of those options here.",
                    user_answer: determineValue("find_legal"),
                    resources: [
                      {
                        description:
                          "TheMuse: What Startups Should Know About Hiring a Lawyer",
                        url: "https://www.themuse.com/advice/what-startups-should-know-about-hiring-a-lawyer"
                      },
                      {
                        description:
                          "BizCounsel can help you find ongoing legal support, with a free consultation. Can include advice, reviews, drafting, contracts, employee issues, litigation consulting.",
                        url: "https://bizcounsel.com/business-attorney"
                      }
                    ]
                  },
                  {
                    refKey: "advisory_resources",
                    name: "Find free advisory resources",
                    description:
                      "Your local SBA office, SCORE, and other small business resources can provide you with free advice, access to business templates, and other tools.",
                    user_answer: determineValue("advisory_resources"),
                    resources: [
                      {
                        description:
                          "SCORE’s mission is to foster vibrant small business communities through mentoring and education. With the nation’s largest network of volunteer, expert business mentors, SCORE has helped more than 11 million entrepreneurs since 1964.",
                        url: "https://www.score.org/"
                      },
                      {
                        description: "The Small Business Development Center",
                        url: "https://www.sba.gov/about-sba/sba-locations/headquarters-offices/office-small-business-development-centers"
                      }
                    ]
                  },
                  {
                    refKey: "define_advisory",
                    name: "Determine expertise and skills needed from advisors",
                    description:
                      "As your company grows, so should your board of advisors. Think about what skills/ expertise/ connections you would like to develop within the company. You can also consider the advisors as a path to develop your own leadership skills that would be needed in a growing company.",
                    user_answer: determineValue("define_advisory"),
                    resources: [
                      {
                        description:
                          "You can do a gap analysis to determine what the company currently lacks. Advisors with those strengths can inform you how to develop that expertise and round out the team's talent.",
                        url: "https://docs.google.com/spreadsheets/d/163O5K07odiiimXHmFQP9OTIGMdF9PLpEf69xpkDDzIw/edit#gid=2008034871"
                      },
                      {
                        description:
                          "Any advisor that you bring on board will expect to be compensated. During those early startup phases, stock options are ok. You can use the FAST agreement (stands for Founder Advisor Standard Template) to formalize an agreement. They even have an industry standard matrix for compensation, depending on the level of engagement and stage of the company. Consult an attorney if you have any questions about the agreement.",
                        url: "https://fi.co/fast"
                      },
                      {
                        description:
                          'First Round Review offers "7 Tactics to Get the Most Out of Your Startup Advisors"',
                        url: "https://review.firstround.com/Get-the-Most-Out-of-Your-Startups-Advisors-with-These-7-Tactics"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "industry_connections",
                name: "Develop relevant industry connections",
                new_action_items: [
                  {
                    refKey: "develop_connections",
                    name: "Develop relevant industry connections",
                    description:
                      "Now the time for networking begins. Your networking regimen could include everything from social media to networking events to conferences and much more.",
                    user_answer: determineValue("develop_connections"),
                    resources: [
                      {
                        description:
                          "LinkedIn certainly leads the way in terms of business networking for most industries.",
                        url: "https://www.linkedin.com/"
                      },
                      {
                        description:
                          "It might be worthwhile joining some online groups that are relevant to your industry. Beyond LinkedIn, there are vibrant groups found at Facebook, Reddit and many, many other online sites only a Google search away.",
                        url: "https://www.reddit.com/"
                      },
                      {
                        description:
                          "If there are any trade associations that are relevant to your industry, it is worth looking them up and possibly joining.",
                        url: "https://directoryofassociations.com/"
                      },
                      {
                        description:
                          "LunchClub office online video networking allows you to never leave your office to connect with relevant industry insiders.",
                        url: "https://lunchclub.com/"
                      },
                      {
                        description:
                          "EventsEye is a very handy resource to start researching relevant tradeshows and conferences in your industry.",
                        url: "https://www.eventseye.com/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "expand_team",
                name: "Expand your team & address your weaknesses",
                new_action_items: [
                  {
                    refKey: "hire_employee",
                    name: "Hire your first employee",
                    description:
                      "Depending on the type of business you have, you may need staff from day one (retail) or you may be able to outsource to  freelancers, interns, and third-party vendors for a while (service and tech businesses). Just remember, trying to do everything yourself takes you away from growing the business.",
                    user_answer: determineValue("hire_employee"),
                    resources: [
                      {
                        description:
                          "Finding the best fit for the job shouldn’t be a full-time job. Indeed’s simple and powerful tools let you source, screen, and hire faster.",
                        url: "https://www.indeed.com/hire"
                      },
                      {
                        description:
                          "Make sure to create your company profile on Glassdoor. Your next hire is researching your company on Glassdoor. Take control of the conversation and show ideal candidates why they want to work with you.",
                        url: "https://www.glassdoor.com/employers/sign-up/"
                      },
                      {
                        description:
                          "64% of job seekers get hired through a referral. Use LinkedIn Jobs to boost your chances of getting hired through people you know.",
                        url: "https://www.linkedin.com/jobs/"
                      },
                      {
                        description:
                          "Have your job postings found by millions of job seekers who search on Google every day.",
                        url: "https://jobs.google.com/"
                      }
                    ]
                  },
                  {
                    refKey: "team_building",
                    name: "Identify team building best practices",
                    description:
                      "Build the Foundation of a strong team, by leveraging research and industry best practices.",
                    user_answer: determineValue("hire_employee"),
                    resources: [
                      {
                        description:
                          "re:Work is a collection of practices, research, and ideas from Google and others to help you put people first.",
                        url: "https://rework.withgoogle.com/"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Acceptance: Product
          {
            refKey: "acceptance_product_lc",
            name: "Product",
            description:
              "Any solution to a real-world market problem begins and ends with the product. You'll spend a lot of time iterating on the right solution for your customers. You'll want to develop a system that can concurrently take that market feedback while you are defining your product in order to bring a more solid solution to the market.",
            fa_tasks: [
              {
                refKey: "define_core",
                name: "Define the core features for your product",
                new_action_items: [
                  {
                    refKey: "define_features",
                    name: "Define the core features for your product",
                    description:
                      "By the time you have gone through Acceptance you'll likely be inundated with a variety of feature requests. The problem is that you'll likely have more requests than you can possibly handle. You'll have to figure out what takes priority and how your limited development resources can create the most value for your target customers.",
                    user_answer: determineValue("define_features"),
                    resources: [
                      {
                        description:
                          "ProductBoard offers a features prioritization platform that you can try for free for 15 days.",
                        url: "https://www.productboard.com/feature-prioritization-software/"
                      },
                      {
                        description:
                          "Try a free trial of AirFocus, which offers tools to help you prioritize what to do next, create clear roadmaps and collaborate on product strategy.",
                        url: "https://airfocus.com/roadmap-and-prioritization-templates/"
                      },
                      {
                        description:
                          "You can also use a good old fashioned spreadsheet for free, courtesy of the 280 Group.",
                        url: "https://docs.google.com/spreadsheets/d/1YghFdn2aPCu6e3zhzscUScxuVmVwrTdxi0bCtCVW6bs/edit#gid=1006754295"
                      },
                      {
                        description:
                          "And even more great product related resources from 280 Group.",
                        url: "https://drive.google.com/drive/u/2/folders/1F6O0HjkA0VNe9qCoaHi7RpLJ13EH8PyF"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "prototype",
                name: "Begin your prototype",
                new_action_items: [
                  {
                    refKey: "begin_prototype",
                    name: "Begin your prototype",
                    description:
                      "You will need something to share your product vision to investors and potential customers/ partners. In some cases you may need a functioning application, a clickable prototype, wireframes or mockups. in any case, it is better to be fast than perfect here, as this iteration might end up getting replaced by the real product.",
                    user_answer: determineValue("begin_prototype"),
                    resources: [
                      {
                        description:
                          "If you are building a functioning web application, you may consider fast tracking the effort with existing code to convey a concept. It may take the form of a pre-built application available on GitHub or Sourceforge, or even existing code and plugins from a content management system, like Wordpress.",
                        url: "https://github.com/marketplace"
                      },
                      {
                        description:
                          "Sourceforge is the complete open-source and business software platform.",
                        url: "https://sourceforge.net/"
                      },
                      {
                        description:
                          "WordPress is open source software you can use to create a beautiful website, blog, or app.",
                        url: "https://wordpress.org/"
                      },
                      {
                        description:
                          "If you are doing a design-based mockup for prototyping and testing, you can use a tool like Figma. You can start for free with two editors and three projects.",
                        url: "https://www.figma.com/"
                      },
                      {
                        description:
                          "Adobe XD offers a free starter plan to help design your mockups. Share your story with designs that look and feel like the real thing. Wireframe, animate, prototype, collaborate, and more.",
                        url: "https://www.adobe.com/products/xd.html"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "create_advantages",
                name: "Create competitive advantages",
                new_action_items: [
                  {
                    refKey: "file_patents",
                    name: "File for trademarks and patents",
                    description:
                      "It’s important to consult with an attorney about patents early on. Getting advice early is key, then you might be able to defer filing for a while, depending on the nature of your business.",
                    user_answer: determineValue("file_patents"),
                    resources: [
                      {
                        description:
                          "KissPatent offers a straightforward idea protection process tailored to growing businesses, fully supported by our team of experts.",
                        url: "https://kisspatent.com/"
                      },
                      {
                        description:
                          "Seventh makes intellectual property secure and simple. Seventh helps venture-backed companies find the business value in their intellectual property.",
                        url: "https://seventh.ai/"
                      },
                      {
                        description:
                          "The US Patent and Trademark office offers free searches, so you can protect your IP and brand over time.",
                        url: "https://www.uspto.gov/"
                      },
                      {
                        description:
                          "Going global? Google allows you to search and read the full text of patents from around the world.",
                        url: "https://patents.google.com/"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Acceptance: Market
          {
            refKey: "acceptance_market_lc",
            name: "Market",
            description:
              "Within Market, you'll consider all types of relationships with customers and partners, such as sales and marketing, channel and partner strategies, market research, customer service and more.",
            fa_tasks: [
              {
                refKey: "survey",
                name: "Survey your potential customers",
                new_action_items: [
                  {
                    refKey: "survey_customers",
                    name: "Survey your potential customers",
                    description:
                      "You will have a constant cycle of defining, validating and developing. Constant dialogue with your target customers is important, as well as having a steady pipeline of metrics and KPI reporting.",
                    user_answer: determineValue("survey_customers"),
                    resources: [
                      {
                        description:
                          "The low budget founder can simply start with Google Forms, as it offers a quick and easy way to create surveys and collect data. Better yet, Google Sheets integration makes it easy to export and analyze the data later",
                        url: "https://www.google.com/sheets/about/"
                      },
                      {
                        description:
                          "TypeForm allows you to create fun forms and surveys that people enjoy answering.",
                        url: "https://www.typeform.com/"
                      },
                      {
                        description:
                          "SurveyMonkey is an industry leader that offers much more than the survey, includes prospect lists and workflow automation.",
                        url: "https://www.surveymonkey.com/"
                      },
                      {
                        description:
                          "Check out the HubSpot Market Research Kit",
                        url: "https://drive.google.com/drive/u/1/folders/1OQ6tN9OQxBmx3RiImu_OEcLID6xC3XG2"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "person",
                name: "Create a customer persona",
                new_action_items: [
                  {
                    refKey: "create_persona",
                    name: "Create a customer persona",
                    description:
                      "It’s important to know who your customers are, their lifestyles, wants and needs. Personas can help consolidate this information for you and your team.",
                    user_answer: determineValue("create_persona"),
                    resources: [
                      {
                        description:
                          "Develop a free User Persona at Slite to begin to define your target customer.",
                        url: "https://slite.com/"
                      },
                      {
                        description:
                          "Check out 20 must-see user persona templates",
                        url: "https://www.justinmind.com/blog/user-persona-templates/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "model",
                name: "Model out your Product/ Market fit",
                new_action_items: [
                  {
                    refKey: "model_fit",
                    name: "Model out your Product/ Market fit",
                    description:
                      "This is a crucial step because properly modeling out the product market fit de-risks your startup significantly and adds a lot of credibility when talking to customers, partners and investors.",
                    user_answer: determineValue("model_fit"),
                    resources: [
                      {
                        description:
                          "280 Group offers excellent product/ market fit canvases",
                        url: "https://drive.google.com/drive/u/1/folders/1dWyiP9aFqxTErZ-sd6VgxyQBFYbkHkmC"
                      },
                      {
                        description:
                          "280 Groups Lean Product Management eBook helps you achieve optimal product/ market fit in record time and with fewer resources.",
                        url: "https://drive.google.com/file/d/1HCFPtPpKuhna0qlQIw8QbL7DPhpgHLD8/view?usp=sharing"
                      },
                      {
                        description:
                          "280 Group has an excellent eBook on the optimal product process.",
                        url: "https://drive.google.com/file/d/1zlK9zOXj2b2zflr0Z5xLbiLwkItVwFOy/view?usp=sharing"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Acceptance: Money
          {
            refKey: "acceptance_money_lc",
            name: "Money",
            description:
              "Having proper financial discipline is one of the keys to survival during these early startup periods. We'll be reviewing everything from fundraising to financial and accounting systems and more.",
            fa_tasks: [
              {
                refKey: "setup_finance",
                name: "Set up your financial systems",
                new_action_items: [
                  {
                    refKey: "define_budgets",
                    name: "Define short-term and long-term budgets",
                    description:
                      "As you approach market acceptance, things should start feeling a little more real. If you’re having conversations with investors and advisors, you may get asked how much money you need and how long it’ll last. It’s a great idea to be armed with answers. Begin by evaluating sample spreadsheet budgets. They’ll show what kinds of costs to expect, as well as how to present your budget in a fashion consistent with GAAP (Generally Accepted Accounting Principles).",
                    user_answer: determineValue("define_budgets"),
                    resources: [
                      {
                        description:
                          "Here’s a standard Internet business financial model.",
                        url: "https://docs.google.com/spreadsheets/d/1oD1W6rE6Cq8tiM5ikXcnD-QFQw9cy2pLVoLTOZ4-R1g/edit?usp=sharing"
                      },
                      {
                        description:
                          "If mobile is your game, you can check out this financial model.",
                        url: "https://docs.google.com/spreadsheets/d/1OFuv1X3ZwW9L4e-8qmwy2rJosWfSXN0EYgk0QX5jago/edit?usp=sharing"
                      },
                      {
                        description:
                          "If you have a SaaS business, you can start with this financial model.",
                        url: "https://docs.google.com/spreadsheets/d/1_wf92LZ-8P095WT_EAe7pxg_X-vRmXk5lQOlWZ5EifY/edit?usp=sharing"
                      },
                      {
                        description:
                          "If none of these financial models suit your fancy, you can check out the Corporate Finance Institute for even more financial models.",
                        url: "https://corporatefinanceinstitute.com/resources/templates/excel-modeling/"
                      }
                    ]
                  },
                  {
                    refKey: "develop_pricing",
                    name: "Begin developing your business & pricing models",
                    description:
                      "How you price your products is key to maximizing profits. You'll be considering things like free trials, freemium upgrades, one-time vs. recurring charges, as well as monthly vs. annual charges. If you don't price correctly, you may end up leaving money on the table.",
                    user_answer: determineValue("develop_pricing"),
                    resources: [
                      {
                        description:
                          "HubSpot offers an excellent Sales Pricing Strategy Calculator.",
                        url: "https://docs.google.com/spreadsheets/d/1sUq3Kyuc44IjbGYisSf3GYp5NahsviInp62K65D7p7g/edit#gid=1726150873"
                      }
                    ]
                  },
                  {
                    refKey: "accounting",
                    name: "Set up your accounting system",
                    description:
                      "Once you have your bank account set up, choose an accounting program. Start as you intend to go. Few things will doom your business faster than books that are a mess.",
                    user_answer: determineValue("accounting"),
                    resources: [
                      {
                        description:
                          "Quickbooks allows you to run your whole business, from inventory and invoices, to projects and people, QuickBooks organizes it all in one place.",
                        url: "https://quickbooks.intuit.com/"
                      },
                      {
                        description:
                          "Xero is all-in-one accounting software, with everything you need to run your business.",
                        url: "https://www.xero.com/us/"
                      }
                    ]
                  },
                  {
                    refKey: "insurance",
                    name: "Get insurance coverage",
                    description:
                      "Depending on the type of business you’re starting, you may need insurance of one kind or another, like liability, workers’ comp, or health insurance, especially if you hire full-time staff.",
                    user_answer: determineValue("insurance"),
                    resources: [
                      {
                        description:
                          "InsuranceQuotes allows you to compare business insurance plans and (save up to 75%). They'll help you save time and money.",
                        url: "https://quote.insurancequotes.com/"
                      },
                      {
                        description:
                          "Bizinsure is business insurance simplified. You can compare quotes from leading insurers to buy online and receive speedy coverage confirmation.",
                        url: "https://www.bizinsure.com/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "planning",
                name: "Do some financial planning",
                new_action_items: [
                  {
                    refKey: "open_bank",
                    name: "Open a business bank account",
                    description:
                      "Once you have your corporate paperwork done, along with the EID (employer identification number), then you should be able to open a business bank account. We've listed some of the most friendly banking options here.",
                    user_answer: determineValue("open_bank"),
                    resources: [
                      {
                        description:
                          "Silicon Valley Bank (SVB) specializes in tech startup banking; in fact, 50% of venture-backed tech and life science companies in the US bank with SVB. This is ideal for tech companies since SVB has a team that specializes in tech banking and financing.",
                        url: "https://www.svb.com/startup-banking"
                      },
                      {
                        description:
                          "Mercury is a tech-focused bank that was built for startups. While they specialize in working with tech companies, all businesses can access a Mercury banking account.",
                        url: "https://mercury.com/"
                      },
                      {
                        description:
                          "Brex offers a “cash management account.” A cash management account (CMA) offers all of the features of a business bank account with fewer fees.",
                        url: "https://www.brex.com/"
                      },
                      {
                        description:
                          "With its Banking for Innovators banking services, First Republic is a popular banking option for startups. Its services include access to the basic business banking needs, plus startup-specific offerings such as complimentary 409a valuation, robust online and mobile banking tools, and access to strategic advice from a dedicated banking partner.",
                        url: "https://www.firstrepublic.com/innovators/solutions"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "explore",
                name: "Explore possible funding sources",
                new_action_items: [
                  {
                    refKey: "explore_funding",
                    name: "Explore possible funding sources",
                    description:
                      "Raising money is never easy, but it helps when you are organized and know what resources are out there for meeting investors.",
                    user_answer: determineValue("explore_funding"),
                    resources: [
                      {
                        description:
                          "LinkedIn is an excellent place to begin. Remember to do homework on your targets to make sure there is a synergistic fit. And don't start by asking for money on the initial outreach. You would not ask for a wedding band on the first date, would you? Rather, start by emphasizing something about their backgrounds and ask if you can talk, just for advice.",
                        url: "https://www.linkedin.com/feed/"
                      },
                      {
                        description:
                          "Gust is one of the leading portals for bringing together startups and founders. Create an account here, and start looking for investors.",
                        url: "https://gust.com/"
                      },
                      {
                        description:
                          "F6S allows you to connect with funding from accelerators, funds & investors, as well as getting help with free stuff, talent search & exposure.",
                        url: "https://www.f6s.com/"
                      }
                    ]
                  },
                  {
                    refKey: "refine_pitch",
                    name: "Refine your pitch",
                    description:
                      "You need a good elevator pitch for many reasons: potential investors, customers, prospective new hires, bankers.  If you can’t persuasively and clearly pitch your business, how can you expect key stakeholders to buy in?",
                    user_answer: determineValue("refine_pitch"),
                    resources: [
                      {
                        description:
                          "Silicon Valley in Your Pocket is a virtual accelerator that will help you become investor-ready by optimizing your pitch deck and presentation.",
                        url: "https://siliconvalleyinyourpocket.com/product/silicon-valley-in-your-pocket-3-month-acceleration-program/ref/33"
                      },
                      {
                        description:
                          "You can also check out some of the best pitch decks ever for inspiration",
                        url: "https://piktochart.com/blog/startup-pitch-decks-what-you-can-learn/"
                      },
                      {
                        description:
                          "Guy Kawasaki's tips on how to prepare a pitch deck.",
                        url: "https://guykawasaki.com/the-only-10-slides-you-need-in-your-pitch/"
                      },
                      {
                        description:
                          "And SlideBean offers a pitch deck template inspired by Guy Kawasaki's tips.",
                        url: "https://slidebean.com/templates/guy-kawasaki-pitch-deck-template"
                      }
                    ]
                  },
                  {
                    refKey: "research_lenders",
                    name: "Research VCs, angel investment groups and venture lenders",
                    description:
                      "You need a good elevator pitch for many reasons: potential investors, customers, prospective new hires, bankers.  If you can’t persuasively and clearly pitch your business, how can you expect key stakeholders to buy in?",
                    user_answer: determineValue("research_lenders"),
                    resources: [
                      {
                        description:
                          "Silicon Valley in Your Pocket is a virtual accelerator that will help you become investor-ready by optimizing your pitch deck and presentation.",
                        url: "https://siliconvalleyinyourpocket.com/product/silicon-valley-in-your-pocket-3-month-acceleration-program/ref/33"
                      },
                      {
                        description:
                          "You can also check out some of the best pitch decks ever for inspiration",
                        url: "https://piktochart.com/blog/startup-pitch-decks-what-you-can-learn/"
                      },
                      {
                        description:
                          "Guy Kawasaki's tips on how to prepare a pitch deck.",
                        url: "https://guykawasaki.com/the-only-10-slides-you-need-in-your-pitch/"
                      },
                      {
                        description:
                          "And SlideBean offers a pitch deck template inspired by Guy Kawasaki's tips.",
                        url: "https://slidebean.com/templates/guy-kawasaki-pitch-deck-template"
                      }
                    ]
                  },
                  {
                    refKey: "find_grants",
                    name: "Find Grants",
                    description:
                      "Depending on your business, grants from government and non-government agencies might be a good option to explore. Typically, you will need to demonstrate other societal benefits or specific uses for that specific agency.",
                    user_answer: determineValue("find_grants"),
                    resources: [
                      {
                        description:
                          "The best free places to search for grant funding.",
                        url: "https://www.peakproposals.com/blog/the-best-free-places-to-search-for-grant-funding"
                      },
                      {
                        description:
                          "The best resource to learn about government grant opportunities is www.grants.gov. On grants.gov you will find opportunities posted by 26 federal agencies and 12 independent federal agencies, executive branch offices, and commissions.",
                        url: "https://www.grants.gov/"
                      },
                      {
                        description:
                          "Foundation Directory Online, the most extensive, up-to-date database of philanthropic giving, can show you where they are—and help you win more funding from the",
                        url: "https://candid.org/find-funding"
                      },
                      {
                        description:
                          "Grant research tools from National Council of Nonprofits",
                        url: "https://www.councilofnonprofits.org/tools-resources/grant-research-tools"
                      }
                    ]
                  },
                  {
                    refKey: "explore_eq_cf",
                    name: "Explore leading equity crowdfunding platforms",
                    description:
                      "Equity crowdfunding is exploding and is poised to overtake other categories of venture capital. Potential investors often don't have to be VCs or angels, making it easier for you to raise from your own friends, family, and acquaintances.",
                    user_answer: determineValue("explore_eq_cf"),
                    resources: [
                      {
                        description:
                          "Directory of leading equity crowdfunding platforms",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblknI9aNpUp2r6Bn/viwlN5WEh3PIrRjQq?blocks=hide"
                      }
                    ]
                  },
                  {
                    refKey: "explore_gov",
                    name: "Explore government programs, including grants, loans and coaching programs",
                    description:
                      "The federal government, sometimes in partnership with your state government, offers a wide variety of resources to help small businesses and startups. This includes grants, loans, and even free coaching and mentorship.",
                    user_answer: determineValue("explore_gov"),
                    resources: [
                      {
                        description: "Government grant and loan programs",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblREoEHOtlaUecNZ/viwFUDbMYHjB0Lt8G?blocks=hide"
                      }
                    ]
                  },
                  {
                    refKey: "research_preseed_funds",
                    name: "Research the leading seed and pre-seed funds",
                    description:
                      "There are a lot of venture funds that invest in early-stage companies. You'll need to do your research to confirm their investment thesis, typical investment amount, and whether you'd be a good fit.",
                    user_answer: determineValue("research_preseed_funds"),
                    resources: [
                      {
                        description: "Directory of 750 seed funds",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblNg0ecCQUcPEElL/viwqZUDXu1WecYQvU?blocks=hide"
                      },
                      {
                        description: "Directory of early stage investors",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblz1EhfembFVIAn4/viwfxqyqJKz4HwbN3?blocks=hide"
                      },
                      {
                        description: "No warm intro needed investors",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblUeSeRePgKOQNNf/viwnGy6y058CvlEO8?blocks=hide"
                      },
                      {
                        description: "90+ funding platforms",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tbl7JGZqYzfjif9Vi/viwFxgXfhzsRhvTfK?blocks=hide"
                      }
                    ]
                  },
                  {
                    refKey: "research_accel",
                    name: "Research the leading accelerator programs",
                    description:
                      "Accelerators and incubators are a great way to get a lot of the early help you'll need to get things off the ground and gain visibility and make connections. While they’re a great way to get money in the bank, make sure you’ll leverage the other benefits they offer.",
                    user_answer: determineValue("research_accel"),
                    resources: [
                      {
                        description: "Directory of accelerator programs",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblrjNDVwUAFqkfZk/viwjMlMrTC5YYUEga?blocks=hide"
                      },
                      {
                        description: "Corporate accelerator programs",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tbl8WuUjLCyY8QP1H/viw3nnjUV9mYgyDSn?blocks=hide"
                      }
                    ]
                  },
                  {
                    refKey: "research_accel_dei",
                    name: "Research funds and accelerators focused on diversity & inclusion",
                    description:
                      "Find the accelerators that understand the even steeper hill to climb for underrepresented founders.",
                    user_answer: determineValue("research_accel_dei"),
                    resources: [
                      {
                        description: "Top 25 VC firms bridging the gender gap",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblS9xq0jBX5y0pGo/viwf4VjPcocP55v4O?blocks=hide"
                      },
                      {
                        description:
                          "Take a look at accelerator programs with a strong focus on female founders",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblt4Zk56JErs2DCU/viwVJFcHEEjI248En?blocks=hide"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "generate",
                name: "Generate your first income!",
                new_action_items: [
                  {
                    refKey: "generate_income",
                    name: "Generate your first income!",
                    description:
                      "Start generating revenue as soon as possible.  At the early stages of a startup there is never enough money – resist the temptation to wait until things are “perfect.” Oh, and get your lawyer to create any customer contract forms necessary.",
                    user_answer: determineValue("generate_income"),
                    resources: [
                      {
                        description:
                          "How are you collecting your income? Via online payments, bank transfers or good old fashioned cash? You'll find some of the most popular payment options here, starting with Stripe.",
                        url: "https://stripe.com/"
                      },
                      {
                        description:
                          "If you have a physical business that accepts credit cards, Square is a great starting point as well.",
                        url: "https://squareup.com/us/en"
                      },
                      {
                        description:
                          "PayPal allows anyone to send money and shop with one easy account.",
                        url: "https://www.paypal.com/us/home"
                      },
                      {
                        description:
                          "Business.com has a step-by-step guide to accepting credit cards.",
                        url: "https://www.business.com/articles/how-to-accept-credit-card-payments/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "update_met",
                name: "Update your key Metrics that Matter, along with goal KPIs",
                new_action_items: [
                  {
                    refKey: "update_metrics_acc",
                    name: "Update your metrics to see how your score has improved since last time.",
                    description:
                      "Keep crunching those numbers here and see how your scores have improved since last time.",
                    user_answer: determineValue("update_metrics_acc"),
                    resources: [
                      {
                        description: "Update your metrics",
                        url: "/mtm"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      // Formula
      {
        refKey: "lc_formula",
        name: "Formula",
        description:
          "Now that you know that there are ready and willing customers for your solution, how do you make it available to users? Planning for the product delivery, channels, partners, fulfillment are some of the key steps in this phase.",
        lc_areas: [
          // formula: Team
          {
            refKey: "formula_team_lc",
            name: "Team",
            description:
              "Once you’ve created a company, your team grows to include not just founders and employees, but advisors, legal, and other outside service providers. A primary driver of success is the combined knowledge, energy, and passion of the team as a whole, and that collective mindset should remain strong.",
            fa_tasks: [
              {
                refKey: "founding",
                name: "Add founding team members",
                new_action_items: [
                  {
                    refKey: "add_founders",
                    name: "Add founding team members",
                    description:
                      "Build the foundations of a strong team by leveraging research and industry best practices.",
                    user_answer: determineValue("add_founders"),
                    resources: [
                      {
                        description:
                          "LinkedIn Jobs is a great way to find talent. And if you want to save a buck or two, simply search for people with the right backgrounds, and recruit away!",
                        url: "https://www.linkedin.com/jobs/"
                      },
                      {
                        description:
                          "AngelList is the #1 startup hiring platform",
                        url: "https://angel.co/jobs"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "legal",
                name: "Check on legal issues",
                new_action_items: [
                  {
                    refKey: "check_legal",
                    name: "Check on legal issues",
                    description:
                      "We know that legal can be expensive, and you should use them as judiciously as possible. Remember there are options to keep costs under control.",
                    user_answer: determineValue("check_legal"),
                    resources: [
                      {
                        description:
                          "LawBite offers high quality, low-cost business legal advice that is just a few steps away.",
                        url: "https://www.lawbite.co.uk/"
                      },
                      {
                        description:
                          "CooleyGo can simplify the paperwork for you. Cooley GO Docs will generate important legal documents - for free!",
                        url: "https://www.cooleygo.com/documents/"
                      },
                      {
                        description:
                          "Avodocs also offers free legal documents for startups.",
                        url: "https://www.avodocs.com/#documents"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "compensation",
                name: "Develop a compensation plan",
                new_action_items: [
                  {
                    refKey: "compensation_plan",
                    name: "Develop a compensation plan",
                    description:
                      "A company must balance between funding and maintaining operations, while taking care of the team. It is important to have a good handle on what money is in the bank, along with what's coming in, while also providing for the needs of the everyone helping to drive company success.",
                    user_answer: determineValue("compensation_plan"),
                    resources: [
                      {
                        description:
                          "Gust's free tool will help you ask the right questions to determine how much value each founder will contribute, and give you a sensible, objective recommendation for a fair equity split.",
                        url: "https://cofounders.gust.com/"
                      },
                      {
                        description:
                          "Carta powers a free startup equity calculator that helps to value your equity offer",
                        url: "https://carta.com/blog/value-equity-offer-startup-equity-calculator/"
                      },
                      {
                        description:
                          "Salary.com offers a salary calculator that helps make fair pay a reality for employers and employees",
                        url: "https://www.salary.com/"
                      },
                      {
                        description:
                          "Search salaries and compensation at Glassdoor",
                        url: "https://www.glassdoor.com/Salaries/index.htm"
                      },
                      {
                        description:
                          "Gusto offers a free salary comparison tool",
                        url: "https://gusto.com/tools/salary-comparison"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "expand_online",
                name: "Expand your online presence",
                new_action_items: [
                  {
                    refKey: "online_presence",
                    name: "Expand your online presence",
                    description:
                      "Are you everywhere you should be online? We bet there are a few you missed, and now that you have a spare few seconds...",
                    user_answer: determineValue("online_presence"),
                    resources: [
                      {
                        description:
                          "Every company should have a Wikipedia page.",
                        url: "https://www.wikipedia.org/"
                      },
                      {
                        description:
                          "Have you created your LinkedIn company page? Do the same at Facebook.",
                        url: "https://www.linkedin.com/feed/"
                      },
                      {
                        description:
                          "Search Engine Journal offers 21 other web directories that still have value.",
                        url: "https://www.searchenginejournal.com/web-directories-list/287799/#close"
                      },
                      {
                        description:
                          "If you are local, you can add your company to Google",
                        url: "https://www.google.com/business/"
                      },
                      {
                        description:
                          "If you are local, you can add your company to Yelp",
                        url: "https://biz.yelp.com/"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Formula: Product
          {
            refKey: "formula_product_lc",
            name: "Product",
            description:
              "Any solution to a real-world market problem begins and ends with the product. You'll spend a lot of time iterating on the right solution for your customers. You'll want to develop a system that can concurrently take that market feedback while you are defining your product in order to bring a more solid solution to the market.",
            fa_tasks: [
              {
                refKey: "define_core",
                name: "Define the core product features",
                new_action_items: [
                  {
                    refKey: "define_core_features",
                    name: "Define the core product features",
                    description:
                      "Once you have a working product, you will want to test variants of that product to see what tweaks can improve the KPIs of the business - increase conversion, increase purchase size, increase user retention, improve the user experience and so forth.",
                    user_answer: determineValue("define_core_features"),
                    resources: [
                      {
                        description:
                          "Free online statistical tools to do A/B testing analysis",
                        url: "https://abtestguide.com/calc/"
                      },
                      {
                        description:
                          "The HubSpot guide to how to do A/B testing.",
                        url: "https://blog.hubspot.com/marketing/how-to-do-a-b-testing"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "define_core",
                name: "Define the core product features",
                new_action_items: [
                  {
                    refKey: "define_core_features",
                    name: "Define the core product features",
                    description:
                      "Once you have a working product, you will want to test variants of that product to see what tweaks can improve the KPIs of the business - increase conversion, increase purchase size, increase user retention, improve the user experience and so forth.",
                    user_answer: determineValue("define_core_features"),
                    resources: [
                      {
                        description:
                          "Free online statistical tools to do A/B testing analysis",
                        url: "https://abtestguide.com/calc/"
                      },
                      {
                        description:
                          "The HubSpot guide to how to do A/B testing.",
                        url: "https://blog.hubspot.com/marketing/how-to-do-a-b-testing"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "finesse",
                name: "Feature finesse",
                new_action_items: [
                  {
                    refKey: "feature_finesse",
                    name: "Feature finesse",
                    description:
                      "There is now undoubtedly a large queue of new features, bug fixes and various enhancements requested by partners and customers, not to mention the key upgrades that are part of a management team's strategic goals. It's always critical to prioritize these features and keep things moving.",
                    user_answer: determineValue("feature_finesse"),
                    resources: [
                      {
                        description: "The optimal product process ebook",
                        url: "https://drive.google.com/file/d/1zlK9zOXj2b2zflr0Z5xLbiLwkItVwFOy/view?usp=sharing"
                      },
                      {
                        description:
                          "Get the Feature Prioritization Matrix spreadsheet.",
                        url: "https://docs.google.com/spreadsheets/d/1YghFdn2aPCu6e3zhzscUScxuVmVwrTdxi0bCtCVW6bs/edit?usp=sharing"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "continue_product_development",
                name: "Continue product development",
                new_action_items: [
                  {
                    refKey: "continue_development",
                    name: "Continue product development",
                    description: "Keep building those enhancements ",
                    user_answer: determineValue("continue_development"),
                    resources: [
                      {
                        description:
                          "Check out all the Product Management resources",
                        url: "https://drive.google.com/drive/folders/1dWyiP9aFqxTErZ-sd6VgxyQBFYbkHkmC?usp=sharing"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // formula: Market
          {
            refKey: "formula_market_lc",
            name: "Market",
            description:
              "Within Market, you'll consider all types of relationships with customers and partners, such as sales and marketing, channel and partner strategies, market research, customer service and more.",
            fa_tasks: [
              {
                refKey: "beachhead",
                name: "Develop a market beachhead strategy",
                new_action_items: [
                  {
                    refKey: "develop_beachhead",
                    name: "Develop a market beachhead strategy",
                    description:
                      "The Beachhead Strategy focuses resources on a key, smaller market segment, and winning that market first before moving into larger markets. There are a variety of resources to help select and prioritize your beachhead.",
                    user_answer: determineValue("develop_beachhead"),
                    resources: [
                      {
                        description:
                          "Check out the Beachhead Prioritization Calculator spreadsheet",
                        url: "https://docs.google.com/spreadsheets/d/14e9n5okYCkb9TSCKV2Sx3iHptZfGcFcRxsOvljp5Qz4/edit#gid=2128067878"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "channel_strategy",
                name: "Develop a channel and partner strategy",
                new_action_items: [
                  {
                    refKey: "develop_channel_strategy",
                    name: "Develop a channel and partner strategy",
                    description:
                      "Channels and partners are critical for any go-to-market, as these would introduce opportunities to achieve some scalability and repeatability to your sales process.",
                    user_answer: determineValue("develop_channel_strategy"),
                    resources: [
                      {
                        description: "Learn from the Channel Sales eBook",
                        url: "https://drive.google.com/file/d/15iC1U9I-gc9nvpZlMdL4xeojFiIdJcbY/view?usp=sharing"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "acquire",
                name: "Acquire initial customers/ partners",
                new_action_items: [
                  {
                    refKey: "acquire_customers",
                    name: "Acquire initial customers/ partners",
                    description:
                      "Where are your first customers coming from? Are you B2B or B2C or something even different? Depending on your target customer profile, you may start with Google Ads or Facebook Ads, or possibly more elaborate sales channels, like OEMs, VARs or other types of resellers.",
                    user_answer: determineValue("acquire_customers"),
                    resources: [
                      {
                        description:
                          "Get in front of customers when they’re searching for businesses like yours on Google Search and Maps. Only pay for results, like clicks to your website or calls to your business.",
                        url: "https://ads.google.com/"
                      },
                      {
                        description:
                          "Facebook offers advertising solutions for every level of expertise.",
                        url: "https://www.facebook.com/business/ads"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "fulfillment",
                name: "Develop a distribution & fulfillment plan",
                new_action_items: [
                  {
                    refKey: "develop_distribution_plan",
                    name: "Develop a distribution & fulfillment plan",
                    description:
                      "How does your customer receive your product offering? Even a SaaS product requires some logistical and operation considerations.",
                    user_answer: determineValue("develop_distribution_plan"),
                    resources: [
                      {
                        description:
                          "The Definitive Guide to Order Fulfillment and Customer Service",
                        url: "https://drive.google.com/file/d/1gR-P8qvME6wyDHW9WRUPg0YU1pDA8i4n/view?usp=sharing"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // formula: Money
          {
            refKey: "formula_money_lc",
            name: "Money",
            description:
              "Having proper financial discipline is one of the keys to survival during these early startup periods. We'll be reviewing everything from fundraising to financial and accounting systems and more.",
            fa_tasks: [
              {
                refKey: "fundraising_plan",
                name: "Develop a fundraising plan, with targets",
                new_action_items: [
                  {
                    refKey: "develop_fundraising_plan",
                    name: "Develop a fundraising plan, with targets",
                    description:
                      "If you’re ready for a formal fundraise, you should research possible targets made on real match criteria. Make sure a possible investor has an interest in your industry and company stage. Avoid investors that are active with one of your competitors. They'd be happy to learn about your product plans and competitive advantages.",
                    user_answer: determineValue("develop_fundraising_plan"),
                    resources: [
                      {
                        description:
                          "Crunchbase is an excellent resource to research investors and segment by a variety of filters and segmentations. Make sure to cancel the account before the Pro trial ends ;-).",
                        url: "https://www.crunchbase.com/"
                      },
                      {
                        description: "Y Combinator Series A guide",
                        url: "https://www.ycombinator.com/library/14-series-a-guide"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "pricing",
                name: "Develop a credible & competitive pricing model",
                new_action_items: [
                  {
                    refKey: "develop_pricing_model",
                    name: "Develop a credible & competitive pricing model",
                    description:
                      "Larger companies nowadays tend to have a Chief Revenue Officer, someone that owns overall sales/marketing strategies, along with a key focus on how to price your product or services. The pricing strategy takes into account things like freemium, billing frequency, competitor pricing analysis, and much more.",
                    user_answer: determineValue("develop_pricing_model"),
                    resources: [
                      {
                        description:
                          "HubSpot's Ultimate Guide to Pricing Strategies allows you to discover how to properly price your products, services, or events so you can drive both revenue and profit.",
                        url: "https://docs.google.com/spreadsheets/d/1KGev2IN7GqIxSO9b2KBHgJlXgAha7Vah80Z0beI5gu8/edit#gid=139136510"
                      },
                      {
                        description:
                          "Xtensio offers an excellent SaaS pricing strategy template that you can start with for free.",
                        url: "https://xtensio.com/saas-pricing-strategy-template/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "dialogue",
                name: "Begin dialogue with top early stage financing options",
                new_action_items: [
                  {
                    refKey: "begin_early_dialogue",
                    name: "Begin dialogue with top early stage financing options",
                    description:
                      "Investors may not write a check on day one, but many will be willing to give feedback and advice. That exchange could begin a dialogue that you can build on over time.",
                    user_answer: determineValue("begin_early_dialogue"),
                    resources: [
                      {
                        description:
                          "Treat the engagement with prospective investors like a sales process. Use a CRM and marketing tools and tactics to track engagements and automate follow-ups. HubSpot offers its starter CRM for free.",
                        url: "https://www.hubspot.com/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "metrics_formula",
                name: "Update your key Metrics that Matter, along with goal KPIs",
                new_action_items: [
                  {
                    refKey: "update_metrics_formula",
                    name: "Update your key Metrics that Matter, along with goal KPIs",
                    description:
                      "Update your metrics to see how your score has improved since last time.",
                    user_answer: determineValue("update_metrics_formula"),
                    resources: [
                      {
                        description: "Update metrics",
                        url: "/mtm"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "fundraising_plan_targets",
                name: "Develop a fundraising plan, with targets",
                new_action_items: [
                  {
                    refKey: "build_cf_list",
                    name: "Build your target list from leading equity crowdfunding platforms",
                    description:
                      "Equity crowdfunding is exploding and is poised to overtake other categories of venture capital. Potential investors often don't have to be VCs or angels, making it easier for you to raise from your own friends, family, and acquaintances.",
                    user_answer: determineValue("build_cf_list"),
                    resources: [
                      {
                        description:
                          "Directory of leading equity crowdfunding platforms",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblknI9aNpUp2r6Bn/viwlN5WEh3PIrRjQq?blocks=hide"
                      }
                    ]
                  },
                  {
                    refKey: "build_gov_list",
                    name: "Build your target list from government programs, including grants, loans and coaching programs",
                    description:
                      "The federal government, sometimes in partnership with your state government, offers a wide variety of resources to help small businesses and startups. This includes grants, loans, and even free coaching and mentorship.",
                    user_answer: determineValue("build_gov_list"),
                    resources: [
                      {
                        description: "Government grant and loan programs",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblREoEHOtlaUecNZ/viwFUDbMYHjB0Lt8G?blocks=hide"
                      }
                    ]
                  },
                  {
                    refKey: "build_preseed_list",
                    name: "Build your target list from leading seed and pre-seed funds",
                    description:
                      "There are a lot of venture funds that invest in early-stage companies. You'll need to do your research to confirm their investment thesis, typical investment amount, and whether you'd be a good fit.",
                    user_answer: determineValue("build_preseed_list"),
                    resources: [
                      {
                        description: "Directory of 750 seed funds",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblNg0ecCQUcPEElL/viwqZUDXu1WecYQvU?blocks=hide"
                      },
                      {
                        description:
                          "Check out this directory of leading early stage investors",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblz1EhfembFVIAn4/viwfxqyqJKz4HwbN3?blocks=hide"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "accel_list",
                name: "Begin dialogue with top early stage financing options",
                new_action_items: [
                  {
                    refKey: "build_accel_list",
                    name: "Build your target list from leading accelerator programs",
                    description:
                      "There are a lot of venture funds that invest in early-stage companies. You'll need to do your research to confirm their investment thesis, typical investment amount, and whether you'd be a good fit.",
                    user_answer: determineValue("build_accel_list"),
                    resources: [
                      {
                        description: "Directory of accelerator programs",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblrjNDVwUAFqkfZk/viwjMlMrTC5YYUEga?blocks=hide"
                      },
                      {
                        description:
                          "Check out these corporate accelerator programs, as they may have strategic goals closely aligned with your company's",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tbl8WuUjLCyY8QP1H/viw3nnjUV9mYgyDSn?blocks=hide"
                      }
                    ]
                  },
                  {
                    refKey: "build_accel_dei_list",
                    name: "Build your target list from funds and accelerators focused on diversity & inclusion",
                    description:
                      "Find the accelerators that understand the even steeper hill to climb for underrepresented founders.",
                    user_answer: determineValue("build_accel_dei_list"),
                    resources: [
                      {
                        description:
                          "Find out what top 25 VC firms are leading the effort to bridge the gender gap",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblS9xq0jBX5y0pGo/viwf4VjPcocP55v4O?blocks=hide"
                      },
                      {
                        description:
                          "Take a look at accelerator programs with a strong focus on female founders",
                        url: "https://airtable.com/shrtzbqG6f3PT7Q8J/tblt4Zk56JErs2DCU/viwVJFcHEEjI248En?blocks=hide"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "tracking",
                name: "Track your fundraising targets",
                new_action_items: [
                  {
                    refKey: "setup_tracking",
                    name: "Set up prospect tracking system & input top prospects",
                    description:
                      "The rule of thumb has generally been that you'll need to talk to 100 prospects to find one investor. It’s important to be systematic about the process by keeping track of engagements and status. If you aren't ready for a full-blown CRM, you can start with our spreadsheet template.",
                    user_answer: determineValue("setup_tracking"),
                    resources: [
                      {
                        description:
                          "You can also set up a free Hubspot CRM to track investors, as well as customers and partners",
                        url: "https://www.hubspot.com/products/get-started"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      // Match
      {
        refKey: "lc_match",
        name: "Match",
        description:
          "Hurray! In the Match lifecycle you have sold your product for the first time. Users are having their first experiences and you are likely getting feedback from those users about their experiences. This lifecycle is an important indicator of the subsequent bonding stage, and your actions here will serve as a crucial foundation for the Bonding lifecycle.",
        lc_areas: [
          // match: Team
          {
            refKey: "match_team_lc",
            name: "Team",
            description:
              "Once you’ve created a company, your team grows to include not just founders and employees, but advisors, legal, and other outside service providers. A primary driver of success is the combined knowledge, energy, and passion of the team as a whole, and that collective mindset should remain strong.",
            fa_tasks: [
              {
                refKey: "leverage",
                name: "Get the most out of your team",
                new_action_items: [
                  {
                    refKey: "leverage_team",
                    name: "Get the most out of your team",
                    description:
                      "Keep your team excited and motivated to take your company to the next level.",
                    user_answer: determineValue("leverage_team"),
                    resources: [
                      {
                        description:
                          "PredictiveIndex is the talent optimization platform that helps you design amazing teams, hire the best talent, inspire employees, measure engagement, spend less time solving people problems and more time celebrating results.",
                        url: "https://www.predictiveindex.com/"
                      },
                      {
                        description:
                          "Indeed offers three cost-effective team building exercises that you can try this week.",
                        url: "https://www.indeed.com/hire/c/info/exercises-for-team-building"
                      },
                      {
                        description:
                          "PredictiveIndex offers a free course and certification on team building.",
                        url: "https://www.predictiveindex.com/learn/teamwork/series/team-performance/"
                      },
                      {
                        description:
                          "MuseumHack offers five team building exercises that are free.",
                        url: "https://museumhack.com/5-free-team-building-activities/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "retain_outside",
                name: "Retain outside service providers",
                new_action_items: [
                  {
                    refKey: "find_counsel",
                    name: "Find outside counsel",
                    description:
                      "As you scale, you will want to retain an established firm that can support your growing legal needs. Some of the leading startup law firms are highlighted here.",
                    user_answer: determineValue("find_counsel"),
                    resources: [
                      {
                        description:
                          "Orrick focuses on serving the Technology, Energy & Infrastructure and Finance sectors globally. Clients worldwide call on the teams for forward-looking commercial advice on transactions, litigation and compliance matters.",
                        url: "https://www.orrick.com/"
                      },
                      {
                        description:
                          "Clients partner with Cooley on transformative deals, complex IP and ... the law. Cooley has 1100+ lawyers across 16 offices in the United States, Asia and Europe. They also provide a wide variety of free legal tools and templates on their CooleyGo website.",
                        url: "https://www.cooley.com/"
                      },
                      {
                        description:
                          "For nearly six decades, Wilson Sonsini has represented the technology pioneers associated with virtually every milestone innovation. Today, the firm is synonymous with ushering promising, innovative companies through their business life cycle.",
                        url: "https://www.wsgr.com/en/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "systematize",
                name: "Systematize key company workflows",
                new_action_items: [
                  {
                    refKey: "find_accounting",
                    name: "Find outside finance & accounting support",
                    description:
                      "As you scale, you will want to retain an established firm that can support your growing legal needs. Some of the leading accounting firms are highlighted here.",
                    user_answer: determineValue("find_accounting"),
                    resources: [
                      {
                        description:
                          "MyStartupCFO can help firms with bookkeeping and accounting, financial modeling and budgeting, interim CFO services and fundraising help.",
                        url: "https://mystartupcfo.com/"
                      },
                      {
                        description:
                          "KPMG Spark has the DNA of a Big Four accounting firm, but customizes their offerings and pricing for startups.",
                        url: "https://www.kpmgspark.com/"
                      },
                      {
                        description:
                          "Bench.co allows you to run your business while they do your bookkeeping. Get a professional bookkeeper at a price you can afford, and powerful financial reporting with zero learning curve.",
                        url: "https://bench.co/"
                      },
                      {
                        description:
                          "inDinero is a well known bookkeeping service provider with fractional CFO resources. Their bookkeeping solutions start at $310/ month. They have their accounting tool for the service.",
                        url: "https://www.indinero.com/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "customer_success",
                name: "Develop customer service program",
                new_action_items: [
                  {
                    refKey: "develop_customer_success",
                    name: "Develop customer service program",
                    description:
                      "Keeping a customer is a lot more cost-effective than acquiring a customer. Make sure to keep your customers happy by providing good customer service.",
                    user_answer: determineValue("develop_customer_success"),
                    resources: [
                      {
                        description:
                          "You can power up your customer support with Zendesk.",
                        url: "https://www.zendesk.com/"
                      },
                      {
                        description:
                          "Influx offers a complete customer support operation on demand. They answer tickets for you, 24/7, while regularly training and improving their team.",
                        url: "https://influx.com/solutions/how-it-works"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // match: Product
          {
            refKey: "match_product_lc",
            name: "Product",
            description:
              "Any solution to a real-world market problem begins and ends with the product. You'll spend a lot of time iterating on the right solution for your customers. You'll want to develop a system that can concurrently take that market feedback while you are defining your product in order to bring a more solid solution to the market.",
            fa_tasks: [
              {
                refKey: "feedback",
                name: "Analyze initial feedback",
                new_action_items: [
                  {
                    refKey: "analyze_feedback",
                    name: "Analyze initial feedback",
                    description:
                      "Hopefully, you are well on your way to research users' needs and working to collect quantitative and qualitative data on your potential product-market fit. There are a variety of tools you can use to help define those key features and measure their effectiveness against your target segments.",
                    user_answer: determineValue("analyze_feedback"),
                    resources: [
                      {
                        description:
                          "ProductBoard is in all-in-one toolkit for product management, allowing you to capture customer feedback, automate data-driven prioritization, and quickly build roadmaps that are always up-to-date",
                        url: "https://www.productboard.com/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "ux",
                name: "Test user experience",
                new_action_items: [
                  {
                    refKey: "test_ux",
                    name: "Test user experience",
                    description:
                      "If your product is a digital one, you’ll need to find out if your customers are having a good experience. Even if your product isn’t digital, you’ll want to know if customers are able to use your website with ease. The below tools will help you gain insight even if you don’t have a dedicated UX team yet.",
                    user_answer: determineValue("test_ux"),
                    resources: [
                      {
                        description:
                          "Optimal Workshop has a relatively robust free plan with everything from card sorting to surveys and qualitative research tools.",
                        url: "https://www.optimalworkshop.com/"
                      },
                      {
                        description:
                          "Hotjar is known for heat maps, which let you see where users click on your site. They’ve got other user testing tools as well as a free plan.",
                        url: "https://www.hotjar.com/"
                      },
                      {
                        description:
                          "Userzoom has a free 14 day trial and has lots of useful tools like usability testing, surveys, interviews, and more.",
                        url: "https://www.userzoom.com/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "roadmap",
                name: "Define ongoing product roadmap",
                new_action_items: [
                  {
                    refKey: "define_ongoing_roadmap",
                    name: "Define ongoing product roadmap",
                    description:
                      "Now that you've built something great, what's next? Planning your product roadmap is something that will benefit your company, your customers and your investors.",
                    user_answer: determineValue("define_ongoing_roadmap"),
                    resources: [
                      {
                        description: "Check out a sample product roadmap",
                        url: "https://docs.google.com/presentation/d/1ce_MTifP4fLU92FmXjk21cyusr8qa4kapha7P32s_Cs/edit#slide=id.p1"
                      },
                      {
                        description: "Developer Program Roadmap",
                        url: "https://docs.google.com/presentation/d/1itSAYDLU4HPoqpXTNCeXWagQZl976T9yTNO_AAuFdlk/edit#slide=id.p1"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // match: Market
          {
            refKey: "match_market_lc",
            name: "Market",
            description:
              "Within Market, you'll consider all types of relationships with customers and partners, such as sales and marketing, channel and partner strategies, market research, customer service and more.",
            fa_tasks: [
              {
                refKey: "marketing_plan",
                name: "Develop marketing plan",
                new_action_items: [
                  {
                    refKey: "develop_marketing_plan",
                    name: "Develop marketing plan",
                    description:
                      "Start considering more sophisticated marketing automation tools that will streamline workflows and give you far greater reach with fewer resources",
                    user_answer: determineValue("develop_marketing_plan"),
                    resources: [
                      {
                        description:
                          "HubSpot's free CRM can be upgraded to its full Marketing Hub suite.",
                        url: "https://app.hubspot.com/pricing/8092026/marketing"
                      },
                      {
                        description:
                          "Get Acoustic's Marketing Benchmark Report.",
                        url: "https://www.acoustic.com/reports/marketing-benchmark-report"
                      },
                      {
                        description:
                          "Quarterly.AI offers for free the first intelligent assistant for sales",
                        url: "https://www.quarterly.ai/index.html#q-getapp"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "sales_plan",
                name: "Develop sales plan",
                new_action_items: [
                  {
                    refKey: "develop_sales_plan",
                    name: "Develop sales plan",
                    description:
                      "Sales automation tools can help your sales team scale up.",
                    user_answer: determineValue("develop_sales_plan"),
                    resources: [
                      {
                        description:
                          "Conversica allows you to continue the Conversation Across Three Channels: Website Chat, Email, and SMS",
                        url: "http://get.conversica.com/get-chat/"
                      },
                      {
                        description:
                          "Calendly compares the 15 best sales automation tools for this year.",
                        url: "https://calendly.com/blog/best-sales-automation-tools-for-2020/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "sales",
                name: "Grow sales",
                new_action_items: [
                  {
                    refKey: "grow_sales",
                    name: "Grow sales",
                    description:
                      "As your business heats up, your focus will pivot quickly from marketing automation to sales automation. Luckily, there are a number of good hosted solutions that can help you accomplish this goal.",
                    user_answer: determineValue("grow_sales"),
                    resources: [
                      {
                        description:
                          "A very cost-effective way to begin your sales automation efforts as you are scaling your business",
                        url: "https://www.pipedrive.com/"
                      },
                      {
                        description:
                          "Outfunnel connects sales and marketing data deeply and easily.",
                        url: "https://outfunnel.com/"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // match: Money
          {
            refKey: "match_money_lc",
            name: "Money",
            description:
              "Having proper financial discipline is one of the keys to survival during these early startup periods. We'll be reviewing everything from fundraising to financial and accounting systems and more.",
            fa_tasks: [
              {
                refKey: "imp_accounting",
                name: "Implement accounting systems & support",
                new_action_items: [
                  {
                    refKey: "implement_accounting",
                    name: "Implement accounting systems & support",
                    description:
                      "Once you have your bank account set up, choose an accounting program. Start as you intend to go. Few things will doom your business faster than books that are a mess.",
                    user_answer: determineValue("implement_accounting"),
                    resources: [
                      {
                        description:
                          "Quickbooks allows you to run your whole business, from inventory and invoices, to projects and people, QuickBooks organizes it all in one place.",
                        url: "https://quickbooks.intuit.com/"
                      },
                      {
                        description:
                          "Xero is all-in-one accounting software, with everything you need to run your business.",
                        url: "https://www.xero.com/us/"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "financial_procedures",
                name: "Develop finance & accounting policies & procedures",
                new_action_items: [
                  {
                    refKey: "develop_financial_procedures",
                    name: "Develop finance & accounting policies & procedures",
                    description:
                      "Make sure that every penny gets counted properly by implementing the right policies and procedures.",
                    user_answer: determineValue("develop_financial_procedures"),
                    resources: [
                      {
                        description:
                          "BizTree's Business in a Box offers all the templates you need to plan, start, organize, manage, finance & grow your business, in one place.",
                        url: "https://www.biztree.com/lp/_ab/2021.03.24/05/?q=accounting-policies-and-procedures"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "act_update_metrics_match",
                name: "Update your key Metrics that Matter, along with goal KPIs",
                new_action_items: [
                  {
                    refKey: "update_metrics_match",
                    name: "Update your key Metrics that Matter, along with goal KPIs",
                    description: "",
                    user_answer: determineValue("update_metrics_match"),
                    resources: [
                      {
                        description: "Update your metrics",
                        url: "/mtm"
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "negotiate",
                name: "Negotiate and close investors",
                new_action_items: [
                  {
                    refKey: "term_sheets",
                    name: "Know about term sheets",
                    description:
                      "A term sheet is a nonbinding agreement that shows the basic terms and conditions of an investment. The term sheet serves as a template and basis for more detailed, legally binding documents. Founders often start with a basic SAFE or Convertible Note, but as companies grow, things get more complicated quickly.",
                    user_answer: determineValue("term_sheets"),
                    resources: [
                      {
                        description:
                          "Get the AUMI & NVCA Enhanced Model Term Sheet Model",
                        url: "https://www.aumni.fund/resources/enhanced-model-term-sheet"
                      },
                      {
                        description:
                          "Learn about the typical terms outlined in a VC Term Sheet using simple examples.",
                        url: "https://www.wallstreetprep.com/knowledge/the-ultimate-guide-to-the-vc-term-sheet-term-sheet-template/"
                      }
                    ]
                  },
                  {
                    refKey: "consult_counsel",
                    name: "If needed, consult with advisors and outside counsel",
                    description:
                      "Talk to a lawyer if there are any complexities or variations from the boiler plate language found in template agreements, like the SAFE or Convertible Note.",
                    user_answer: determineValue("consult_counsel"),
                    resources: [
                      {
                        description:
                          "Hopefully, you already have outside counsel, but if not, you can search for outside counsel here",
                        url: "https://www.upcounsel.com/"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      // Bonding (will always be greyed out for now...no content)
      {
        refKey: "lc_bonding",
        name: "Bonding",
        description: "",
        lc_areas: [
          // bonding: Team
          {
            refKey: "bonding_team_lc",
            name: "Team",
            description: "",
            fa_tasks: []
          },
          // bonding: Product
          {
            refKey: "bonding_product_lc",
            name: "Product",
            description: "",
            fa_tasks: []
          },
          // bonding: Market
          {
            refKey: "bonding_market_lc",
            name: "Market",
            description: "",
            fa_tasks: []
          },
          // bonding: Money
          {
            refKey: "bonding_money_lc",
            name: "Money",
            description: "",
            fa_tasks: []
          }
        ]
      },
      // Velocity (will always be greyed out for now...no content)
      {
        refKey: "lc_velocity",
        name: "Velocity",
        description: "",
        lc_areas: [
          // Velocity: Team
          {
            refKey: "velocity_team_lc",
            name: "Team",
            description: "",
            fa_tasks: []
          },
          // Velocity: Product
          {
            refKey: "velocity_product_lc",
            name: "Product",
            description: "",
            fa_tasks: []
          },
          // velocity: Market
          {
            refKey: "velocity_market_lc",
            name: "Market",
            description: "",
            fa_tasks: []
          },
          // velocity: Money
          {
            refKey: "velocity_money_lc",
            name: "Money",
            description: "",
            fa_tasks: []
          }
        ]
      }
    ]
  };
};
