import React, { Component } from "react";
import "./Public.scss";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { pathURL } from "../redux/actions/routes";
import { logoutUser } from "../redux/actions/auth";

class LogoutPage extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    const { match } = this.props;
    this.props.pathURL(match.path, match.url);
    this.props.logoutUser();
  }

  render() {
    return <Redirect to={"/"} />;
  }
}

LogoutPage.propTypes = {};

export default connect(null, { pathURL, logoutUser })(LogoutPage);
