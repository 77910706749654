import React, { useState } from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Collapse from "react-bootstrap/Collapse";
import { Row } from "react-bootstrap";

import Form from "react-bootstrap/Form";

import { BsBoxArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";

export const ActionItems = ({
  handleValueUpdate: handleValueUpdate,
  category: category,
  ...action
}) => {
  const [open, setOpen] = useState(false);
  const determineInternal = (url) => {
    const pattern = /^((http|https|ftp):\/\/)/;

    if (!pattern.test(url)) {
      return false;
    }
    return true;
  };
  return (
    <div id="example-collapse-text">
      <Card
        className={`${category} action`}
        // onClick={() => {
        //   setOpen(!open);
        // }}
        // aria-controls="example-collapse-text"
        // aria-expanded={open}
      >
        {/* I need to have defaultActiveKey="first" in the below container where "first" is the first on the list that's being iterated through */}
        <Tab.Container defaultActiveKey={action.refKey}>
          <Row className="sub-action">
            <Col sm={12}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item
                  key={`${action.refKey}-navitem`}
                  // className={category}
                >
                  <Form.Check
                    //type="checkbox"
                    label={action.name}
                    aria-label={action.name}
                    checked={action.user_answer}
                    onChange={handleValueUpdate(action.refKey)}
                  />
                  {/* <h3 key={`${action.refKey}-navLink`} eventKey={action.refKey}>
                    {action.name}
                  </h3> */}
                  <div
                    className="de-caret"
                    onClick={() => {
                      setOpen(!open);
                    }}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                  ></div>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey={action.refKey}>
                  <div key={action.refKey}>
                    <div>{action.description}</div>
                    {/* if there are resources, show the resources section */}
                    {action.resources.length > 0 && (
                      <Collapse in={open}>
                        <div>
                          <br></br>
                          <strong>Resources:</strong>
                          <ul>
                            {action.resources.map((resource) => (
                              <li key={resource.url}>
                                {resource.description}
                                {/* if the url starts with http or https, create an anchor link. If it's a relative link, use router Link */}
                                {determineInternal(resource.url) ? (
                                  <a
                                    href={resource.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <BsBoxArrowUpRight />
                                  </a>
                                ) : (
                                  <Link to={resource.url}>
                                    {resource.description}
                                  </Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Collapse>
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Card>
    </div>
  );
};
