import { Auth, Errors, Routes } from "./actions";

import { GlossaryData } from "./actions/glossary";
import { PlaybookData } from "./actions/playbook";
import { ScoresData } from "./actions/scores";
import { authReducer } from "./reducers/authReducer";
import { combineReducers } from "redux";
import { errorReducer } from "./reducers/errorReducer";
import { glossaryReducer } from "./reducers/glossaryReducer";
import { mtmData } from "./actions/mtm";
import { mtmReducer } from "./reducers/mtmReducer";
import { playbookReducer } from "./reducers/playbookReducer";
import { routesReducer } from "./reducers/routesReducer";
import { scoresReducer } from "./reducers/scoresReducer";
import { teamData } from "./actions/team";
import { teamReducer } from "./reducers/teamReducer";
import { PremiumData } from "./actions/premium";
import { premiumReducer } from "./reducers/premiumReducer";

export interface StoreState {
  routes: Routes;
  errors: Errors;
  auth: Auth;
  glossary: GlossaryData;
  playbook: PlaybookData;
  mtm: mtmData;
  team: teamData;
  scores: ScoresData;
  premium: PremiumData;
}

export const reducers = combineReducers<StoreState>({
  routes: routesReducer,
  errors: errorReducer,
  auth: authReducer,
  glossary: glossaryReducer,
  playbook: playbookReducer,
  mtm: mtmReducer,
  team: teamReducer,
  scores: scoresReducer,
  premium: premiumReducer
});
