import { Action, ActionTypes, ScoresData } from "../actions";

const initialState: ScoresData = {
  deal_score: 0,
  product_score: 0,
  market_score: 0,
  money_score: 0,
  team_score: 0,
  last_updated: ""
};

export function scoresReducer(
  state = initialState,
  action: Action
): ScoresData {
  switch (action.type) {
    case ActionTypes.getScores:
      return {
        ...state,
        deal_score: action.payload.deal_score,
        product_score: action.payload.product_score,
        market_score: action.payload.market_score,
        money_score: action.payload.money_score,
        team_score: action.payload.team_score,
        last_updated: action.payload.last_updated
      };
    default:
      return state;
  }
}
