import { Errors, Action, ActionTypes } from "../actions";

const initialState: Errors = {
  message: "",
  statusCode: null
};

export function errorReducer(state = initialState, action: Action): Errors {
  switch (action.type) {
    case ActionTypes.setError:
      return {
        ...state,
        message: action.payload.message,
        statusCode: action.payload.statusCode
      };
    default:
      return state;
  }
}
