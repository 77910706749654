import * as React from "react";
import * as d3 from "d3";
import "./Charts.scss";

export const ScoreRadial = ({ data, size }) => {
  const width = size.width ? size.width : 300,
    height = size.width ? size.width : 300,
    chartRadius = height / 2.5;

  let svg = d3
    .select("#score-radial")
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .append("g")
    .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

  const PI = Math.PI,
    arcMinRadius = 10,
    arcPadding = 10,
    labelPadding = -5,
    numTicks = 10;

  let scale = d3
    .scaleLinear()
    .domain([0, d3.max(data, (d) => 10) * 1.0])
    .range([0, 2 * PI]);

  let ticks = scale.ticks(numTicks).slice(0, -1);
  let keys = data.map((d, i) => d.name);
  //number of arcs
  const numArcs = keys.length;
  const arcWidth =
    (chartRadius - arcMinRadius - numArcs * arcPadding) / numArcs;

  //// beging color arcs

  let arc = d3
    .arc()
    .innerRadius((d, i) => getInnerRadius(i))
    .outerRadius((d, i) => getOuterRadius(i))
    .startAngle(0)
    .endAngle((d, i) => scale(d));

  //// end color arcs

  //// beging black background

  let radialAxis = svg
    .append("g")
    .attr("class", "r axis")
    .selectAll("g")
    .data(data)
    .enter()
    .append("g");

  radialAxis
    .append("circle")
    .attr("r", (d, i) => getOuterRadius(i) + arcPadding);

  // radialAxis
  //   .append("text")
  //   .attr("x", labelPadding)
  //   .attr("y", (d, i) => -getOuterRadius(i) + arcPadding)
  //   .text((d) => d.name);

  //// end black background

  //// begin number overlay

  let axialAxis = svg
    .append("g")
    .attr("class", "a axis")
    .selectAll("g")
    .data(ticks)
    .enter()
    .append("g")
    .attr("transform", (d) => "rotate(" + (rad2deg(scale(d)) - 90) + ")");

  axialAxis.append("line").attr("x2", chartRadius);

  axialAxis
    .append("text")
    .attr("x", chartRadius + 10)
    .style("text-anchor", (d) =>
      scale(d) >= PI && scale(d) < 2 * PI ? "end" : null
    )
    .attr(
      "transform",
      (d) =>
        "rotate(" + (90 - rad2deg(scale(d))) + "," + (chartRadius + 10) + ",0)"
    )
    .text((d) => d);

  //// end

  //data arcs
  let arcs = svg
    .append("g")
    .attr("class", "data")
    .selectAll("path")
    .data(data)
    .enter()
    .append("path")
    .attr("class", "arc")
    .style("fill", (d, i) => {
      return d.color;
    });

  arcs
    .transition()
    .delay((d, i) => i * 200)
    .duration(1000)
    .attrTween("d", arcTween);

  function arcTween(d, i) {
    let interpolate = d3.interpolate(0, d.value);
    return (t) => arc(interpolate(t), i);
  }

  function rad2deg(angle) {
    return (angle * 180) / PI;
  }

  function getInnerRadius(index) {
    return arcMinRadius + (numArcs - (index + 1)) * (arcWidth + arcPadding);
  }

  function getOuterRadius(index) {
    return getInnerRadius(index) + arcWidth;
  }

  // const calculateHeight = (size) => {
  //   return size.width && size.width > 0 ? size.width : 200;
  // };

  return (
    <>
      <svg
        // width={size.width ? size.width : 200}
        // height={calculateHeight(size)}
        width={width}
        height={height}
        id="score-radial"
      ></svg>
    </>
  );
};
