import { Action, ActionTypes, PlaybookData } from "../actions";

// Temporarily import data locally since it doesn't change much
// until we optimize the loading of the api data
import { generatePlaybook } from "../fixtures/PlaybookData";

const theState = generatePlaybook();

const initialState: PlaybookData = theState;

export function playbookReducer(
  state = initialState,
  action: Action
): PlaybookData {
  switch (action.type) {
    case ActionTypes.getPlaybook:
      return {
        ...state,
        data: action.payload.data
      };
    case ActionTypes.loadPlaybookUpdateData:
      return {
        ...state,
        data: action.payload.data
      };
    case ActionTypes.postPlaybook:
      return {
        ...state
      };
    default:
      return state;
  }
}
