import { ActionTypes } from "../types";
import { Dispatch } from "redux";
import { StoreState } from "../../redux";
import axios from "axios";
import { getOrgId } from "../selectors/authSelectors";
import { store } from "../../index";

const baseUrl = `${process.env.REACT_APP_SERVER_API_URL}api`;
//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");

    if (accessToken && accessToken !== undefined) {
      // config.headers["x-auth-token"] = accessToken;
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      axios.defaults.withCredentials = true;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem("refreshToken");
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.detail &&
      error.response.data.detail == "Token has expired"
    ) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("org_id");
      localStorage.removeItem("user_id");
      localStorage.removeItem("org_name");
      localStorage.removeItem("onboarded");
      localStorage.removeItem("tier");
      store.dispatch(logoutUser());
    }
    if (
      refreshToken &&
      error.response &&
      error.response.status &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (error.response.data.code === "token_not_valid") {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("org_id");
        localStorage.removeItem("user_id");
        localStorage.removeItem("org_name");
        localStorage.removeItem("onboarded");
        localStorage.removeItem("tier");
        store.dispatch(logoutUser());
      }
      console.log("error and retrieving new token");
      originalRequest._retry = true;
      axios.defaults.withCredentials = true;
      return axios
        .post(`${baseUrl}/token/refresh/`, { refresh: refreshToken })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("token", res.data.access);
            console.log("Access token refreshed!");
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);

export interface Auth {
  loggedIn: boolean;
  token: { refresh: string; access: string };
  loaded: boolean;
  org_name: string;
  org_id: string;
  user_id: string;
  org_stage: string;
  onboarding_survey_completed: boolean;
  tier: string;
}

export interface LoginExpectation {
  org_name: string;
  token: { refresh: string; access: string };
  org_id: string;
  user_id: string;
  org_stage: string;
  onboarding_survey_completed: boolean;
  tier: string;
}

export interface LoginInput {
  email: string;
  password: string;
  scope: string;
}

export interface LoginAction {
  type: ActionTypes.loginUser;
  payload: LoginExpectation;
}

export const loginUser = (LoginInput: LoginInput) => {
  const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
  const loginURL = `${url}/login`;
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post<LoginExpectation>(loginURL, LoginInput);
      // Success 🎉
      localStorage.setItem("token", response.data.token.access);
      localStorage.setItem("refreshToken", response.data.token.refresh);
      localStorage.setItem("org_id", response.data.org_id);
      localStorage.setItem("user_id", response.data.user_id);
      localStorage.setItem("org_name", response.data.org_name);
      localStorage.setItem("org_stage", response.data.org_stage);
      localStorage.setItem(
        "onboarded",
        String(response.data.onboarding_survey_completed)
      );
      localStorage.setItem("tier", response.data.tier);
      dispatch<LoginAction>({
        type: ActionTypes.loginUser,
        payload: response.data
      });
      return response;
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
      return error.response;
    }
  };
};

// logout action

export interface LogoutExpectation {
  detail: string;
}

export interface LogoutAction {
  type: ActionTypes.logoutUser;
  payload: LogoutExpectation;
}

export const logoutUser = () => {
  console.log("logging out user");
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("org_id");
    localStorage.removeItem("user_id");
    localStorage.removeItem("org_name");
    localStorage.removeItem("onboarded");
    localStorage.removeItem("tier");
    dispatch<LogoutAction>({
      type: ActionTypes.logoutUser,
      payload: { detail: "logging out" }
    });
  };
};

// loadData

export interface LoadDataExpectation {
  detail: string;
}

export interface LoadDataAction {
  type: ActionTypes.loadData;
  payload: LoadDataExpectation;
}

export const loadData = () => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    dispatch<LoadDataAction>({
      type: ActionTypes.loadData,
      payload: { detail: "Loading Data" }
    });
  };
};

export interface LoadOrgAction {
  type: ActionTypes.loadOrgFromStorage;
  payload: {
    token: { refresh: string; access: string };
    org_name: string;
    org_id: string;
    user_id: string;
    org_stage: string;
    onboarding_survey_completed: boolean;
    tier: string;
  };
}

export const loadOrgData = () => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    const storageToken = localStorage.getItem("token") || "";
    const storageOrgName = localStorage.getItem("org_name") || "";
    const storageOrgID = localStorage.getItem("org_id") || "";
    const storageUserID = localStorage.getItem("user_id") || "";
    const storageOrgStage = localStorage.getItem("org_stage") || "";
    const storageOnboarding = localStorage.getItem("onboarded") == "true";
    const storageTier = localStorage.getItem("tier") || "";
    dispatch<LoadOrgAction>({
      type: ActionTypes.loadOrgFromStorage,
      payload: {
        token: { refresh: "", access: storageToken },
        org_name: storageOrgName,
        org_id: storageOrgID,
        user_id: storageUserID,
        org_stage: storageOrgStage,
        onboarding_survey_completed: storageOnboarding,
        tier: storageTier
      }
    });
  };
};

// register action

export interface RegisterExpectation {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  role: any;
  organization: {
    id: string;
    created: string;
    updated: string;
    name: string;
    industry: string;
    security_phone: string;
    support_email: string;
    token_expiration: string;
    last_score_calculation: string;
    date_joined: string;
    is_active: boolean;
    organization_type: string;
    organization_stage: string;
    org_id: string;
    onboarding_survey_completed: boolean;
  };
}

export interface RegisterInput {
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  company_industry: string;
  role: number;
  is_active: boolean;
  organization: {
    name: string;
    industry: string;
  };
  org_info: {
    referral: string;
    stage: string;
    pitch: string;
    ideal_investor: string;
    description: string;
    engagement: string;
    partner: string;
  };
}

export interface RegisterAction {
  type: ActionTypes.registerUser;
  payload: RegisterExpectation;
}

export const registerUser = (registerInput: RegisterInput) => {
  const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
  const registerURL = `${url}/register`;
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post<RegisterExpectation>(
        registerURL,
        registerInput
      );

      // Success 🎉
      dispatch<RegisterAction>({
        type: ActionTypes.registerUser,
        payload: response.data
      });
      return response;
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
      return error.response;
    }
  };
};

// BETA Registration. Temporary until Beta is over

export interface RegisterBetaExpectation {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  role: any;
  organization: {
    id: string;
    created: string;
    updated: string;
    name: string;
    industry: string;
    security_phone: string;
    support_email: string;
    token_expiration: string;
    last_score_calculation: string;
    date_joined: string;
    is_active: boolean;
    organization_type: string;
    organization_stage: string;
    org_id: string;
    onboarding_survey_completed: boolean;
  };
}

export interface RegisterBetaInput {
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  company_industry: string;
  role: number;
  is_active: boolean;
  organization: {
    name: string;
    industry: string;
  };
  beta_info: {
    referral: string;
    stage: string;
    pitch: string;
    beta_choices: string;
    ideal_investor: string;
    description: string;
    engagement: string;
    partner: string;
  };
}

export const registerBetaUser = (registerBetaInput: RegisterBetaInput) => {
  const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
  const registerBetaURL = `${url}/beta-register`;
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post<RegisterBetaExpectation>(
        registerBetaURL,
        registerBetaInput
      );
      // Note: Do not need to store anything in redux on response
      // Success 🎉
      return response;
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
      return error.response;
    }
  };
};

// END BETA Registration

// Onboarding

export interface OnboardingExpectation {
  data: {
    organization_stage: string;
    org_id: string;
    onboarding_survey_completed: boolean;
  };
}

export interface OnboardingInput {
  stage: string;
  engagement: string;
  cofounders: string;
  cofounders_div: string;
  skillsets: string;
}

export interface OnboardingAction {
  type: ActionTypes.onboardUser;
  payload: OnboardingExpectation;
}

export const onboardUser = (onboardingInput: OnboardingInput) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };
      const orgId = getOrgId(getState());
      const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
      const onboardURL = `${url}/onboarding/${orgId}`;
      axios.defaults.withCredentials = true;

      const onboardingPostObject = {
        mtm_development_stage: onboardingInput.stage,
        ob_engagement: onboardingInput.engagement,
        mtm_cofounders_total: onboardingInput.cofounders,
        ob_div_cofounders: onboardingInput.cofounders_div,
        ob_skillsets: onboardingInput.skillsets
      };

      const response = await axios.post<OnboardingExpectation>(
        onboardURL,
        onboardingPostObject,
        config
      );
      localStorage.setItem(
        "onboarded",
        String(response.data.data.onboarding_survey_completed)
      );

      // Success 🎉
      dispatch<OnboardingAction>({
        type: ActionTypes.onboardUser,
        payload: response.data
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};

// Password reset: email

export interface RequestPasswordInput {
  email: string;
}

export interface RequestPasswordExpectation {
  status: string;
}

export const requestPassword = (requestPasswordInput: RequestPasswordInput) => {
  const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
  const requestPasswordURL = `${url}/reset-password`;
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post<RequestPasswordExpectation>(
        requestPasswordURL,
        requestPasswordInput
      );
      // Success 🎉
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};

// Set password

export interface ResetPasswordInput {
  password: string;
  password_confirm: string;
  token: string;
  email: string;
}

export interface ResetPasswordExpectation {
  status: string;
}

export const resetPassword = (ResetPasswordInput: ResetPasswordInput) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${ResetPasswordInput.token}`
        }
      };
      const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
      const resetPasswordURL = `${url}/set-password`;
      axios.defaults.withCredentials = true;

      const passwordPostObject = {
        password: ResetPasswordInput.password,
        password_confirm: ResetPasswordInput.password_confirm,
        email: ResetPasswordInput.email
      };

      const response = await axios.post<ResetPasswordExpectation>(
        resetPasswordURL,
        passwordPostObject,
        config
      );

      return response;

      // Success 🎉
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};

// Get partners info

export interface ReferralData {
  deal_score: number;
  product_score: number;
  market_score: number;
  money_score: number;
  team_score: number;
  last_updated: string;
}

export interface ReferralExpectation {
  data: {
    shortname: string;
    description: string;
    logo_url: string;
    url: string;
    active: boolean;
    name: string;
  };
}

export interface ReferralAction {
  type: ActionTypes.getReferral;
  payload: ReferralData;
}

export interface ReferralInput {
  referral_id: string;
}

export const getReferral = (ReferralInput: ReferralInput) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    try {
      const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
      const referralURL = `${url}/partners/${ReferralInput.referral_id}`;

      const response = await axios.get<ReferralData>(referralURL);

      return response;
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status == 404) {
          console.log("No referral partner present");
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};
