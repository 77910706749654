import { ActionTypes } from "../types";
import { Dispatch } from "redux";
import { StoreState } from "../../redux";
import { LoadDataAction } from "../actions/auth";
import { getOrgId } from "../selectors/authSelectors";
import axios from "axios";

export interface EnumVideosItem {
  id: number;
  title: string;
  description: string;
  category: string;
  video_url: string;
  active: boolean;
}

export interface EnumSprintsItem {
  id: number;
  title: string;
  description: string;
  category: string;
  video_url: string;
  active: boolean;
  date: string;
  instructor: string;
  instructor_bio: string;
  instructor_url: string;
  extended_description: string;
}

export interface EnumUserSprintsItem {
  date_added: string;
  id: number;
  org: string;
  sprint: string;
}

export interface EnumTemplatesItem {
  id: number;
  title: string;
  description: string;
  category: string;
  template_url: string;
  active: boolean;
}

// for comment: make it so when a value is updated it makes comment null, and the comment section defaults to "check back tomorrow..." if null

export interface VideosData {
  videos: Array<EnumVideosItem>;
}

export interface SprintsData {
  sprints: Array<EnumSprintsItem>;
}

export interface UserSprintsData {
  sprints: Array<EnumUserSprintsItem>;
}

export interface TemplatesData {
  templates: Array<EnumTemplatesItem>;
}

export interface PremiumData {
  videos: Array<EnumVideosItem>;
  sprints: Array<EnumSprintsItem>;
  userSprints: Array<EnumSprintsItem>;
  templates: Array<EnumTemplatesItem>;
}

export interface VideosExpectation {
  data: [];
}

export interface VideoAction {
  type: ActionTypes.getVideos;
  payload: VideosData;
}

const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
const videosURL = `${url}/videos`;

export const getVideos = () => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };

      axios.defaults.withCredentials = true;

      const response = await axios.get<VideosData>(videosURL, config);

      dispatch<VideoAction>({
        type: ActionTypes.getVideos,
        payload: response.data
      });
      dispatch<LoadDataAction>({
        type: ActionTypes.loadData,
        payload: { detail: "Loading Data" }
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};

// Sprints

export interface SprintsExpectation {
  data: [];
}

export interface UserSprintsAction {
  type: ActionTypes.getUserSprints;
  payload: UserSprintsData;
}

export interface SprintsAction {
  type: ActionTypes.getSprints;
  payload: SprintsData;
}

const sprintsURL = `${url}/sprints`;

export const getSprints = () => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };

      axios.defaults.withCredentials = true;

      const response = await axios.get<SprintsData>(sprintsURL, config);

      dispatch<SprintsAction>({
        type: ActionTypes.getSprints,
        payload: response.data
      });
      dispatch<LoadDataAction>({
        type: ActionTypes.loadData,
        payload: { detail: "Loading Data" }
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};

export interface UserSprintsExpectation {
  data: [];
}

export interface AddUserSprintAction {
  type: ActionTypes.addUserSprints;
  payload: SprintsData;
}

export const getUserSprints = () => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };

      const orgId = getOrgId(getState());

      axios.defaults.withCredentials = true;

      const response = await axios.get<SprintsData>(
        `${sprintsURL}/user/${orgId}`,
        config
      );

      dispatch<UserSprintsAction>({
        type: ActionTypes.getUserSprints,
        payload: response.data
      });
      dispatch<LoadDataAction>({
        type: ActionTypes.loadData,
        payload: { detail: "Loading Data" }
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};

// Add User Sprint

export const postUserSprint = (data: any) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };

      const orgId = getOrgId(getState());
      const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
      const sprintURL = `${url}/sprints/add/`;
      axios.defaults.withCredentials = true;

      const sprintPostObject = {
        org_id: orgId,
        sprint_id: data.data.sprint_id
      };

      const response = await axios.post<any>(
        sprintURL,
        sprintPostObject,
        config
      );

      dispatch<AddUserSprintAction>({
        type: ActionTypes.addUserSprints,
        payload: response.data
      });
      dispatch<LoadDataAction>({
        type: ActionTypes.loadData,
        payload: { detail: "Loading Data" }
      });

      // return response to mtm page to determine redux update
      // return response;
      return [];
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};

// Templates

export interface TemplatesExpectation {
  data: [];
}

export interface TemplateAction {
  type: ActionTypes.getTemplates;
  payload: TemplatesData;
}

const templatesURL = `${url}/templates`;

export const getTemplates = () => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };

      axios.defaults.withCredentials = true;

      const response = await axios.get<TemplatesData>(templatesURL, config);

      dispatch<TemplateAction>({
        type: ActionTypes.getTemplates,
        payload: response.data
      });
      dispatch<LoadDataAction>({
        type: ActionTypes.loadData,
        payload: { detail: "Loading Data" }
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};
