import "./App.scss";

import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { Dashboard } from "../Dashboard/Dashboard";
import ErrorBoundary from "../Error/ErrorBoundary";
import { Glossary } from "../Glossary/Glossary";
import Header from "../Header/Header";
import Login from "../../../public/Login";
import LogoutPage from "../../../public/Logout";
import { MTM } from "../MTM/MTM";
import { Premium } from "../Premium/Premium";
import Onboarding from "../Onboarding/Onboarding";
import PasswordReset from "../../../public/PasswordReset";
import PasswordSet from "../../../public/PasswordSet";
import { Playbook } from "../Playbook/Playbook";
import PrivateRoute from "../../components/PrivateRoute/PrivateRoute";
import { Profile } from "../Profile/Profile";
import PropTypes from "prop-types";
import Register from "../../../public/Register";
import BetaRegister from "../../../public/BetaRegister";
import BetaConfirmation from "../../../public/BetaConfirmation";
import Referral from "../../../public/Referral";
import { StoreState } from "../../../redux";
// import { Team } from "../Team/Team";
import { appRoutes } from "../../../redux/actions/routes";
import { connect } from "react-redux";
import { getAuthStatus } from "../../../redux/selectors/authSelectors";
import withTracker from "../../../withTracker";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

// eslint-disable-next-line
function App(props: any) {
  const { appRoutes } = props;

  const routesArray = [
    {
      id: 1,
      path: "/",
      component: (
        <PrivateRoute
          key={1}
          exact
          path={"/"}
          component={withTracker(Dashboard, {
            /* additional attributes */
            title: "Dashboard"
          })}
          {...props}
        />
      )
    },
    {
      id: 3,
      path: "/login",
      component: (
        <Route
          key={3}
          path={"/login"}
          component={withTracker(Login, {
            /* additional attributes */
            title: "Login"
          })}
          {...props}
        />
      )
    },
    {
      id: 4,
      path: "/register",
      component: (
        <Route
          key={4}
          exact
          path={"/register"}
          component={withTracker(BetaRegister, {
            /* additional attributes */
            title: "Register"
          })}
          {...props}
        />
      )
    },
    {
      id: 5,
      path: "/playbook",
      component: (
        <PrivateRoute
          key={5}
          exact
          path={"/playbook"}
          component={withTracker(Playbook, {
            /* additional attributes */
            title: "Playbook"
          })}
          {...props}
        />
      )
    },
    {
      id: 6,
      path: "/mtm",
      component: (
        <PrivateRoute
          key={6}
          exact
          path={"/mtm"}
          component={withTracker(MTM, {
            /* additional attributes */
            title: "Metrics That Matter"
          })}
          {...props}
        />
      )
    },
    // {
    //   id: 7,
    //   path: "/team",
    //   component: (
    //     <PrivateRoute
    //       key={7}
    //       exact
    //       path={"/team"}
    //       component={withTracker(Team, {
    //         /* additional attributes */
    //       })}
    //       {...props}
    //     />
    //   )
    // },
    {
      id: 8,
      path: "/glossary",
      component: (
        <PrivateRoute
          key={8}
          exact
          path={"/glossary"}
          component={withTracker(Glossary, {
            /* additional attributes */
            title: "Glossary"
          })}
          {...props}
        />
      )
    },
    // {
    //   id: 9,
    //   path: "/profile",
    //   component: (
    //     <PrivateRoute
    //       key={9}
    //       exact
    //       path={"/profile"}
    //       component={withTracker(Profile, {
    //         /* additional attributes */
    //         title: "Profile"
    //       })}
    //       {...props}
    //     />
    //   )
    // },
    {
      id: 10,
      path: "/password-reset",
      component: (
        <Route
          key={10}
          exact
          path={"/password-reset"}
          component={withTracker(PasswordReset, {
            /* additional attributes */
            title: "Reset Password"
          })}
          {...props}
        />
      )
    },
    {
      id: 11,
      path: "/logout",
      component: (
        <Route
          key={11}
          exact
          path={"/logout"}
          component={withTracker(LogoutPage, {
            /* additional attributes */
            title: "Logout"
          })}
          {...props}
        />
      )
    },
    {
      id: 12,
      path: "/onboarding",
      component: (
        <PrivateRoute
          key={12}
          exact
          path={"/onboarding"}
          component={withTracker(Onboarding, {
            /* additional attributes */
            title: "Onboarding"
          })}
          {...props}
        />
      )
    },
    {
      id: 19,
      path: "/set-password",
      component: (
        <Route
          key={19}
          path={"/set-password"}
          component={withTracker(PasswordSet, {
            /* additional attributes */
            title: "Password Reset"
          })}
          {...props}
        />
      )
    },
    {
      id: 20,
      path: "/beta-confirmation",
      component: (
        <Route
          key={20}
          path={"/beta-confirmation"}
          component={withTracker(BetaConfirmation, {
            /* additional attributes */
            title: "Confirmation"
          })}
          {...props}
        />
      )
    },
    {
      id: 21,
      path: "/referral",
      component: (
        <Route
          key={21}
          path={"/referral"}
          component={withTracker(Referral, {
            /* additional attributes */
            title: "Referral"
          })}
          {...props}
        />
      )
    },
    {
      id: 36,
      path: "/premium",
      component: (
        <PrivateRoute
          key={36}
          path={"/premium"}
          component={withTracker(Premium, {
            /* additional attributes */
            title: "Premium"
          })}
          {...props}
        />
      )
    }
  ];

  useEffect(() => {
    appRoutes(routesArray);
  }, [routesArray, appRoutes]);

  return (
    <Router>
      <ScrollToTop />
      <ErrorBoundary>
        <Header />
      </ErrorBoundary>
      <div className="App" data-testid="App">
        <Switch>
          {routesArray.map((data) => (data ? data.component : null))}
        </Switch>
      </div>
    </Router>
  );
}

App.propTypes = {
  appRoutes: PropTypes.func,
  match: PropTypes.object,
  isLoggedIn: PropTypes.bool
};

const mapStateToProps = (state: StoreState): { isLoggedIn: boolean } => {
  return { isLoggedIn: getAuthStatus(state) };
};

export default connect(mapStateToProps, { appRoutes })(App);
