export const glossaryDataHardCoded = {
  data: [
    {
      id: 1,
      term: "Value Proposition",
      is_used: true,
      definition:
        "A value proposition is a promise of value to be delivered, communicated, and acknowledged. It is also a belief from the customer about how value will be delivered, experienced and acquired. A value proposition can apply to an entire organization, or parts thereof, or customer accounts, or products or services.",
      rank: 1,
      key: ""
    },
    {
      id: 2,
      term: "Compounded Annual Growth Rate (CAGR)",
      is_used: true,
      definition:
        "The compound annual growth rate (CAGR) is the annualized average rate of revenue growth between two given years, assuming growth takes place at an exponentially compounded rate.",
      rank: 1,
      key: ""
    },
    {
      id: 3,
      term: "Customer Acqusition Cost (CAC)",
      is_used: true,
      definition:
        "Customer acquisition cost (CAC) is the cost related to acquiring a new customer. In other words, CAC refers to the resources and costs incurred to acquire an additional customer.",
      rank: 1,
      key: ""
    },
    {
      id: 4,
      term: "Average Revenue Per User (ARPU)",
      is_used: true,
      definition:
        "Average revenue per unit is the measure of the revenue generated per unit or user. ARPU is also known as average revenue per user or ARPU. It is a non-GAAP measure that allows management of a company as well as investors to refine their analysis of a company's revenue generation capability and growth at the per-unit level. It is usually calculated as total revenue divided by the number of units, users, or subscribers.",
      rank: 1,
      key: ""
    },
    {
      id: 5,
      term: "Margin",
      is_used: true,
      definition:
        "Profit margin is one of the commonly used profitability ratios to gauge the degree to which a company or a business activity makes money. It represents what percentage of sales has turned into profits. Simply put, the percentage figure indicates how many cents of profit the business has generated for each dollar of sale. For instance, if a business reports that it achieved a 35% profit margin during the last quarter, it means that it had a net income of $0.35 for each dollar of sales generated.",
      rank: 1,
      key: ""
    },
    {
      id: 6,
      term: "Churn",
      is_used: true,
      definition:
        "The churn rate, also known as the rate of attrition or customer churn, is the rate at which customers stop doing business with an entity. It is most commonly expressed as the percentage of service subscribers who discontinue their subscriptions within a given time period.",
      rank: 1,
      key: ""
    },
    {
      id: 7,
      term: "Total Addressable Market (TAM)",
      is_used: true,
      definition:
        "The total amount of money you can make selling what you're selling is called the total addressable market, also known as total available market, or TAM for short. Another way of thinking about it is the total amount of demand that exists in the market for your product or service.",
      rank: 1,
      key: ""
    },
    {
      id: 8,
      term: "Serviceable Addressable Market (SAM)",
      is_used: true,
      definition:
        "The serviceable available market or served addressable market is more clearly defined as that market opportunity that exists within a firm's existing core competencies and/or past performance. The biggest consideration when calculating SAM is that a firm most likely can only service markets that are core or directly adjacent to its current customer base.",
      rank: 1,
      key: ""
    },
    {
      id: 9,
      term: "Serviceable Obtainable Market (SOM)",
      is_used: true,
      definition:
        "The Serviceable Obtainable Market (SOM) is an estimate of the portion of revenue within a specific product segment that a company is able to capture. Another way of looking at it is as an estimate of the market share for a particular product that a company can garner. It is part of market segmentation analysis, which is broadly used in the marketing and operational decisions of a company.",
      rank: 1,
      key: ""
    },
    {
      id: 10,
      term: "Revenue Model",
      is_used: true,
      definition:
        "A revenue model is a framework for generating financial income. It identifies which revenue source to pursue, what value to offer, how to price the value, and who pays for the value.[1] It is a key component of a company's business model.[2] It primarily identifies what product or service will be created in order to generate revenues and the ways in which the product or service will be sold.",
      rank: 1,
      key: ""
    },
    {
      id: 11,
      term: "Net Burn",
      is_used: true,
      definition:
        "Total monthly cost of running the business net of average monthly income.",
      rank: 1,
      key: ""
    },
    {
      id: 12,
      term: "Runway",
      is_used: true,
      definition:
        "Number of months worth of funding left in the bank, based on the currently average monthly burn rate.",
      rank: 1,
      key: ""
    },
    {
      id: 13,
      term: "Lifetime Value (LTV)",
      is_used: true,
      definition: "The lifetime value of one customer",
      rank: 1,
      key: ""
    },
    {
      id: 14,
      term: "Emotional Intelligence",
      is_used: true,
      definition:
        "Emotional Intelligence, or EQ, is the ability to understand, use, and manage your own emotions in positive ways to relieve stress, communicate effectively, empathize with others, overcome challenges and defuse conflict. Many say Emotional Intelligence is a key trait for startup founder success. Take our quick five minute survey and see how your EQ Score stacks up.",
      rank: 1,
      key: ""
    },
    {
      id: 15,
      term: "Visionary-Pragmatist",
      is_used: true,
      definition:
        "A Visionary-Pragmatist is a remarkably future-oriented, very imaginative person who easily develops a conceptual model, but can sometimes be over-imaginative and end up with pure speculations. Some of the strong visionaries possess an unusual power of foresight. Many say visionary-pragmatism is a key trait for startup founder success. Take our quick five minute survey and see how your Visionary-Pragmatist Score stacks up.",
      rank: 1,
      key: ""
    },
    {
      id: 16,
      term: "Influencer Score",
      is_used: true,
      definition:
        "The Influencer Scale measures the persuadability powers of a leader. Many say leadership and ability to influence are key trait for startup founder success. Take our quick five minute survey and see how your Influencer Score stacks up.",
      rank: 1,
      key: ""
    },
    {
      id: 17,
      term: "Self-Confidence",
      is_used: true,
      definition:
        "Launching a new company requires self-confidence. Many say self-confidence is a key trait for startup founder success, but what confidence level is too little or too much? Take our quick five minute survey and see how your Self-Confidence Score stacks up.",
      rank: 1,
      key: ""
    },
    {
      id: 18,
      term: "Psychological Safety",
      is_used: true,
      definition:
        "According to Harvard Business School professor Amy Edmondson, who coined the term: Psychological safety is a belief that one will not be punished or humiliated for speaking up with ideas, questions, concerns or mistakes.",
      rank: 1,
      key: ""
    },
    {
      id: 19,
      term: "409a valuation",
      is_used: true,
      definition:
        "A formal report that tells you the fair market value of your company's common stock prepared in compliance to recommended guidelines issued by regulatory bodies.",
      rank: 1,
      key: ""
    },
    {
      id: 20,
      term: "83(b) election",
      is_used: true,
      definition:
        "A form that tells the IRS that you will be taxed on the value of granted shares today rather than recognizing income on their value as of the date that they vest.",
      rank: 1,
      key: ""
    },
    {
      id: 21,
      term: "Accelerator",
      is_used: true,
      definition:
        "A type of business incubator that typically accepts startup teams into a three-month program and may provide capital, basic living expenses, office space and mentorship, often in exchange for equity in the startup.",
      rank: 1,
      key: ""
    },
    {
      id: 22,
      term: "Annual Recurring Revenue (ARR)",
      is_used: true,
      definition:
        "The subscription-based revenue which software-as-a-service or platform-as-a-service (SaaS/PaaS)-based companies receive each year; also known as the run rate.",
      rank: 1,
      key: ""
    },
    {
      id: 23,
      term: "Articles of Incorporation (or Certificate of Incorporation)",
      is_used: true,
      definition:
        "Documents filed with the state's Secretary of State or Registrar which acts as a charter to document the establishment and existence of a corporation—typically including the business's name, address, a statement of business purpose, and details related to the types of stock the corporation is entitled to issue.",
      rank: 1,
      key: ""
    },
    {
      id: 24,
      term: "Authorized shares",
      is_used: true,
      definition:
        "The maximum number of shares that a company can issue, as decided by its Board of Directors. For a Delaware C-Corporation, any increases or decreases to this number require amending and restating the Certificate of Incorporation in Delaware. Gust Launch starts each company with 10 million authorized shares—a very common number to begin with.",
      rank: 1,
      key: ""
    },
    {
      id: 25,
      term: "Balance Sheet",
      is_used: true,
      definition:
        "A condensed financial statement showing the nature and total value of a company's assets, liabilities, and capital on a given date.",
      rank: 1,
      key: ""
    },
    {
      id: 26,
      term: "Board of Directors",
      is_used: true,
      definition:
        "A group of people elected by a company's shareholders (often according to the terms of a negotiated shareholders' agreement) that makes decisions on major company issues, including hiring/firing the CEO.",
      rank: 1,
      key: ""
    },
    {
      id: 27,
      term: "Burn Rate",
      is_used: true,
      definition: "The monthly negative cash flow from a startup.",
      rank: 1,
      key: ""
    }
  ]
};
