import { createSelector } from "reselect";

export const getScores = (state) => state.scores || {};

export const getDealScore = createSelector([getScores], (scores) => {
  return scores.deal_score;
});

export const getProductScore = createSelector([getScores], (scores) => {
  return scores.product_score;
});

export const getMarketScore = createSelector([getScores], (scores) => {
  return scores.market_score;
});

export const getMoneyScore = createSelector([getScores], (scores) => {
  return scores.money_score;
});

export const getTeamScore = createSelector([getScores], (scores) => {
  return scores.team_score;
});

export const getLastUpdatedScore = createSelector([getScores], (scores) => {
  return scores.last_updated;
});
