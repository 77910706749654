import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

export const PlaybookDropdown = ({
  handleDropdownSelect: handleDropdownSelect,
  filterValue: filterValue
}) => {
  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle variant="info">{filterValue}</Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              handleDropdownSelect("Team");
            }}
          >
            Team
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              handleDropdownSelect("Product");
            }}
          >
            Product
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              handleDropdownSelect("Market");
            }}
          >
            Market
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              handleDropdownSelect("Money");
            }}
          >
            Money
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
