import "./MTM.scss";

import {
  EnumMtmItem,
  loadMtmUpdateData,
  mtmData,
  postMtm
} from "../../../redux/actions/mtm";
import {
  cleanInt,
  convertPercent,
  parseDate,
  percentValidation
} from "../../helpers/helpers";

import Accordion from "react-bootstrap/Accordion";
import { BsInfo } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import CardColumns from "react-bootstrap/CardColumns";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SimpleSlider } from "../../components/SimpleSlider/SimpleSlider";
import Spinner from "react-bootstrap/Spinner";
import { StoreState } from "../../../redux";
import Wrapper from "../../components/Wrapper/Wrapper";
import { connect } from "react-redux";
import {
  getMtmData,
  hasMtmBeenVisited,
  getCountProblemValue,
  getCountFundraise,
  getCountBusiness,
  getCountCompetitors,
  getCountFinancials,
  getCountGTM,
  getCountMarket,
  getCountTeam
} from "../../../redux/selectors/mtmSelectors";
import { pathURL } from "../../../redux/actions/routes";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Icon from "../../components/Icons";

// Using match with typescript is weird, used this logic to set up the type:
// https://stackoverflow.com/questions/48138111/what-typescript-type-should-i-use-to-reference-the-match-object-in-my-props
interface MatchParams {
  name: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
  path: string;
  url: string;
}

interface AppProps {
  pathURL: (path: string, url: string) => void;
  match: MatchProps;
  mtm: EnumMtmItem[];
  visited: number;
  categoryProblem: number;
  categoryFundraise: number;
  categoryBusiness: number;
  categoryCompetitors: number;
  categoryFinancials: number;
  categoryGTM: number;
  categoryMarket: number;
  categoryTeam: number;
  loadMtmUpdateData: (data: mtmData) => void;
  postMtm: (data: EnumMtmItem) => void;
}

class _MTM extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      user_answer: "",
      answers: [{ user_answer: "" }],
      loading: true,
      filterValue: "value-proposition",
      count: 0,
      isActive: false
    };
  }

  componentDidMount() {
    this.props.pathURL(this.props.match.path, this.props.match.url);
    // When redirected from the dashboard "next steps"
    // Takes the props passed from router, sets to
    // filterValue, and then clears the history for refresh
    const { location, history } = this.props;
    this.setState({
      filterValue:
        location.state && location.state.filterValue
          ? location.state.filterValue
          : "value-proposition"
    });
    history.replace();
  }

  postAnswer = (data: any): void => {
    this.props.postMtm(data);
  };

  handleValueUpdate = (idx: number, type: string) => (evt: any) => {
    if (evt.target.value !== null) {
      const newValue = this.props.mtm.map((data, sidx) => {
        if (idx !== data.id) return data;
        let theValue = evt.target.value;
        if (type === "int_pct") {
          theValue = parseInt(theValue) / 100;
        }
        return { ...data, user_answer: theValue };
      });
      const updatedField = newValue.find((f) => f.id == idx);
      // Post to db, if post is successful, update redux to persist changes
      (async () => {
        const response = await this.props.postMtm(updatedField);
        if (response && response.status === 201) {
          updatedField.comment = response.data.data.comment;
          updatedField.last_updated = response.data.data.last_updated;
          this.props.loadMtmUpdateData(newValue);
          this.setState({ answers: newValue });
        }
      })();
    }
  };

  handleSliderChange = (data: any, e: any) => {
    const newValue = this.props.mtm.map((mtm, sidx) => {
      if (data.id != mtm.id) return mtm;
      return { ...mtm, user_answer: e.target.value };
    });

    const updatedField = newValue.find((f) => f.id == data.id);
    // Post to db, if post is successful, update redux to persist changes
    (async () => {
      const response = await this.props.postMtm(updatedField);
      if (response && response.status === 201) {
        // newValue[data.id - 1].comment = response.data.data.comment;
        updatedField.comment = response.data.data.comment;
        updatedField.last_updated = response.data.data.last_updated;
        this.props.loadMtmUpdateData(newValue);
        this.setState({ answers: newValue });
      }
    })();
  };

  handleIconClick = (category: string) => {
    this.setState({ filterValue: category });
  };

  nextItem = (direction: string, count: number) => {
    const categoryArray = [
      "value-proposition",
      "market-sizing",
      "business-model",
      "go-to-market",
      "competitors",
      "team",
      "financials",
      "fundraise"
    ];
    if (direction === "forward") {
      const forwardCount = (count + 1) % categoryArray.length; // increase i by one and if we've gone too high, start from `0` again
      this.setState({
        count: forwardCount,
        filterValue: categoryArray[forwardCount]
      });
    }

    if (direction === "backward") {
      const backwardCount = count ? count - 1 : categoryArray.length - 1;

      this.setState({
        count: backwardCount,
        filterValue: categoryArray[backwardCount]
      });
    }
  };

  render() {
    const categoryInfo: {
      [key: string]: { name: string; description: string };
    } = {
      "value-proposition": {
        name: "Value Proposition",
        description:
          "What problem are you solving for your customers and what is that solution worth to them? To answer these essential questions, you’ll need market research conducted with customers in the field."
      },
      "market-sizing": {
        name: "Market Sizing",
        description:
          "It's important to show how you’ve sized up your potential market, how fast it’s growing, and how much of it you can capture. These questions are easier to answer if you’ve done a market sizing study. Have your SOM and CAGR on hand."
      },
      "business-model": {
        name: "Business Model",
        description:
          "How much money can you make from your customers and how often? To complete this section, you’ll need some key metrics handy, like your ARPU, profit margin, and LTV."
      },
      "go-to-market": {
        name: "Go To Market",
        description:
          "Your customers are the key to your go-to-market strategy. To complete this section you’ll need your CAC and your projected customer count for the first 3 years."
      },
      competitors: {
        name: "Competitors",
        description:
          "It’s important to know your competitors: who they are, how many there are, and how you’re better. You’ll need a competitive analysis to complete this section."
      },
      team: {
        name: "Team",
        description:
          "Help us get to know your team, their strengths, and any gaps that need to be filled. To best answer these questions, it helps to know your co-founders well."
      },
      financials: {
        name: "Financials",
        description:
          "Financials are integral to your startup’s path to profitability. To complete this section you’ll need your projected revenues and expenses for the first 3 years, your net burn, and runway."
      },
      fundraise: {
        name: "Fundraising",
        description:
          "Knowing how you’re going to spend money is an important part of the fundraising journey. To answer these questions you’ll need your pre-money valuation or price cap and use of funds."
      }
    };

    const getCategoryName = (category: string) => {
      return categoryInfo[category].name;
    };

    const getCategoryDescription = (category: string) => {
      return categoryInfo[category].description;
    };

    return (
      <div className="App metrics-wrap">
        <Wrapper>
          <h1>metrics that matter</h1>
          {!this.props.mtm.length ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <CardColumns>
              <Row>
                <p>
                  Welcome{this.props.visited && <span> back</span>}! Complete
                  the sections below to help calculate your DealScore (an
                  assessment of your startup based on our proprietary analysis
                  of your team and key business metrics). The more accurate the
                  data, the more useful your DealScore will be in identifying
                  your startup’s strengths and areas of improvement. Get started
                  regardless of which stage you’re in now and update your
                  responses as your business grows!
                </p>
                <Col className="d-block d-md-none">
                  <Row className={this.state.filterValue}>
                    <Col xs={1}>
                      <div
                        onClick={() => {
                          this.nextItem("backward", this.state.count);
                        }}
                      >
                        <Icon name="caretLeft" />
                      </div>
                    </Col>
                    <Col xs={10}>{getCategoryName(this.state.filterValue)}</Col>
                    <Col xs={1}>
                      <div
                        onClick={() => {
                          this.nextItem("forward", this.state.count);
                        }}
                      >
                        <Icon name="caretRight" />
                      </div>{" "}
                    </Col>
                  </Row>
                </Col>
                <Col className="d-none d-md-block" sm={3}>
                  <Row>
                    <Col
                      onClick={() => {
                        this.handleIconClick("value-proposition");
                      }}
                      onKeyDown={(e) => {
                        return (
                          e.key != "Enter" ||
                          this.handleIconClick("value-proposition")
                        );
                      }}
                      className={
                        this.state.filterValue === "value-proposition"
                          ? "active"
                          : ""
                      }
                      sm={12}
                      tabIndex="0"
                    >
                      <Icon
                        name="valueProposition"
                        color="var(--de-product-yellow)"
                        progress={this.props.categoryProblem}
                      />
                      <div className="center-icon-descriptor">
                        Value Proposition
                      </div>
                    </Col>
                    <Col
                      onClick={() => {
                        this.handleIconClick("market-sizing");
                      }}
                      onKeyDown={(e) => {
                        return (
                          e.key != "Enter" ||
                          this.handleIconClick("market-sizing")
                        );
                      }}
                      className={
                        this.state.filterValue === "market-sizing"
                          ? "active"
                          : ""
                      }
                      sm={12}
                      tabIndex="0"
                    >
                      <Icon
                        name="marketSizing"
                        color="var(--de-market-pink)"
                        progress={this.props.categoryMarket}
                      />
                      <div className="center-icon-descriptor">
                        Market Sizing
                      </div>
                    </Col>
                    <Col
                      onClick={() => {
                        this.handleIconClick("business-model");
                      }}
                      onKeyDown={(e) => {
                        return (
                          e.key != "Enter" ||
                          this.handleIconClick("business-model")
                        );
                      }}
                      className={
                        this.state.filterValue === "business-model"
                          ? "active"
                          : ""
                      }
                      sm={12}
                      tabIndex="0"
                    >
                      <Icon
                        name="businessModel"
                        color="var(--de-money-orange)"
                        progress={this.props.categoryBusiness}
                      />
                      <div className="center-icon-descriptor">
                        Business Model
                      </div>
                    </Col>
                    <Col
                      onClick={() => {
                        this.handleIconClick("go-to-market");
                      }}
                      onKeyDown={(e) => {
                        return (
                          e.key != "Enter" ||
                          this.handleIconClick("go-to-market")
                        );
                      }}
                      className={
                        this.state.filterValue === "go-to-market"
                          ? "active"
                          : ""
                      }
                      sm={12}
                      tabindex="0"
                    >
                      <Icon
                        name="goToMarket"
                        color="var(--de-market-pink)"
                        progress={this.props.categoryGTM}
                      />
                      <div className="center-icon-descriptor">Go-To-Market</div>
                    </Col>
                    <Col
                      onClick={() => {
                        this.handleIconClick("competitors");
                      }}
                      onKeyDown={(e) => {
                        return (
                          e.key != "Enter" ||
                          this.handleIconClick("competitors")
                        );
                      }}
                      className={
                        this.state.filterValue === "competitors" ? "active" : ""
                      }
                      sm={12}
                      tabindex="0"
                    >
                      <Icon
                        name="competitors"
                        color="var(--de-market-pink)"
                        progress={this.props.categoryCompetitors}
                      />
                      <div className="center-icon-descriptor">Competitors</div>
                    </Col>
                    <Col
                      onClick={() => {
                        this.handleIconClick("team");
                      }}
                      onKeyDown={(e) => {
                        return e.key != "Enter" || this.handleIconClick("team");
                      }}
                      className={
                        this.state.filterValue === "team" ? "active" : ""
                      }
                      sm={12}
                      tabindex="0"
                    >
                      <Icon
                        name="team"
                        color="var(--de-team-blue)"
                        progress={this.props.categoryTeam}
                      />
                      <div className="center-icon-descriptor">Team</div>
                    </Col>
                    <Col
                      onClick={() => {
                        this.handleIconClick("financials");
                      }}
                      onKeyDown={(e) => {
                        return (
                          e.key != "Enter" || this.handleIconClick("financials")
                        );
                      }}
                      className={
                        this.state.filterValue === "financials" ? "active" : ""
                      }
                      sm={12}
                      tabindex="0"
                    >
                      <Icon
                        name="financials"
                        color="var(--de-money-orange)"
                        progress={this.props.categoryFinancials}
                      />
                      <div className="center-icon-descriptor">Financials</div>
                    </Col>
                    <Col
                      onClick={() => {
                        this.handleIconClick("fundraise");
                      }}
                      onKeyDown={(e) => {
                        return (
                          e.key != "Enter" || this.handleIconClick("fundraise")
                        );
                      }}
                      className={
                        this.state.filterValue === "fundraise" ? "active" : ""
                      }
                      sm={12}
                      tabindex="0"
                    >
                      <Icon
                        name="fundraise"
                        color="var(--de-money-orange)"
                        progress={this.props.categoryFundraise}
                      />
                      <div className="center-icon-descriptor">Fundraising</div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} sm={12} md={9}>
                  <p>{`${getCategoryDescription(this.state.filterValue)}`}</p>
                  <Form>
                    {this.props.mtm
                      .sort(
                        ({ rank: previousID }, { rank: currentID }) =>
                          previousID - currentID
                      )
                      .map((data: EnumMtmItem, index: number) => (
                        <div key={data.key + "emptydiv"}>
                          {data.active &&
                            data.category === this.state.filterValue && (
                              <Accordion
                                key={data.key + "maindiv"}
                                id={data.key}
                              >
                                <Card>
                                  <Card.Header>
                                    <div className="metric-q">
                                      {data.text}
                                      <Accordion.Toggle eventKey={data.key}>
                                        <BsInfo />
                                      </Accordion.Toggle>
                                    </div>
                                    <div className="metric-a">
                                      {data.type == "dd_option" && (
                                        <Form.Group>
                                          {/* <label htmlFor={data.key}>Choose an option:</label> */}
                                          <Form.Control
                                            as="select"
                                            custom
                                            name={data.key}
                                            key={data.key}
                                            defaultValue={data.user_answer}
                                            onChange={this.handleValueUpdate(
                                              data.id,
                                              data.type
                                            )}
                                          >
                                            <option
                                              value={""}
                                              key={data.key + "null"}
                                              hidden
                                            >
                                              {"Select One"}
                                            </option>
                                            {data.choices.map((data: any) => (
                                              <option
                                                value={data.text}
                                                key={data.key + data.text}
                                              >
                                                {data.text}
                                              </option>
                                            ))}
                                          </Form.Control>
                                        </Form.Group>
                                      )}
                                      {data.type == "int" && (
                                        <InputGroup className="mb-3">
                                          <FormControl
                                            type="number"
                                            onInput={(e: any) => {
                                              // only whole numbers, no decimals
                                              e.target.value =
                                                e.target.value.replace(
                                                  /[^\d]/,
                                                  ""
                                                );
                                            }}
                                            name={data.key}
                                            key={data.key}
                                            defaultValue={data.user_answer}
                                            onBlur={this.handleValueUpdate(
                                              data.id,
                                              data.type
                                            )}
                                          />
                                        </InputGroup>
                                      )}
                                      {data.type == "int_pct" && (
                                        <InputGroup className="mb-3">
                                          <FormControl
                                            type="number"
                                            name={data.key}
                                            key={data.key}
                                            onInput={(e: any) => {
                                              e.target.value =
                                                percentValidation(
                                                  e.target.value
                                                );
                                            }}
                                            defaultValue={convertPercent(
                                              data.user_answer
                                            )}
                                            onBlur={this.handleValueUpdate(
                                              data.id,
                                              data.type
                                            )}
                                            min={0}
                                            max={100}
                                          />
                                          <InputGroup.Append>
                                            <InputGroup.Text>%</InputGroup.Text>
                                          </InputGroup.Append>
                                        </InputGroup>
                                      )}
                                      {data.type == "int_dol" && (
                                        // https://react-bootstrap.netlify.app/components/input-group/
                                        <InputGroup className="mb-3">
                                          <InputGroup.Prepend>
                                            <InputGroup.Text>$</InputGroup.Text>
                                          </InputGroup.Prepend>
                                          <FormControl
                                            aria-label="Amount (to the nearest dollar)"
                                            type="number"
                                            onInput={(e: any) => {
                                              // only whole numbers, no decimals
                                              e.target.value =
                                                e.target.value.replace(
                                                  /[^\d]/,
                                                  ""
                                                );
                                            }}
                                            name={data.key}
                                            key={data.key}
                                            defaultValue={data.user_answer}
                                            onBlur={this.handleValueUpdate(
                                              data.id,
                                              data.type
                                            )}
                                          />
                                        </InputGroup>
                                      )}
                                      {data.type == "range_1_5" && (
                                        <Form.Group controlId={data.key}>
                                          <SimpleSlider
                                            min={0}
                                            max={5}
                                            {...data}
                                            defaultValue={cleanInt(
                                              data.user_answer
                                            )}
                                            handleChange={
                                              this.handleSliderChange
                                            }
                                          />
                                        </Form.Group>
                                      )}
                                      {data.type == "range_1_10" && (
                                        <Form.Group controlId={data.key}>
                                          <SimpleSlider
                                            min={0}
                                            max={10}
                                            {...data}
                                            defaultValue={cleanInt(
                                              data.user_answer
                                            )}
                                            handleChange={
                                              this.handleSliderChange
                                            }
                                          />
                                        </Form.Group>
                                      )}
                                      {data.type == "str" && (
                                        <div>
                                          {/* Answer:{" "} */}
                                          <input
                                            type="text"
                                            name={data.key}
                                            key={data.key}
                                            defaultValue={data.user_answer}
                                            onBlur={this.handleValueUpdate(
                                              data.id,
                                              data.type
                                            )}
                                          />
                                        </div>
                                      )}
                                      {data.comment && (
                                        <div className="mtm-comment">
                                          <span>
                                            Feedback from DealEngine:&nbsp;
                                          </span>{" "}
                                          {data.comment}
                                        </div>
                                      )}
                                      <div className="mtm-comment">
                                        <span>Last Updated:&nbsp;</span>{" "}
                                        {parseDate(data.last_updated)}
                                      </div>
                                    </div>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey={data.key}>
                                    <Card.Body>
                                      <div className="metric-desc">
                                        {data.description}
                                      </div>
                                      {data.help_url && (
                                        <div>
                                          <a
                                            className="metric-desc"
                                            href={data.help_url}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            Related Resources
                                          </a>
                                        </div>
                                      )}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            )}
                        </div>
                      ))}
                  </Form>
                </Col>
              </Row>
            </CardColumns>
          )}
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = (
  state: StoreState
): {
  mtm: EnumMtmItem[];
  visited: number;
  categoryProblem: number;
  categoryFundraise: number;
  categoryBusiness: number;
  categoryCompetitors: number;
  categoryFinancials: number;
  categoryGTM: number;
  categoryMarket: number;
  categoryTeam: number;
} => {
  return {
    mtm: getMtmData(state),
    visited: hasMtmBeenVisited(state),
    categoryProblem: getCountProblemValue(state),
    categoryFundraise: getCountFundraise(state),
    categoryBusiness: getCountBusiness(state),
    categoryCompetitors: getCountCompetitors(state),
    categoryFinancials: getCountFinancials(state),
    categoryGTM: getCountGTM(state),
    categoryMarket: getCountMarket(state),
    categoryTeam: getCountTeam(state)
  };
};

export const MTM = connect(mapStateToProps, {
  pathURL,
  loadMtmUpdateData,
  postMtm
})(_MTM);
