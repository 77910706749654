import { Link, Redirect } from "react-router-dom";
import React, { Component } from "react";
import { getAuthStatus, getOnboarding } from "../redux/selectors/authSelectors";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import PublicWrapper from "./PublicWrapper";
import { connect } from "react-redux";
import { loginUser } from "../redux/actions/auth";
import { pathURL } from "../redux/actions/routes";
import { toLowerCase } from "../secure/helpers/helpers";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      error: false,
      errorMessage: "",
      redirect: false,
      email: "",
      password: "",
      loading: false
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.props.pathURL(match.path, match.url);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  submit = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    });
    // Post to db, if post is successful, update redux to persist changes
    (async () => {
      const response = await this.props.loginUser({
        email: this.state.email,
        password: this.state.password,
        scope: "admin"
      });
      if (response && response.status === 200) {
        this.setState({
          loading: false
        });
        return;
      } else {
        this.setState({
          error: true,
          errorMessage:
            response.data.detail || "Something went wrong, please try again",
          loading: false
        });
      }
    })();
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: name === "email" ? toLowerCase(value) : value
    });
  };

  resetError = (event) => {
    this.setState({
      error: false,
      errorMessage: ""
    });
  };

  render() {
    // TODO: turn this on once onboarding flow is completed
    if (this.props.success) {
      return <Redirect to={"/"} />;
    }

    // if (this.props.success && !this.props.onboarded) {
    //   return <Redirect to={"/onboarding"} />;
    // }

    return (
      <>
        <PublicWrapper>
          <Card body className="de-public" border="info">
            <h1>sign in</h1>
            <small>Welcome back to DealEngine!</small>
            <Form onSubmit={this.submit}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  id="email"
                  name="email"
                  value={this.state.email}
                  required
                  onFocus={this.resetError}
                  onChange={this.handleChange}
                />
                {/* no placeholder in design, id was on Control id="inputEmail" */}
                <Form.Text className="text-muted">
                  We will never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  id="password"
                  name="password"
                  value={this.state.password}
                  onFocus={this.resetError}
                  onChange={this.handleChange}
                  required
                />
                <Form.Text
                  className="small text-muted"
                  as={Link}
                  to="/password-reset"
                >
                  Forgot password?
                </Form.Text>
              </Form.Group>

              <Button
                variant="outline-primary"
                type="submit"
                disabled={this.state.loading}
              >
                {this.state.loading ? "Loading…" : "sign in"}
              </Button>

              <Button variant="outline-secondary" as={Link} to="/register">
                apply for beta
              </Button>
              {this.state.error && (
                <div className="error">{this.state.errorMessage}</div>
              )}
            </Form>
          </Card>
        </PublicWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  success: getAuthStatus(state),
  onboarded: getOnboarding(state)
});

Login.propTypes = {};

export default connect(mapStateToProps, { pathURL, loginUser })(Login);
