import "./Public.scss";

import { Link, Redirect } from "react-router-dom";
import React, { Component } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import PublicWrapper from "./PublicWrapper";
import { connect } from "react-redux";
import { pathURL } from "../redux/actions/routes";
import { resetPassword } from "../redux/actions/auth";

class PasswordSet extends Component {
  constructor() {
    super();
    this.state = {
      error: false,
      errorMessage: "",
      password: "",
      email: "",
      password_confirm: "",
      token: "",
      redirect: false,
      loading: false
    };
  }

  submit = async (e) => {
    e.preventDefault();

    if (this.state.password !== this.state.password_confirm) {
      this.setState({
        error: true,
        errorMessage: "Your passwords do not match"
      });
      return;
    }

    const passwordStrength =
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        this.state.password
      );

    const passwordConfirmStrength =
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        this.state.password_confirm
      );

    if (passwordStrength || passwordConfirmStrength) {
      this.setState({
        error: true,
        errorMessage: "Your passwords are not strong enough"
      });
      return;
    }

    this.setState({
      loading: true
    });

    const passwordPostObject = {
      password: this.state.password,
      password_confirm: this.state.password_confirm,
      email: this.state.email,
      token: this.state.token
    };

    const response = await this.props.resetPassword(passwordPostObject);
    if (response && response.status === 200) {
      this.setState({
        loading: false,
        redirect: true
      });
    } else {
      this.setState({
        error: true,
        errorMessage: "Token has expired, please request new password",
        loading: false
      });
    }
  };
  componentDidMount() {
    const { match, location } = this.props;
    const idMatch = location.search.substring(
      location.search.lastIndexOf("id=") + 3,
      location.search.lastIndexOf("&email")
    );
    const emailMatch = location.search.substring(
      location.search.lastIndexOf("&email") + 7
    );

    this.setState({
      email: emailMatch,
      token: idMatch
    });
    this.props.pathURL(match.path, match.url);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  resetError = (event) => {
    this.setState({
      error: false,
      errorMessage: ""
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/login"} />;
    }

    return (
      <>
        <PublicWrapper>
          <Card body className="de-public" border="info">
            <h1>set new password</h1>
            <Form onSubmit={this.submit}>
              <Form.Group>
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  value={this.state.password}
                  onFocus={this.resetError}
                  onChange={this.handleChange}
                  isInvalid={
                    this.state.password &&
                    !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
                      this.state.password
                    )
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a stronger password (Minimum eight characters,
                  at least one uppercase letter, one lowercase letter, one
                  number and one special character)
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Password Confirm</Form.Label>
                <Form.Control
                  type="password"
                  id="password_confirm"
                  name="password_confirm"
                  onFocus={this.resetError}
                  value={this.state.password_confirm}
                  className="form-control"
                  onChange={this.handleChange}
                  isInvalid={
                    this.state.password_confirm &&
                    !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
                      this.state.password_confirm
                    )
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a stronger password (Minimum eight characters,
                  at least one uppercase letter, one lowercase letter, one
                  number and one special character)
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                variant="outline-primary"
                type="submit"
                disabled={this.state.loading}
              >
                {this.state.loading ? "Loading…" : "submit password"}
              </Button>
              {this.state.error && <div>{this.state.errorMessage}</div>}
              <Button
                variant="outline-secondary"
                as={Link}
                to="/password-reset"
              >
                request new password
              </Button>
              <Button variant="outline-secondary" as={Link} to="/register">
                create an account
              </Button>
              <Button variant="outline-secondary" as={Link} to="/login">
                sign in
              </Button>
            </Form>
          </Card>
        </PublicWrapper>
      </>
    );
  }
}

PasswordSet.propTypes = {};

export default connect(null, { pathURL, resetPassword })(PasswordSet);
