import { PremiumData, Action, ActionTypes } from "../actions";

const initialState: PremiumData = {
  videos: [],
  sprints: [],
  userSprints: [],
  templates: []
};

export function premiumReducer(
  state = initialState,
  action: Action
): PremiumData {
  switch (action.type) {
    case ActionTypes.getVideos:
      return {
        ...state,
        videos: action.payload.data
      };
    case ActionTypes.getSprints:
      return {
        ...state,
        sprints: action.payload.data
      };
    case ActionTypes.getTemplates:
      return {
        ...state,
        templates: action.payload.data
      };
    case ActionTypes.getUserSprints:
      return {
        ...state,
        userSprints: action.payload.data
      };
    case ActionTypes.addUserSprints:
      return {
        ...state,
        userSprints: [...state.userSprints, action.payload]
      };
    default:
      return state;
  }
}
