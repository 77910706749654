import { createSelector } from "reselect";

export const getPremium = (state) => state.premium || {};

export const getVideosData = createSelector([getPremium], (content) => {
  return content.videos;
});

export const getSprintsData = createSelector([getPremium], (content) => {
  return content.sprints;
});

export const getUserSprintsData = createSelector([getPremium], (content) => {
  let userSprintsArray = content.userSprints;
  let sprintsArray = content.sprints;

  var result = sprintsArray.filter(function (o1) {
    return userSprintsArray.some(function (o2) {
      return o1.sprint_id === o2.sprint; // return the ones with equal id
    });
  });
  return result;
});

export const getTemplatesData = createSelector([getPremium], (content) => {
  return content.templates;
});
