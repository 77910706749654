import "./WelcomeModal.scss";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const WelcomeModal = () => {
  const [modalShow, setModalShow] = useState(true);

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Welcome to DealEngine!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>To get started:</h4>
          <p>
            Help us calculate your DealScore by filling in{" "}
            <a href="/mtm">Metrics That Matter</a> or check out{" "}
            <a href="/playbook">Playbook</a>, a comprehensive guide to launching
            your startup.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};
