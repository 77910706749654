import { createSelector } from "reselect";

export const getAuth = (state) => state.auth || {};

// If user is currently logged in or has a token in storage
export const getAuthStatus = createSelector(
  [getAuth],
  (data) => data.loggedIn || (localStorage.getItem("token") ? true : false)
);

export const getAccessToken = createSelector(
  [getAuth],
  (data) => data.token.access
);

export const getRefreshToken = createSelector(
  [getAuth],
  (data) => data.token.refresh
);

export const getLoaded = createSelector([getAuth], (data) => data.loaded);

export const getOrgName = createSelector([getAuth], (data) => data.org_name);

export const getOrgId = createSelector(
  [getAuth],
  (data) => data.org_id || localStorage.getItem("org_id")
);

export const getUserId = createSelector(
  [getAuth],
  (data) => data.user_id || localStorage.getItem("user_id")
);

export const getOnboarding = createSelector([getAuth], (data) =>
  data.onboarding_survey_completed ||
  localStorage.getItem("onboarded") === "true"
    ? true
    : false
);

export const getOrgStage = createSelector([getAuth], (data) => {
  const unused_stages = ["Velocity", "Bonding"];

  if (data.org_stage && unused_stages.includes(data.org_stage)) {
    return "Match";
  } else return data.org_stage;
});

export const getOrgTier = createSelector([getAuth], (data) => data.tier);
