import "./SimpleSlider.css";
import React from "react";
import RangeSlider from "react-bootstrap-range-slider";
// changed from css and moved to app scss page

export const SimpleSlider = ({
  min: min,
  max: max,
  handleChange: handleChange,
  defaultValue: defaultValue,
  ...data
}) => {
  const [value, setValue] = React.useState(defaultValue);

  return (
    <RangeSlider
      className="custom-range"
      value={value}
      min={min}
      max={max}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onAfterChange={(e) => {
        handleChange(data, e);
      }}
    />
  );
};
