import { createSelector } from "reselect";

export const getMtm = (state) => state.mtm || {};

export const getMtmData = createSelector([getMtm], (mtm) => {
  return mtm.data;
});

export const getMTMTotal = createSelector([getMtm], (mtm) => {
  return mtm.data.length;
});

export const getMTMAnswered = createSelector([getMtm], (mtm) => {
  return mtm.data.filter((val) => val.user_answer).length;
});

// Next steps selector

export const getMtmNextSteps = createSelector([getMtm], (mtm) => {
  let mtmRecords = [];
  mtm.data.reduce((total, sub) => {
    if (!sub.user_answer) {
      mtmRecords.push(sub);
    }
  }, 0);
  return mtmRecords;
});

export const hasMtmBeenVisited = createSelector([getMtm], (mtm) => {
  let mtmRecords = [];
  const onboarding_questions = ["mtm_industry", "mtm_development_stage"];
  mtm.data.reduce((total, sub) => {
    // If any other mtm question other than industry has been answered
    // Return true
    if (!onboarding_questions.includes(sub.key) && sub.last_updated) {
      mtmRecords.push(sub);
    }
  }, 0);
  return mtmRecords.length;
});

export const showModal = createSelector([getMtm], (mtm) => {
  let dataPulled = false;
  let dataPresent = false;
  let showModal = false;
  const onboarding_questions = [
    "mtm_industry",
    "mtm_development_stage",
    "ob_engagement"
  ];
  mtm.data.reduce((total, sub) => {
    // If any other mtm question other than industry has been answered
    // Return true
    // "mtm_development_stage"
    dataPulled = true;
    if (!onboarding_questions.includes(sub.key) && sub.last_updated) {
      dataPresent = true;
    }
  }, 0);

  if (dataPulled) {
    if (!dataPresent) {
      showModal = true;
    }
  }

  return showModal;
});

// MTM category counts
// Problem Value
export const getCountProblemValue = createSelector([getMtm], (mtm) => {
  const totalNumber = mtm.data.filter(
    (val) => val.category === "value-proposition"
  ).length;
  const totalAnswered = mtm.data.filter(
    (val) => val.user_answer && val.category === "value-proposition"
  ).length;

  const percentFinished = (totalAnswered / totalNumber) * 100;
  return percentFinished;
});

// Market Sizing

export const getCountMarket = createSelector([getMtm], (mtm) => {
  const totalNumber = mtm.data.filter(
    (val) => val.category === "market-sizing"
  ).length;
  const totalAnswered = mtm.data.filter(
    (val) => val.user_answer && val.category === "market-sizing"
  ).length;
  const percentFinished = (totalAnswered / totalNumber) * 100;
  return percentFinished;
});

// Business Model

export const getCountBusiness = createSelector([getMtm], (mtm) => {
  const totalNumber = mtm.data.filter(
    (val) => val.category === "business-model"
  ).length;
  const totalAnswered = mtm.data.filter(
    (val) => val.user_answer && val.category === "business-model"
  ).length;
  const percentFinished = (totalAnswered / totalNumber) * 100;
  return percentFinished;
});

// Go to market

export const getCountGTM = createSelector([getMtm], (mtm) => {
  const totalNumber = mtm.data.filter(
    (val) => val.category === "go-to-market"
  ).length;
  const totalAnswered = mtm.data.filter(
    (val) => val.user_answer && val.category === "go-to-market"
  ).length;
  const percentFinished = (totalAnswered / totalNumber) * 100;
  return percentFinished;
});

// Competitors

export const getCountCompetitors = createSelector([getMtm], (mtm) => {
  const totalNumber = mtm.data.filter(
    (val) => val.category === "competitors"
  ).length;
  const totalAnswered = mtm.data.filter(
    (val) => val.user_answer && val.category === "competitors"
  ).length;
  const percentFinished = (totalAnswered / totalNumber) * 100;
  return percentFinished;
});

// Team

export const getCountTeam = createSelector([getMtm], (mtm) => {
  const totalNumber = mtm.data.filter((val) => val.category === "team").length;
  const totalAnswered = mtm.data.filter(
    (val) => val.user_answer && val.category === "team"
  ).length;
  const percentFinished = (totalAnswered / totalNumber) * 100;
  return percentFinished;
});

// Financials

export const getCountFinancials = createSelector([getMtm], (mtm) => {
  const totalNumber = mtm.data.filter(
    (val) => val.category === "financials"
  ).length;
  const totalAnswered = mtm.data.filter(
    (val) => val.user_answer && val.category === "financials"
  ).length;
  const percentFinished = (totalAnswered / totalNumber) * 100;
  return percentFinished;
});

// Fundraise

export const getCountFundraise = createSelector([getMtm], (mtm) => {
  const totalNumber = mtm.data.filter(
    (val) => val.category === "fundraise"
  ).length;
  const totalAnswered = mtm.data.filter(
    (val) => val.user_answer && val.category === "fundraise"
  ).length;
  const percentFinished = (totalAnswered / totalNumber) * 100;
  return percentFinished;
});
