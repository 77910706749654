import { ActionTypes } from "../types";
import { Dispatch } from "redux";
import { LoadDataAction } from "../actions/auth";
import { StoreState } from "../../redux";
import axios from "axios";
import { getOrgId } from "../selectors/authSelectors";

// for comment: make it so when a value is updated it makes comment null, and the comment section defaults to "check back tomorrow..." if null

export interface ScoresData {
  deal_score: number;
  product_score: number;
  market_score: number;
  money_score: number;
  team_score: number;
  last_updated: string;
}

export interface ScoresExpectation {
  data: {
    deal_score: number;
    product_score: number;
    market_score: number;
    money_score: number;
    team_score: number;
    public_id: string;
    created: string;
    last_updated: string;
    organization: string;
  };
}

export interface ScoresAction {
  type: ActionTypes.getScores;
  payload: ScoresData;
}

export const getScores = () => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };
      const orgId = getOrgId(getState());
      const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
      const scoresURL = `${url}/scores/score/${orgId}`;

      axios.defaults.withCredentials = true;

      const response = await axios.get<ScoresData>(scoresURL, config);

      const scoresDataObj = {
        deal_score: response.data.deal_score,
        product_score: response.data.product_score,
        market_score: response.data.market_score,
        money_score: response.data.money_score,
        team_score: response.data.team_score,
        last_updated: response.data.last_updated
      };

      dispatch<ScoresAction>({
        type: ActionTypes.getScores,
        payload: scoresDataObj
      });
      dispatch<LoadDataAction>({
        type: ActionTypes.loadData,
        payload: { detail: "Loading Data" }
      });
      return response;
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status == 404) {
          console.log("No scores for this organization");
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};
