import "./Playbook.scss";

import {
  EnumPlaybookItem,
  PlaybookData,
  loadPlaybookUpdateData,
  postPlaybook
} from "../../../redux/actions/playbook";

import { ActionItems } from "./ActionItems";
import Card from "react-bootstrap/Card";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { StoreState } from "../../../redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Wrapper from "../../components/Wrapper/Wrapper";
import { connect } from "react-redux";
import { getOrgStage } from "../../../redux/selectors/authSelectors";
import { getPlaybookData } from "../../../redux/selectors/playbookSelectors";
import { pathURL } from "../../../redux/actions/routes";
import { PlaybookDropdown } from "./PlaybookDropdown";

// Using match with typescript is weird, used this logic to set up the type:
// https://stackoverflow.com/questions/48138111/what-typescript-type-should-i-use-to-reference-the-match-object-in-my-props
interface MatchParams {
  name: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
  path: string;
  url: string;
}

interface AppProps {
  pathURL: (path: string, url: string) => void;
  match: MatchProps;
  playbook: EnumPlaybookItem[];
  loadPlaybookUpdateData: (data: PlaybookData) => void;
  postPlaybook: (data: EnumPlaybookItem) => void;
  org_stage: any;
}

class _Playbook extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      user_answer: false,
      answers: [{ user_answer: false }],
      filterValue: "Team",
      count: 0
    };
  }

  componentDidMount() {
    this.props.pathURL(this.props.match.path, this.props.match.url);
  }

  handleValueUpdate = (activity: any) => (evt: any) => {
    const firstValue = this.props.playbook.map((first) => {
      const secondValue = first.lc_areas.map((second) => {
        const thirdValue = second.fa_tasks.map((third) => {
          const fourthValue = third.new_action_items.map((fourth) => {
            if (activity !== fourth.refKey) return fourth;
            this.props.postPlaybook({
              ...fourth,
              user_answer: evt.target.checked
            });
            return { ...fourth, user_answer: evt.target.checked };
          });

          return { ...third, new_action_items: fourthValue };
        });
        return { ...second, fa_tasks: thirdValue };
      });
      return { ...first, lc_areas: secondValue };
    });
    this.props.loadPlaybookUpdateData(firstValue);
    this.setState({ answers: firstValue });
  };

  handleDropdownSelect = (category: any) => {
    this.setState({ filterValue: category });
  };

  render() {
    const evidenceStage = this.props.playbook.find(
      (o) => o.name === "Evidence"
    );
    const acceptanceStage = this.props.playbook.find(
      (o) => o.name === "Acceptance"
    );
    const formulaStage = this.props.playbook.find((o) => o.name === "Formula");
    const matchStage = this.props.playbook.find(
      (o) => o.name === "Match" || o.name === "Bonding"
    );

    const checkDisabled = (stage: string) => {
      const priorities = {
        Velocity: 6,
        Bonding: 5,
        Match: 4,
        Formula: 3,
        Acceptance: 2,
        Evidence: 1
      };
      if (
        priorities[this.props.org_stage] &&
        priorities[this.props.org_stage] >= priorities[stage]
      ) {
        return false;
      } else return true;
    };

    const defaultActive = (stage: string) => {
      // Temporarily set Bonding and Velocity to Match behavior
      if (stage === "Bonding" || stage === "Velocity") {
        return "Match";
      }
      return stage;
    };

    return (
      <div className="App playbook-wrap">
        <Wrapper>
          {/* This tab system goes horizontal , but cannot render mapped data */}
          {/* <h1>Playbook</h1> */}
          <h1>lifecycles</h1>
          <Tabs
            defaultActiveKey={defaultActive(this.props.org_stage)}
            transition={false}
            id="noanim-tab-example"
          >
            <Tab
              eventKey="Evidence"
              title="Evidence"
              disabled={checkDisabled("Evidence")}
            >
              <PlaybookDropdown
                handleDropdownSelect={this.handleDropdownSelect}
                filterValue={this.state.filterValue}
              />
              {evidenceStage && (
                <div>
                  {evidenceStage.lc_areas.map((category: any) => (
                    <div key={category.refKey} className="">
                      {category.name === this.state.filterValue && (
                        <p>{category.description}</p>
                      )}
                      {category.name === this.state.filterValue &&
                        category.fa_tasks.map((activity: any) => (
                          <div key={`${activity.refKey}-name`}>
                            <div className="de-headline">{activity.name}</div>

                            <Card
                              key={`${activity.refKey}-carditem`}
                              className=""
                            >
                              {activity.new_action_items.map((action: any) => (
                                <ActionItems
                                  key={`${action.refKey}-navitem`}
                                  handleValueUpdate={this.handleValueUpdate}
                                  // key={action.refKey}
                                  category={category.name}
                                  {...action}
                                />
                              ))}
                            </Card>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              )}
            </Tab>
            <Tab
              eventKey="Acceptance"
              title="Acceptance"
              disabled={checkDisabled("Acceptance")}
            >
              {" "}
              <PlaybookDropdown
                handleDropdownSelect={this.handleDropdownSelect}
                filterValue={this.state.filterValue}
              />
              {acceptanceStage && (
                <div>
                  {acceptanceStage.lc_areas.map((category: any) => (
                    <div key={category.refKey} className="">
                      {category.name === this.state.filterValue && (
                        <p>{category.description}</p>
                      )}
                      {category.name === this.state.filterValue &&
                        category.fa_tasks.map((activity: any) => (
                          <div key={`${activity.refKey}-name`}>
                            <div className="de-headline">{activity.name}</div>

                            <Card
                              key={`${activity.refKey}-carditem`}
                              className=""
                            >
                              {activity.new_action_items.map((action: any) => (
                                <ActionItems
                                  key={`${action.refKey}-navitem`}
                                  handleValueUpdate={this.handleValueUpdate}
                                  // key={action.refKey}
                                  category={category.name}
                                  {...action}
                                />
                              ))}
                            </Card>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              )}
            </Tab>
            <Tab
              eventKey="Formula"
              title="Formula"
              disabled={checkDisabled("Formula")}
            >
              {" "}
              <PlaybookDropdown
                handleDropdownSelect={this.handleDropdownSelect}
                filterValue={this.state.filterValue}
              />
              {formulaStage && (
                <div>
                  {formulaStage.lc_areas.map((category: any) => (
                    <div key={category.refKey} className="">
                      {category.name === this.state.filterValue && (
                        <p>{category.description}</p>
                      )}
                      {category.name === this.state.filterValue &&
                        category.fa_tasks.map((activity: any) => (
                          <div key={`${activity.refKey}-name`}>
                            <div className="de-headline">{activity.name}</div>

                            <Card
                              key={`${activity.refKey}-carditem`}
                              className=""
                            >
                              {activity.new_action_items.map((action: any) => (
                                <ActionItems
                                  key={`${action.refKey}-navitem`}
                                  handleValueUpdate={this.handleValueUpdate}
                                  // key={action.refKey}
                                  category={category.name}
                                  {...action}
                                />
                              ))}
                            </Card>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              )}
            </Tab>
            <Tab
              eventKey="Match"
              title="Match"
              disabled={checkDisabled("Match")}
            >
              {" "}
              <PlaybookDropdown
                handleDropdownSelect={this.handleDropdownSelect}
                filterValue={this.state.filterValue}
              />
              {matchStage && (
                <div>
                  {matchStage.lc_areas.map((category: any) => (
                    <div key={category.refKey} className="">
                      {category.name === this.state.filterValue && (
                        <p>{category.description}</p>
                      )}
                      {category.name === this.state.filterValue &&
                        category.fa_tasks.map((activity: any) => (
                          <div key={`${activity.refKey}-name`}>
                            <div className="de-headline">{activity.name}</div>

                            <Card
                              key={`${activity.refKey}-carditem`}
                              className=""
                            >
                              {activity.new_action_items.map((action: any) => (
                                <ActionItems
                                  key={`${action.refKey}-navitem`}
                                  handleValueUpdate={this.handleValueUpdate}
                                  // key={action.refKey}
                                  category={category.name}
                                  {...action}
                                />
                              ))}
                            </Card>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              )}
            </Tab>
          </Tabs>
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = (
  state: StoreState
): { playbook: any; org_stage: any } => {
  return { playbook: getPlaybookData(state), org_stage: getOrgStage(state) };
};

export const Playbook = connect(mapStateToProps, {
  pathURL,
  loadPlaybookUpdateData,
  postPlaybook
})(_Playbook);
