// import * as Sentry from "@sentry/react";

import { applyMiddleware, compose, createStore } from "redux";

import App from "./secure/containers/App/App";
// import { Integrations } from "@sentry/tracing";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import { persistReducer } from "redux-persist";
import { persistStore } from "redux-persist";
import { reducers } from "./redux";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

// if (process.env.NODE_ENV !== "development") {
//   Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_DSN,
//     beforeBreadcrumb(breadcrumb, hint) {
//       return breadcrumb.category === "ui.click" ? null : breadcrumb;
//     },
//     integrations: [new Integrations.BrowserTracing()],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//     environment: process.env.NODE_ENV
//   });
// }

// This declaration is for the Redux Devtools extension
// https://github.com/zalmoxisus/redux-devtools-extension
// https://stackoverflow.com/questions/52800877/has-anyone-came-across-this-error-in-ts-with-redux-dev-tools-property-redux

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk)
  // other store enhancers if any
);

// end Redux Devtools setup

const persistConfig = {
  key: "root",
  storage: storage,
  // As we progress, let's revisit which parts of redux we want to cache with persist
  whitelist: ["glossary"],
  blacklist: ["auth", "scores", "playbook", "mtm"]
};

const persisted = persistReducer(persistConfig, reducers);

export const store = createStore(persisted, enhancer);

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
