export const determinePath = (url) => {
  if (url === "/") {
    return "Dashboard";
  } else {
    // Remove the beginning / from the path
    const pathName = url.slice(1);

    const pathMap = {
      playbook: "Playbook",
      mtm: "Metrics",
      team: "Team",
      glossary: "Glossary",
      profile: "Profile"
    };

    return pathMap[pathName] ? pathMap[pathName] : "Menu";
  }
};

export const cleanInt = (val) => {
  if (val && parseInt(val)) {
    return parseInt(val);
  }
  return 0;
};

export const convertPercent = (val) => {
  if (val && !isNaN(val) && 0 < val && val < 1) {
    return (val * 100).toFixed(2);
  }
  return 0;
};

export const percentValidation = (val) => {
  if (val && parseInt(val)) {
    const percentVal = parseInt(val);
    if (percentVal < 0) return 0;
    if (percentVal > 100) return 100;
    return percentVal;
  }
  return 0;
};

export const parseDate = (date) => {
  if (date) {
    const currentDate = new Date(date);
    // Must get UTC date or else off by 1 day
    // https://stackoverflow.com/a/7556634/3145747
    const currentDayOfMonth = currentDate.getUTCDate();
    const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
    const currentYear = currentDate.getFullYear();

    if (
      !isNaN(currentDayOfMonth) &&
      !isNaN(currentMonth) &&
      !isNaN(currentYear)
    ) {
      const dateString =
        currentMonth + 1 + "/" + currentDayOfMonth + "/" + currentYear;
      return dateString;
    } else return "-";
  } else return "-";
};

export const testPasswordIntegrity = (password, passwordConfirm) => {
  if (
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
      password
    ) &&
    password === passwordConfirm
  ) {
    return true;
  }
  return false;
};

export const quizLength = (length) => {
  return length ? length : "-";
};

export const generateSurveySubmit = (survey) => {
  let surveyObj = {};
  survey.forEach((answer) => {
    if (answer && answer.key && answer.user_answer) {
      surveyObj[answer.key] = answer.user_answer;
    }
  });
  return surveyObj;
};

export const toLowerCase = (text) => {
  return text.toLowerCase();
};

export const roundTwoPlaces = (num) => {
  if (num) {
    return Math.round(num * 100) / 100;
  } else return "-";
};

export const cleanDate = (date) => {
  const d = new Date(date);
  return d.toDateString();
};

export const getTierToInt = (tier) => {
  //     STANDARD = "1"
  //     PRO = "2"
  //     ADVISOR = "3"
  return tier ? parseInt(tier) : 1;
};
