import "./Glossary.scss";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import Wrapper from "../../components/Wrapper/Wrapper";
import { StoreState } from "../../../redux";
import { pathURL } from "../../../redux/actions/routes";
import { getGlossaryData } from "../../../redux/selectors/glossarySelectors";
import { EnumGlossaryItem } from "../../../redux/actions";
import Row from "react-bootstrap/Row";

// Using match with typescript is weird, used this logic to set up the type:
// https://stackoverflow.com/questions/48138111/what-typescript-type-should-i-use-to-reference-the-match-object-in-my-props
interface MatchParams {
  name: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
  path: string;
  url: string;
}

interface AppProps {
  pathURL: (path: string, url: string) => void;
  match: MatchProps;
  glossary: EnumGlossaryItem[];
}

class _Glossary extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
  }

  componentDidMount() {
    this.props.pathURL(this.props.match.path, this.props.match.url);
  }

  render() {
    return (
      <div className="App gloss-wrap">
        <Wrapper>
          <h1>glossary</h1>
          <div className="glossary">
            <Row>
              <h2 className="col d-md-block">term</h2>
              <h2 className="col d-md-block">definition</h2>
            </Row>
            <dl>
              {this.props.glossary.map((data: EnumGlossaryItem) => (
                <Row className="align-items-center" key={"_glossary" + data.id}>
                  <dt className="col-sm">{data.term}</dt>
                  <dd className="col-sm">{data.definition}</dd>
                </Row>
              ))}
            </dl>
          </div>
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = (
  state: StoreState
): { glossary: EnumGlossaryItem[] } => {
  return { glossary: getGlossaryData(state) };
};

export const Glossary = connect(mapStateToProps, {
  pathURL
})(_Glossary);
