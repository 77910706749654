import { Action, ActionTypes, Auth } from "../actions";

export const authState = {
  loggedIn: false,
  token: { refresh: "", access: "" },
  loaded: false,
  org_name: "",
  org_id: "",
  user_id: "",
  org_stage: "Evidence",
  onboarding_survey_completed: false,
  tier: localStorage.getItem("tier") || ""
};

const initialState: Auth = authState;

export function authReducer(state = initialState, action: Action): Auth {
  switch (action.type) {
    case ActionTypes.logoutUser:
      console.log("logging out user");
      return {
        ...state,
        loggedIn: false,
        token: { refresh: "", access: "" },
        loaded: false
      };
    case ActionTypes.loginUser:
      return {
        ...state,
        loggedIn: action.payload.org_name ? true : false,
        token: action.payload.token,
        org_name: action.payload.org_name,
        org_id: action.payload.org_id,
        user_id: action.payload.user_id,
        org_stage: action.payload.org_stage,
        onboarding_survey_completed: action.payload.onboarding_survey_completed,
        tier: action.payload.tier
      };
    case ActionTypes.registerUser:
      return {
        ...state,
        org_name: action.payload.organization.name,
        org_id: action.payload.organization.org_id,
        user_id: action.payload.id ? action.payload.id.toString() : "",
        org_stage: action.payload.organization.organization_stage,
        onboarding_survey_completed:
          action.payload.organization.onboarding_survey_completed
      };
    case ActionTypes.onboardUser:
      return {
        ...state,
        org_stage: action.payload.data.organization_stage,
        onboarding_survey_completed:
          action.payload.data.onboarding_survey_completed
      };
    case ActionTypes.loadData:
      return {
        ...state,
        loaded: true
      };
    case ActionTypes.loadOrgFromStorage:
      return {
        ...state,
        token: action.payload.token,
        org_name: action.payload.org_name,
        org_id: action.payload.org_id,
        user_id: action.payload.user_id,
        org_stage: action.payload.org_stage,
        onboarding_survey_completed: action.payload.onboarding_survey_completed,
        tier: action.payload.tier
      };
    case ActionTypes.updateStage:
      return {
        ...state,
        org_stage: action.payload.organization_stage
      };
    case ActionTypes.getReferral:
      return {
        ...state
        // shortname: action.payload.shortname,
        // description: action.payload.description,
        // logo_url: action.payload.logo_url,
        // url: action.payload.url,
        // active: action.payload.active
      };
    default:
      return state;
  }
}
