import { Dispatch } from "redux";
import { ActionTypes } from "../types";

export interface Errors {
  message: string;
  statusCode: null;
}

export interface SetErrorAction {
  type: ActionTypes.setError;
  payload: Errors;
}

export const setError = (errorMsg: Errors) => {
  if (errorMsg) {
    const payload = {
      message: errorMsg.message,
      statusCode: errorMsg.statusCode
    };
    return async (dispatch: Dispatch) => {
      dispatch<SetErrorAction>({
        type: ActionTypes.setError,
        payload
      });
    };
  } else {
    return async (dispatch: Dispatch) => {
      dispatch<SetErrorAction>({
        type: ActionTypes.setError,
        payload: { message: "", statusCode: null }
      });
    };
  }
};
