// import "./Glossary.scss";
import "./Premium.scss";

import React from "react";
import ReactPlayer from "react-player";
import { RouteComponentProps, Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import { StoreState } from "../../../redux";
import { pathURL } from "../../../redux/actions/routes";
import {
  getVideosData,
  getSprintsData,
  getUserSprintsData,
  getTemplatesData
} from "../../../redux/selectors/premiumSelectors";
import { getOrgTier } from "../../../redux/selectors/authSelectors";
import {
  EnumVideosItem,
  EnumSprintsItem,
  EnumTemplatesItem,
  postUserSprint,
  EnumUserSprintsItem
} from "../../../redux/actions/premium";
import Wrapper from "../../components/Wrapper/Wrapper";
import { cleanDate } from "../../helpers/helpers";

// Using match with typescript is weird, used this logic to set up the type:
// https://stackoverflow.com/questions/48138111/what-typescript-type-should-i-use-to-reference-the-match-object-in-my-props
interface MatchParams {
  name: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
  path: string;
  url: string;
}

interface AppProps {
  pathURL: (path: string, url: string) => void;
  match: MatchProps;
  videos: EnumVideosItem[];
  sprints: EnumSprintsItem[];
  userSprints: EnumSprintsItem[];
  templates: EnumTemplatesItem[];
  tier: any;
  postUserSprint: (data: any) => void;
}

class _Premium extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      userSprints: this.props.userSprints
    };
  }

  componentDidMount() {
    this.props.pathURL(this.props.match.path, this.props.match.url);
  }

  handleSprintClick = (data: any) => {
    this.props.postUserSprint(data);
  };

  determineDate = (date: any) => {
    const scheduledDate = Date.parse(date);
    const currentDate = Date.now();
    if (scheduledDate > currentDate) {
      return true;
    } else return false;
  };

  render() {
    const checkTier = (tier: string) => {
      if (tier === "3" || tier === "2") {
        return false;
      } else return true;
    };

    return (
      <div className="App playbook-wrap">
        <Wrapper>
          <h1>content types</h1>
          <Tabs
            defaultActiveKey={"Traction Sprints"}
            transition={false}
            id="noanim-tab-example"
          >
            <Tab
              eventKey="Traction Sprints"
              title="Traction sprints"
              disabled={false}
            >
              <Tabs
                defaultActiveKey={"Traction Sprints"}
                transition={false}
                id="noanim-tab-example2"
              >
                <Tab
                  eventKey="Traction Sprints"
                  title="Traction Sprints"
                  disabled={false}
                >
                  <Row className="align-items-center" key={"_content"}>
                    {this.props.sprints
                      // .filter((data) => data.category === "Team")
                      .map((data: any) => (
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          key={data.id}
                          // onClick={() => {
                          //   this.handleSprintClick({ data });
                          // }}
                        >
                          <div className="">{data.title}</div>
                          <ReactPlayer
                            controls={true}
                            url={data.video_url}
                            width={"400px"}
                          />
                          <div className="">{cleanDate(data.date)}</div>
                          <a href={data.instructor_url}>{data.instructor}</a>
                          <div className="">{data.instructor_bio}</div>
                          <div className="">{data.description}</div>
                          <div>
                            {ReactHtmlParser(data.extended_description)}
                          </div>
                          <Button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={() => this.handleSprintClick({ data })}
                          >
                            select sprint
                          </Button>
                        </Col>
                      ))}
                  </Row>
                </Tab>
                <Tab eventKey="My Sprints" title="My Sprints" disabled={false}>
                  <Row className="align-items-center" key={"_mysprints"}>
                    <div>
                      Upcoming sprints:
                      {this.props.userSprints
                        .filter((data) => {
                          return this.determineDate(data.date);
                        })
                        .map((data: any) => (
                          <Col sm={6} md={6} lg={6} key={data.id}>
                            <div className="">{data.title}</div>
                            <ReactPlayer
                              controls={true}
                              url={data.video_url}
                              width={"400px"}
                            />
                            <div className="">{cleanDate(data.date)}</div>
                            {/* <div className="">{data.instructor}</div> */}
                            <a href={data.instructor_url}>{data.instructor}</a>
                            <div className="">{data.instructor_bio}</div>
                            <div className="">{data.description}</div>
                            <div>
                              {ReactHtmlParser(data.extended_description)}
                            </div>
                          </Col>
                        ))}
                    </div>
                    <div>
                      Past Sprints:
                      {this.props.userSprints
                        .filter((data) => {
                          return !this.determineDate(data.date);
                        })
                        .map((data: any) => (
                          <Col sm={6} md={6} lg={6} key={data.id}>
                            <div className="">{data.title}</div>
                            <ReactPlayer
                              controls={true}
                              url={data.video_url}
                              width={"400px"}
                            />
                            <div className="">{cleanDate(data.date)}</div>
                            {/* <div className="">{data.instructor}</div> */}
                            <a href={data.instructor_url}>{data.instructor}</a>
                            <div className="">{data.instructor_bio}</div>
                            <div className="">{data.description}</div>
                            <div>
                              {ReactHtmlParser(data.extended_description)}
                            </div>
                          </Col>
                        ))}
                    </div>
                  </Row>
                </Tab>
              </Tabs>
            </Tab>
            <Tab eventKey="Videos" title="Videos" disabled={false}>
              <Tabs
                defaultActiveKey={"Team"}
                transition={false}
                id="noanim-tab-example2"
              >
                <Tab eventKey="Team" title="Team" disabled={false}>
                  <Row className="align-items-center" key={"_content"}>
                    {this.props.videos
                      .filter((data) => data.category === "Team")
                      .map((data: any) => (
                        <Col sm={6} md={6} lg={6} key={data.id}>
                          <ReactPlayer
                            controls={true}
                            url={data.video_url}
                            width={"400px"}
                          />
                          <div className="">{data.title}</div>
                          <div className="">{data.description}</div>
                          <div>
                            {ReactHtmlParser(data.extended_description)}
                          </div>
                        </Col>
                      ))}
                  </Row>
                </Tab>
                <Tab eventKey="Product" title="Product" disabled={false}>
                  {this.props.videos
                    .filter((data) => data.category === "product")
                    .map((data: any) => (
                      <Row
                        className="align-items-center"
                        key={"_content" + data.id}
                      >
                        <ReactPlayer controls={true} url={data.video_url} />
                      </Row>
                    ))}
                </Tab>
                <Tab eventKey="Market" title="Market" disabled={false}>
                  {this.props.videos
                    .filter((data) => data.category === "market")
                    .map((data: any) => (
                      <Row
                        className="align-items-center"
                        key={"_content" + data.id}
                      >
                        <ReactPlayer controls={true} url={data.video_url} />
                      </Row>
                    ))}
                </Tab>
                <Tab eventKey="Money" title="Money" disabled={false}>
                  {this.props.videos
                    .filter((data) => data.category === "money")
                    .map((data: any) => (
                      <Row
                        className="align-items-center"
                        key={"_content" + data.id}
                      >
                        <ReactPlayer controls={true} url={data.video_url} />
                      </Row>
                    ))}
                </Tab>
              </Tabs>
            </Tab>
            <Tab
              eventKey="Templates"
              title="Templates"
              disabled={checkTier(this.props.tier)}
            >
              <Tabs
                defaultActiveKey={"Team"}
                transition={false}
                id="noanim-tab-example2"
              >
                <Tab eventKey="Team" title="Team" disabled={false}>
                  <Row className="align-items-center" key={"_content"}>
                    {this.props.templates
                      .filter((data) => data.category === "Team")
                      .map((data: any) => (
                        <Col sm={6} md={6} lg={6} key={data.id}>
                          <Image
                            src={data.image_url}
                            fluid
                            alt={data.description}
                          />
                          <Link to={data.template_url}>{data.title}</Link>
                          <div className="">{data.description}</div>
                        </Col>
                      ))}
                  </Row>
                </Tab>
                <Tab eventKey="Product" title="Product" disabled={false}>
                  {this.props.templates
                    .filter((data) => data.category === "product")
                    .map((data: any) => (
                      <Col sm={6} md={6} lg={6} key={data.id}>
                        <Image
                          src={data.image_url}
                          fluid
                          alt={data.description}
                        />
                        <Link to={data.template_url}>{data.title}</Link>
                        <div className="">{data.description}</div>
                      </Col>
                    ))}
                </Tab>
                <Tab eventKey="Market" title="Market" disabled={false}>
                  {this.props.templates
                    .filter((data) => data.category === "market")
                    .map((data: any) => (
                      <Col sm={6} md={6} lg={6} key={data.id}>
                        <Image
                          src={data.image_url}
                          fluid
                          alt={data.description}
                        />
                        <Link to={data.template_url}>{data.title}</Link>
                        <div className="">{data.description}</div>
                      </Col>
                    ))}
                </Tab>
                <Tab eventKey="Money" title="Money" disabled={false}>
                  {this.props.templates
                    .filter((data) => data.category === "money")
                    .map((data: any) => (
                      <Col sm={6} md={6} lg={6} key={data.id}>
                        <Image
                          src={data.image_url}
                          fluid
                          alt={data.description}
                        />
                        <Link to={data.template_url}>{data.title}</Link>
                        <div className="">{data.description}</div>
                      </Col>
                    ))}
                </Tab>
              </Tabs>
            </Tab>
          </Tabs>
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = (
  state: StoreState
): {
  videos: EnumVideosItem[];
  sprints: EnumSprintsItem[];
  userSprints: EnumUserSprintsItem[];
  templates: EnumTemplatesItem[];
  tier: any;
} => {
  return {
    videos: getVideosData(state),
    sprints: getSprintsData(state),
    userSprints: getUserSprintsData(state),
    templates: getTemplatesData(state),
    tier: getOrgTier(state)
  };
};

export const Premium = connect(mapStateToProps, {
  pathURL,
  postUserSprint
})(_Premium);
