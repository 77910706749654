import { createSelector } from "reselect";
import { getOrgStage } from "./authSelectors";

export const getPlaybook = (state) => state.playbook || {};

export const getPlaybookData = createSelector([getPlaybook], (playbook) => {
  return playbook.data;
});

// TODO: make this a count of all the sub action items
export const getPlaybookAllTotal = createSelector([getPlaybook], (playbook) => {
  const totalPlaybook = playbook.data.reduce((allTotal, lc) => {
    const sum = lc.lc_areas.reduce((total, area) => {
      const sumArea = area.fa_tasks.reduce((total, task) => {
        const sumTask = task.new_action_items.reduce((total, item) => {
          return total + item.user_answer + !item.user_answer;
        }, 0);
        return total + sumTask;
      }, 0);
      return total + sumArea;
    }, 0);
    return allTotal + sum;
  }, 0);
  return totalPlaybook;
});

// TODO: make this a count of all the non-null sub action items
export const getPlaybookAllAnswered = createSelector(
  [getPlaybook],
  (playbook) => {
    const totalPlaybookAnswered = playbook.data.reduce((allTotal, lc) => {
      const sum = lc.lc_areas.reduce((total, area) => {
        const sumArea = area.fa_tasks.reduce((total, task) => {
          const sumTask = task.new_action_items.reduce((total, item) => {
            return total + item.user_answer;
          }, 0);
          return total + sumTask;
        }, 0);
        return total + sumArea;
      }, 0);
      return allTotal + sum;
    }, 0);
    return totalPlaybookAnswered;
  }
);

// Team

// Need to only get the team

export const getPlaybookTeamTotal = createSelector(
  [getPlaybook, getOrgStage],
  (playbook, org) => {
    let playbookStage = playbook.data.find((o) => o.name === org);
    if (playbookStage) {
      let playbookArea = playbookStage.lc_areas.find((o) => o.name === "Team");
      if (playbookArea) {
        const totalTeamPlaybook = playbookArea.fa_tasks.reduce(
          (total, task) => {
            const sumTask = task.new_action_items.reduce((total, item) => {
              return total + 1;
            }, 0);
            return total + sumTask;
          },
          0
        );
        return totalTeamPlaybook;
      } else return 0;
    } else return 0;
  }
);

export const getPlaybookTeamAnswered = createSelector(
  [getPlaybook, getOrgStage],
  (playbook, org) => {
    let playbookStage = playbook.data.find((o) => o.name === org);
    if (playbookStage) {
      let playbookArea = playbookStage.lc_areas.find((o) => o.name === "Team");
      if (playbookArea) {
        const totalTeamPlaybook = playbookArea.fa_tasks.reduce(
          (total, task) => {
            const sumTask = task.new_action_items.reduce((total, item) => {
              return total + item.user_answer;
            }, 0);
            return total + sumTask;
          },
          0
        );
        return totalTeamPlaybook;
      } else return 0;
    } else return 0;
  }
);

// Product

export const getPlaybookProductTotal = createSelector(
  [getPlaybook, getOrgStage],
  (playbook, org) => {
    let playbookStage = playbook.data.find((o) => o.name === org);
    if (playbookStage) {
      let playbookArea = playbookStage.lc_areas.find(
        (o) => o.name === "Product"
      );
      if (playbookArea) {
        const totalTeamPlaybook = playbookArea.fa_tasks.reduce(
          (total, task) => {
            const sumTask = task.new_action_items.reduce((total, item) => {
              return total + 1;
            }, 0);
            return total + sumTask;
          },
          0
        );
        return totalTeamPlaybook;
      } else return 0;
    } else return 0;
  }
);

export const getPlaybookProductAnswered = createSelector(
  [getPlaybook, getOrgStage],
  (playbook, org) => {
    let playbookStage = playbook.data.find((o) => o.name === org);
    if (playbookStage) {
      let playbookArea = playbookStage.lc_areas.find(
        (o) => o.name === "Product"
      );
      if (playbookArea) {
        const totalTeamPlaybook = playbookArea.fa_tasks.reduce(
          (total, task) => {
            const sumTask = task.new_action_items.reduce((total, item) => {
              return total + item.user_answer;
            }, 0);
            return total + sumTask;
          },
          0
        );
        return totalTeamPlaybook;
      } else return 0;
    } else return 0;
  }
);

// Market

export const getPlaybookMarketTotal = createSelector(
  [getPlaybook, getOrgStage],
  (playbook, org) => {
    let playbookStage = playbook.data.find((o) => o.name === org);
    if (playbookStage) {
      let playbookArea = playbookStage.lc_areas.find(
        (o) => o.name === "Market"
      );
      if (playbookArea) {
        const totalTeamPlaybook = playbookArea.fa_tasks.reduce(
          (total, task) => {
            const sumTask = task.new_action_items.reduce((total, item) => {
              return total + 1;
            }, 0);
            return total + sumTask;
          },
          0
        );
        return totalTeamPlaybook;
      } else return 0;
    } else return 0;
  }
);

export const getPlaybookMarketAnswered = createSelector(
  [getPlaybook, getOrgStage],
  (playbook, org) => {
    let playbookStage = playbook.data.find((o) => o.name === org);
    if (playbookStage) {
      let playbookArea = playbookStage.lc_areas.find(
        (o) => o.name === "Market"
      );
      if (playbookArea) {
        const totalTeamPlaybook = playbookArea.fa_tasks.reduce(
          (total, task) => {
            const sumTask = task.new_action_items.reduce((total, item) => {
              return total + item.user_answer;
            }, 0);
            return total + sumTask;
          },
          0
        );
        return totalTeamPlaybook;
      } else return 0;
    } else return 0;
  }
);

// Money

export const getPlaybookMoneyTotal = createSelector(
  [getPlaybook, getOrgStage],
  (playbook, org) => {
    let playbookStage = playbook.data.find((o) => o.name === org);
    if (playbookStage) {
      let playbookArea = playbookStage.lc_areas.find((o) => o.name === "Money");
      if (playbookArea) {
        const totalTeamPlaybook = playbookArea.fa_tasks.reduce(
          (total, task) => {
            const sumTask = task.new_action_items.reduce((total, item) => {
              return total + 1;
            }, 0);
            return total + sumTask;
          },
          0
        );
        return totalTeamPlaybook;
      } else return 0;
    } else return 0;
  }
);

export const getPlaybookMoneyAnswered = createSelector(
  [getPlaybook, getOrgStage],
  (playbook, org) => {
    let playbookStage = playbook.data.find((o) => o.name === org);
    if (playbookStage) {
      let playbookArea = playbookStage.lc_areas.find((o) => o.name === "Money");
      if (playbookArea) {
        const totalTeamPlaybook = playbookArea.fa_tasks.reduce(
          (total, task) => {
            const sumTask = task.new_action_items.reduce((total, item) => {
              return total + item.user_answer;
            }, 0);
            return total + sumTask;
          },
          0
        );
        return totalTeamPlaybook;
      } else return 0;
    } else return 0;
  }
);

// Company

export const getPlaybookCompanyTotal = createSelector(
  [getPlaybook, getOrgStage],
  (playbook, org) => {
    let playbookStage = playbook.data.find((o) => o.name === org);
    if (playbookStage) {
      let playbookArea = playbookStage.lc_areas.find(
        (o) => o.name === "Company"
      );
      if (playbookArea) {
        const totalTeamPlaybook = playbookArea.fa_tasks.reduce(
          (total, task) => {
            const sumTask = task.new_action_items.reduce((total, item) => {
              return total + 1;
            }, 0);
            return total + sumTask;
          },
          0
        );
        return totalTeamPlaybook;
      } else return 0;
    } else return 0;
  }
);

export const getPlaybookCompanyAnswered = createSelector(
  [getPlaybook, getOrgStage],
  (playbook, org) => {
    let playbookStage = playbook.data.find((o) => o.name === org);
    if (playbookStage) {
      let playbookArea = playbookStage.lc_areas.find(
        (o) => o.name === "Company"
      );
      if (playbookArea) {
        const totalTeamPlaybook = playbookArea.fa_tasks.reduce(
          (total, task) => {
            const sumTask = task.new_action_items.reduce((total, item) => {
              return total + item.user_answer;
            }, 0);
            return total + sumTask;
          },
          0
        );
        return totalTeamPlaybook;
      } else return 0;
    } else return 0;
  }
);
