import {
  LoadDataAction,
  LoadOrgAction,
  LoginAction,
  LogoutAction,
  OnboardingAction,
  RegisterAction
} from "./actions/auth";
import {
  LoadMtmDataAction,
  PostMtmAction,
  UpdateStageAction,
  mtmGetAction
} from "./actions/mtm";
import {
  LoadPlaybookDataAction,
  PlaybookAction,
  PostPlaybookDataAction
} from "./actions/playbook";
import { SetAppRoutesAction, SetPathURLAction } from "./actions";

import { GlossaryAction } from "./actions/glossary";
import { ScoresAction } from "./actions/scores";
import { SetErrorAction } from "./actions/errors";
import {
  VideoAction,
  SprintsAction,
  TemplateAction,
  UserSprintsAction,
  AddUserSprintAction
} from "./actions/premium";

export enum ActionTypes {
  // Will default to 0. When adding a second item, will default to 1...etc
  appRoutes,
  pathURL,
  setError,
  loginUser,
  logoutUser,
  registerUser,
  getGlossary,
  getPlaybook,
  loadData,
  getMtm,
  loadMtmUpdateData,
  postMtm,
  loadPlaybookUpdateData,
  postPlaybook,
  onboardUser,
  loadOrgFromStorage,
  getScores,
  updateStage,
  getVideos,
  getSprints,
  getTemplates,
  getUserSprints,
  addUserSprints
}

export type Action =
  | SetAppRoutesAction
  | SetPathURLAction
  | SetErrorAction
  | LoginAction
  | RegisterAction
  | LogoutAction
  | GlossaryAction
  | PlaybookAction
  | LoadDataAction
  | mtmGetAction
  | LoadMtmDataAction
  | PostMtmAction
  | UpdateStageAction
  | LoadPlaybookDataAction
  | PostPlaybookDataAction
  | OnboardingAction
  | LoadOrgAction
  | ScoresAction
  | VideoAction
  | SprintsAction
  | TemplateAction
  | UserSprintsAction
  | AddUserSprintAction;
